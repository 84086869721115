/* eslint-disable */
import axios from "axios";
import { VERSAO, rotaDeProducao } from "./rotas";
import { CONSOLOGAR } from "@/components/Controle/debug";

export async function buscarTodosItens(isDelivery) {
    console.log("is delivery")
    console.log(isDelivery)
    CONSOLOGAR([window.location.href]);
    let uri = window.location.href.split('?');

    
    let params = null
    let cnpj = null
    let idTerminal = null
    let isMenuEstatico = null
    
    // caso seja o baitnazha, redirecionar para o cnpj deles
    if (window.location.href.includes("baitnazha")) {
        cnpj = 29274029000143
        idTerminal= 17
        isMenuEstatico = false;
        console.log("baitznazha delivery")
        console.log(`baitznazha delivery ${29274029000143} e terminal ${idTerminal}`)        
    } else {
        params = uri[1].split("--");
        cnpj = params[0];
        idTerminal = params[1];
        isMenuEstatico = params[2] == null ? false : true;
    }

    try {
        // let urlProd = rotaDeProducao() +"/itens?cnpj=" + cnpj + "&idTerminal=" + idTerminal + "&isMenuEstatico=" + isMenuEstatico + "&isDelivery=" + isDelivery + "&versao=" + VERSAO;   // para testes assim ==> http://localhost:8080/?00906885412592--25
        console.log(VERSAO)
        let urlProd = rotaDeProducao() +"/itens?cnpj=" + cnpj + "&idTerminal=" + idTerminal + "&isMenuEstatico=" + isMenuEstatico + "&isDelivery=" + isDelivery + "&versao=" + VERSAO;
        // let urlProd = rotaDeProducao() +"/json-buscar?cnpj=" + cnpj + "&idTerminal=" + idTerminal + "&isMenuEstatico=" + isMenuEstatico + "&isDelivery=" + isDelivery + "&versao=" + VERSAO;
        console.log("url =>", urlProd);
        const data = await axios.get(urlProd);
        //
        // caso o cliente esteja com o allfood na versao errada
        console.log(`resposta => ${urlProd}`)
        console.log(data)
        if (data.status == 409) {
            throw (data.data)
        } else {
            let json = data.data;
            json['cnpj'] = cnpj;
            json['idTerminal'] = idTerminal;
            json['isMenuEstatico'] = isMenuEstatico;
            json['isDelivery'] = isDelivery;


            // const arrumado = corrigirPromocoes(json)
            // return arrumado;
            return json
        }

    } catch (e) {
        if (e.response.status == 409) {
            throw e.response.data.erro
        } else 
        throw e;
    }
    
}

/**
 * Pega a parte de promocoes que vem no json e aceta os itens verificando os dias e horarios das promocoes
 * @param {object} json 
 * @returns {object} json arrumado com as promocoes
 */
function corrigirPromocoes(jsonV) {
    let json = jsonV
    const promo = json['promo']
    const classes = json['classes']

    const listaPromo = []
    const listaPromoIds = []

    for (var i = 0; i < promo.length; i++) {
        const hojeEhDiaMes = checarDiaDoMes(promo[i])
        const hojeEhDiaSemana = checarDiaDaSemana(promo[i])
        const agoraEhHora = checarHora(promo[i])

        
        /** vamos checar od dias do mes e da semana e vwer se ta ativa */
        if (promo[i].ativa == "0") {
            console.log(`%c FALHOU NO ATIVO  ${promo[i].CodigoProduto_FK}`, 'background: cyan; color: black')
        }

        if (agoraEhHora == false) {
            console.log(`%c FALHOU NO horario  ${promo[i].CodigoProduto_FK}`, 'background: cyan; color: black')
        }

        if (hojeEhDiaSemana == false) {
            console.log(`%c FALHOU NO dia da semana  ${promo[i].CodigoProduto_FK}`, 'background: cyan; color: black')
        }

        if (hojeEhDiaMes == false) {
            console.log(`%c FALHOU NO dia do mes  ${promo[i].CodigoProduto_FK}`, 'background: cyan; color: black')
        }

        if (hojeEhDiaMes && hojeEhDiaSemana && agoraEhHora && promo[i].ativa == "1") {
            console.log(`%c esta promo esta rodando agora, codigo do prod:: ${promo[i].CodigoProduto_FK}`, 'background: violet; color: black')
            listaPromo.push({
                itemId: Number(promo[i].CodigoProduto_FK),
                valor: promo[i].Valor,
            })
            listaPromoIds.push(Number(promo[i].CodigoProduto_FK))
        }
    }

    for (var i = 0; i < classes.length; i++) {
        const itensVendaDaClasses = classes[i]
        for (var j = 0; j < itensVendaDaClasses.itensVenda.length; j++) {
            const item = itensVendaDaClasses.itensVenda[j]
            const idDoItem  = Number(item.id)
            if (listaPromoIds.includes(idDoItem)) {
                console.log(`%c passou em tudo :: ${item.nome} -- ${item.id}`, 'background: green; color: black')
                classes[i].itensVenda[j].promocao = listaPromo.filter((e) => e.itemId == idDoItem)[0].valor
            }

        }
    }

    json['classes'] = classes

    return json
}

/**
 * Verifica se a data de hoje esta entre a data de inicio e data do fim da promocao
 * @param {object} promo 
 * @returns {boolean} true se tiver promocao hoje, false se nao tiver
 */
function checarDiaDoMes(promo) {
    const hoje = new Date()
    const dataInicioPromo = new Date(promo.DataInicio + "T00:00:00")
    const dataFinalPromo = new Date(promo.DataFinal + "T00:00:00")
    
    if (hoje >= dataInicioPromo && hoje < dataFinalPromo) {
        return true
    }
    return false
}
/**
 * Verifica se o horario de agora esta entre a hora de inicio e hora do fim da promocao
 * @param {object} promo 
 * @returns {boolean} true se tiver promocao hoje, false se nao tiver
 */
function checarHora(promo) {
    const [horaInicio, minutoInicio, segundoInicio] = promo.HoraInicio.split(":");
    const [horaFinal, minutoFinal, segundoFinal] = promo.HoraFinal.split(":");
    const hoje = new Date()
    const hojeInicio = new Date()
    hojeInicio.setHours(horaInicio)
    hojeInicio.setMinutes(minutoInicio)

    const hojeFinal = new Date()
    hojeFinal.setHours(horaFinal)
    hojeFinal.setMinutes(minutoFinal)
    
    if (hoje >= hojeInicio && hoje < hojeFinal) {
        return true
    }
    return false
}

/**
 * Verifica se o dia da semana de hoje eh um dia da promocao
 * @param {object} promo 
 * @returns {boolean} true se tiver promocao hoje, false se nao tiver
*/
function checarDiaDaSemana(promo) {
    const hoje = new Date()
    const diaSemanaHoje = hoje.getUTCDay()

    if (diaSemanaHoje == 0) {
        if (promo.Domingo==  "1") return true
        return false
    }
    if (diaSemanaHoje == 1) {
        if (promo.Segunda==  "1") return true
        return false
    }
    if (diaSemanaHoje == 2) {
        if (promo.Terca==  "1") return true
        return false
    }
    if (diaSemanaHoje == 3) {
        if (promo.Quarta ==  "1") return true
        return false
    }
    if (diaSemanaHoje == 4) {
        if (promo.Quinta ==  "1") return true
        return false
    }
    if (diaSemanaHoje == 5) {
        if (promo.Sexta ==  "1") return true
        return false
    }
    if (diaSemanaHoje == 6) {
        if (promo.Sabado ==  "1") return true
        return false
    }
    return false;
}

export async function buscarRefresh(cnpj, idTerminal,  isMenuEstatico, isDelivery) {
    let cnpjConvertido = cnpj.replaceAll(",", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
    try {
        let urlProd = rotaDeProducao() +"/itens?cnpj=" + cnpjConvertido + "&idTerminal=" + idTerminal + "&isMenuEstatico=" + isMenuEstatico + "&isDelivery=" + isDelivery;   // para testes --> cnpj=00906885019092 idTerminal=72
        // let urlProd = rotaDeProducao() +"/pizza?cnpj=" + cnpjConvertido + "&idTerminal=" + idTerminal + "&isMenuEstatico=" + isMenuEstatico + "&isDelivery=" + isDelivery;   // para testes --> cnpj=00906885019092 idTerminal=72
        const data = await axios.get(urlProd);
        let json = data.data;
        json['cnpj'] = cnpjConvertido;
        json['idTerminal'] = idTerminal;
        return json;
    } catch (e) {
        CONSOLOGAR([e])
    }
}

