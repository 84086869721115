<template>
    <div class="background ">

        <button class="btn-fechar-top" type="button" @click="voltar">X</button>

<!-- top bar, imagem, titulo  -->
        <section class="top-bar">
            <top-bar :imagem="item.imagem" :titulo="item.nome" :descricao="item.descricao"/>
        </section>

        
<!-- preco  -->
        <div class="container-valor">
            <p>R$ {{ this.precoUnitarioCalculado }}<span v-if="precoVariavel"> +</span></p>
            <p class="texto-pequeno" v-if="precoVariavel">
                O preço final deste item dependerá dos itens escolhidos e eventuais adicionais selecionados.
            </p>
        </div>

        <br>

        <!-- esta div existe pq daqui pra baixo temos que ter magin inline -->
        <div class="menu-estatico">

<!-- combo por etapa -->
            <div v-if="item.tipo =='comboPorEtapa'">
                <div v-for="(etapa, index) in item.etapas" :key="index">
                    <div class="row linha">
                        <p class="texto-pequeno">{{ etapa.nome }}</p>
                        <p class="texto-pequeno">
                            <span v-if="etapa.permiteEscolherMenosItens">Até </span> 
                            <span v-if="!etapa.permiteEscolherMenosItens">Escolha </span> 
                            <span>{{ etapa.qtdeItensEscolhidosNaEtapa }} itens</span>
                        </p>
                    </div>
                    <!-- <div class="debug">{{ JSON.stringify(etapa.itens) }}</div> -->
                    <div v-for="(subitem, index2) in etapa.itens" :key="index2">
                        <div class="row">
                            <p>{{ subitem.descricaoCompleta }}</p>
                            <p v-if="item.cobrarPorEtapa">{{ converterPreco(etapa.valorEtapa) }}</p>
                            <p v-if="!item.cobrarPorEtapa">{{ converterPreco(subitem.valorVendaItem) }}</p>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
            
<!-- combo por venda conjunta -->
            <div v-if="item.tipo =='comboNormal'">
                <div v-for="(subitem, index) in item.subitens" :key="index">
                    <div class="row">
                        <p>{{ subitem.quantidade }}x {{ subitem.descricao }}</p>
                        <p>{{ converterPreco(subitem.valorPromocao) }}</p>
                    </div>
                </div>
            </div>

<!-- combo por tamanho -->
            <div v-if="item.tipo =='comboPorTamanho'">
                <p class="linha">Selecione um dos itens abaixo:</p>
                <div class="" v-for="(subitem, index) in item.subitens" :key="index">
                    <div class="row">
                        <p>{{ subitem.descricaoCompleta }}</p>
                        <p>{{ converterPreco(subitem.valorVenda) }}</p>
                    </div>
                </div>

            </div>

<!-- mercadoria -->

            <div v-if="item.tipo =='mercadoria'">

            </div>
            
<!-- produto -->
            <div v-if="item.tipo =='produto'">
                <div v-for="(pergunta, index) in item.variacaofichatecnica" :key="index">
                    <div class="row linha">
                        <p class="texto-pequeno">{{ pergunta.pergunta.nomeVariacao }}</p>
                        <p class="texto-pequeno">Máx. {{ pergunta.pergunta.quantidadeMaxima }}</p>
                    </div>
                    <div v-for="(opcao, index2) in pergunta.opcoes" :key="index2" class="row">
                        <p>{{ opcao.descricaoCompleta }}</p>
                        <p>{{ converterPreco(opcao.valorAdicionais) }}</p>
                    </div>
                    <br>
                </div>

            </div>

<!-- pizza  -->
            <div v-if="item.tipo =='comboPizza'">
                <div v-if="item.etapas.pizza_massas != null && item.etapas.pizza_massas.length > 0">
                    <p class="row linha texto-pequeno">Massa:</p>
                    <div v-for="(massa, index) in item.etapas.pizza_massas" :key="index">
                        <div class="row ">
                            <p>{{ massa.descricaoCompleta }}</p>
                            <p>{{ converterPreco(massa.valorVenda) }}</p>
                        </div>
                    </div>
                    <br>
                </div>
                <div v-if="item.etapas.pizza_bordas != null && item.etapas.pizza_bordas.length > 0">
                    <p class="row linha texto-pequeno">Borda:</p>
                    <div v-for="(borda, index) in item.etapas.pizza_bordas" :key="index">
                        <div class="row">
                            <p>{{ borda.descricaoCompleta }}</p>
                            <p>{{ converterPreco(borda.valorVenda) }}</p>
                        </div>
                    </div>
                    <br>
                </div>
                <div class="row linha">
                    <p class="texto-pequeno">Sabores:</p>                    
                    <p class="texto-pequeno">Máx.  {{ item.etapas.pizza_sabores.qtdeItensEscolhidosNaEtapa }}</p>
                </div>
                <div v-for="(sabor, index) in item.etapas.pizza_sabores.itens" :key="index">
                    <div class="row">
                        <p>{{ sabor.descricaoCompleta }}</p>
                        <p>{{ converterPreco(sabor.valorPromocao) }}</p>
                    </div>
                    <p class="texto-pequeno" style="text-align: justify">{{ sabor.observacao }}</p>
                    <br>
                    <br>
                </div>
            </div>
            <!-- <div class="debug">{{ JSON.stringify(item.etapas.pizza_sabores.itens) }}</div> -->


        </div>
    </div>
</template>

<script>
import TopBar from '../components/ListaItensVenda/TopBar.vue'


/**
 * @param {object} item vem do store
 */
export default {
    name: "MenuEstaticoItemView",
    props: {
    },

    data() {
       return {
         item: this.$store.state.itemSelecionado
       }
    },

    created() {
        this.imprimir()
    },

    computed: {
        precoVariavel() {
            if (this.item.tipo=='comboPorEtapa') return true
            if (this.item.tipo=='comboPizza') return true
            if (this.item.tipo=='comboPorTamanho') return true
            return false
        },
        precoUnitarioCalculado() {
            if (this.item.promocao == 0 || this.item.promocao == null) {
                return this.item.preco.toFixed(2).replace(".", ",");
            } else {
                return this.item.promocao.toFixed(2).replace(".", ",");
            }
        },
    },

    methods: {

        imprimir() {
            console.log(this.item)
        },
        
        converterPreco(numero) {
            return "R$ " + numero.toFixed(2).replace(".", ",")
        },
        /**
         * limpar os itens temporarios e dai voltar para o cardapio
         */
        voltar() {
            this.$store.dispatch('setLimparItemTemporario')
            this.$router.back()
        }

    },

    components: {
        TopBar,
    }

}
</script>

<style scoped>

.menu-estatico p, h1, h2, h3, h4, h5 { color:  white}

.menu-estatico { width: 88%; margin-inline: 6%; }

.menu-estatico .texto-grande  { font-size: 1.2rem; }

.menu-estatico .texto-medio   { font-size: 1.0rem; }

.menu-estatico .texto-pequeno { font-size: 0.8rem; }

.menu-estatico .linha { border-bottom: solid 1px white; margin-bottom: 5px; padding-bottom: 5px; }

.menu-estatico .row {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-block: 8px;
}



/* mobiles  */
@media only screen and (max-width: 720px) {
    .itens-container {
        color: white;
    }
}


.container-valor {
    background:white;
    color: #000;
    z-index: 45654;
    /* border-top: 3px white solid; */
    padding: 10px;
    padding-block: 15px;
    margin: 0;
}

.container-valor > p {
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
}

.container-valor > .texto-pequeno {
    color: black;
    border-top: solid 1px black;
    padding-top: 5px;
    margin-top: 10px;
    font-weight: normal;
    font-size: 0.8rem;
}

</style>