<template>
    <router-link class="link btn" :to="{ name: 'cardapio' }">{{ textoNoBotao }}</router-link>
</template>

<script>
    import json from "../../assets/mocks/produtos.json"

    export default {

        name: "ButtonAbrirCardapio",

        props: {
            texto: String,
        },

        data() {
            return {
            tasks: [],
            postData: {},
            myJson: json,
            imageURL: this.$store.state.backgroundImage,
            isMouseOver: false
        }},

        computed: {
            textoNoBotao() {
                if (this.$store.state.isMenuEstatico) {
                    return "Abrir Menu"
                } return "Abrir Cardápio"
            }
        },

        methods: {
        
        }
    }
</script>


<style scoped>

    .link {
        font-size: 16px;
        padding-block: 10px;
        padding-inline: 20px;
        text-decoration: none;
        font-weight: 300;
        font-style: normal;
        color: white;
    }

    .btn {
        position: relative;
        background: linear-gradient(120deg, var(--cor1) 70%, var(--cor1) 60%);
        color: aliceblue;
        border-radius: 20px;
        box-shadow: 0px 6px 8px 8px rgba(0, 0, 0, 0.4);
        border-style: solid;
        border-color: var(--cor1-clara);
    }


</style>