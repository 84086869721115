<template>
    <div class="todas-promocoes">

        <div v-for="(promocao, index) in promocoes" :key="index">

                <div @click="onClickPromocao(promocao.nome)" class="divImg container" :style="{backgroundImage: 'url(' +promocao.imagem+')'}">
                    <h4>{{ promocao.nome }}</h4>

                    <div style="flex-grow: 1"></div>

                    <div class="row">
                        <span class="pPreco">de: <p>{{precoFormatado(promocao.preco)}}</p></span>
                        <span class="pPromocao">Por: <p>{{precoFormatado(promocao.promocao)}}</p></span>     
                    </div>       
                </div>

        </div>

    </div>
</template>

<script>
export default {
    name: "DestaquesPromocoes",
    props: {
        promocoes: Array
    },
    methods: {
        precoFormatado(item) {
            return "R$ " + item.toFixed(2).replace(".", ",")
        },
        onClickPromocao(nomeItemClicado) {
            let produtos = this.$store.state.produtos
            let esteItem = null
       
            produtos.forEach((cadaClasse) => {
                cadaClasse.itensVenda.forEach((cadaItemDaLista) => {
                    if (cadaItemDaLista.nome == nomeItemClicado) {
                        esteItem = cadaItemDaLista
                    }
                })
            })
        
            this.$store.dispatch("setItemSelecionado", esteItem);

            if (this.$store.state.isMenuEstatico == false) {
                this.$router.push({name: "item", params: {item: esteItem,id: esteItem.id}});
            } else {
                this.$router.push({name: "menu-estatico-item", params: {item: esteItem,id: esteItem.id}});
            }
        }
    }
}
</script>

<style scoped>

.todas-promocoes {
    padding-top: 10px;
    padding-bottom: -10px;
    padding-inline: 1px;
    margin-inline: 10px;
    border-radius: 30px;
    
}

.divImg  {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    height: 185px;
    width: 100%;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    border: 2px rgba(80, 80, 80, 1) solid;
}

.divImg h4 {
    position: relative; 
    background: var(--cor1-escura);
    margin: 0;
    border-top-left-radius: 20px; 
    border-top-right-radius: 20px; 
    padding-block: 10px;
}

.divImg .row {
    display: flex;
    justify-content: space-between;
    top: 80%;
    color: white;
    padding-block: 10px;
    padding-inline: 20px;
    border-bottom-left-radius: 20px; border-bottom-right-radius: 20px;
    background: rgba(0, 0, 0, 0.65);
}

.pPreco, .pPromocao {display: flex; font-size: 20px; }

.pPreco p {text-decoration: line-through; margin-left: 10px;}

.pPromocao p {margin-left: 10px;}

.promocao-imagem-titulo .divp {  padding: 3px; top: 100%; transform: translateY(-150%); background: black};

.cada-promocao {height: 220px; width: 80%;}

.container {
    animation: container 0.5s forwards;
}

@keyframes container {
    from {
        scale: 0.9; 
        opacity: 0.5;
    }
    to {
        scale: 1.0;
        opacity: 1;
    }
}

</style>