<template>
  <div class="theBody">

    <hr>
    <h2>-1/12</h2>
    <h2>{{ -1/12 }}</h2>
    <hr>

        <h5>1000</h5>
        <h2>
            {{ somar(1000) }}
        </h2>
        <h5>1000000</h5>
        <h2>
            {{ somar(1000000) }}
        </h2>
        <h5>2000</h5>
        <h2>
            {{ somar(2000) }}
        </h2>
        <h5>2000000</h5>
        <h2>
            {{ somar(2000000) }}
        </h2>
        <h5>3000</h5>
        <h2>
            {{ somar(3000) }}
        </h2>
        <h5>3000000</h5>
        <h2>
            {{ somar(3000000) }}
        </h2>
        <h5>4000</h5>
        <h2>
            {{ somar(4000) }}
        </h2>
        <h5>4000000</h5>
        <h2>
            {{ somar(4000000) }}
        </h2>
        <h5>5000</h5>
        <h2>
            {{ somar(5000) }}
        </h2>
        <h5>5000000</h5>
        <h2>
            {{ somar(5000000) }}
        </h2>
  </div>
</template> 
 
<script> 

export default {
    name: "TesteView",

    components: {},

    data() {
        return{ 
            dataTop: 10,
            dados: null
        }
    },
    
    methods: {

        somar(final) {
            let number = 0
            for (var i = 0; i < final; i++) {
                number = number + i
            }

            return number
        }

    }

}
</script>

<style scoped>
.theBody {
    position: absolute;
    top: 0px; 
    left: 0px;
    height: 100%;
    width: 100%;
    background: red;
    color: white
}


.pixWhite {
    position: relative;
    top: 50px;
    left: 0px;
    width: 10px;
    height: 10px;
    background: white;
}
.pixGreen {
    position: relative;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    background: green;
}

</style>