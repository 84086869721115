<template>
    <div>
        <div :class="['row']">

            <div class="img-container">
                <!-- <img class="logo" sc="../../assets/logo_allfood_escrita_branca.png" alt=""> -->
                <img class="logo" :src="logo" alt="">
                
            </div>

<!-- caso nao seja delivery, mostrar estes botoes  -->
            <div class="preco" v-if="this.$store.state.isDelivery == false"> 

                <router-link v-if="!this.$store.state.isMenuEstatico" class="icone-texto" style="background: var(--gradiente-btn-conta)" :to="{ name: 'garcom' }">
                    <img src="../../assets/icons/ic-waiter.svg" alt="" class="ic-carrinho">
                    <p>CHAMAR<br>O GARÇOM</p>
                </router-link>

                <router-link v-if="!this.$store.state.isMenuEstatico" class="icone-texto" :to="{ name: 'conta' }" style=""  :is="podeVerConta ? 'span' : 'router-link'" @click="alertarVerConta()">
                    <img  src="../../assets/icons/ic-wallet.svg" alt="" :class="['ic-carrinho']" />
                    <p>CONTA DA<br>{{mesaOuCartao}} {{numeroMesa}}</p>
                </router-link>

                <!-- apenas para menu estatico -->
                <div v-if="this.$store.state.isMenuEstatico"  class="menu-estatico-container">
                    <h3>Menu online</h3>
                </div>

            </div>

<!-- caso seja delivery, mostrar isso  -->
            <div class="preco" v-if="this.$store.state.isDelivery == true">

                <router-link class="icone-texto" style="background: var(--gradiente-btn-conta)" :to="{ name: 'statusdelivery' }">
                    <img src="../../assets/icons/ic_meus_pedidos.png" alt="" class="ic-carrinho">
                    <p>MEUS PEDIDOS</p>
                </router-link>

            </div>

        </div>

        <!-- so barra de busca aqui  -->
        <div v-if="this.$store.state.mostrarBarraBusca" style="display: flex; flex-direction: row">
            <div style="width: 10vh; height: 10%;">
                
            </div>
            <div style="flex-grow: 1;">
                <BarraDeBuscar/>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapState } from 'vuex';
import BarraDeBuscar from './BarraDeBuscar.vue';
import { CONSOLOGAR } from '../Controle/debug';
export default {
    name: "MenuTop",
    data() {
        return {
            numeroDeIensCarrinho: 0,
            precoTotalAteAgora: 0.0,
            logo: this.$store.state.logo,
            mesaOuCartao: this.$store.state.porCartao ? "COMANDA" : "MESA",
        };
    },
    computed: {
        ...mapState([
            'itensCarrinho'
        ]),
        numeroMesa() {
            if (this.$store.state.porCartao) {
                return "";
            }
            else {
                return this.$store.state.mesa;
            }
        },
        podeVerConta() {
            if (this.$store.state.itensCarrinho.length > 0) {
                return true;
            }
            return false;
        },
    },
    created() {
        this.calcularPrecoTotal();
    },
    methods: {
        clicouCarrinho() {
            CONSOLOGAR(["clicou"]);
        },
        calcularPrecoTotal() {
            for (var i = 0; i < this.itensCarrinho.length; i++) {
                this.precoTotalAteAgora = this.precoTotalAteAgora + parseFloat(this.itensCarrinho[i].precoTotal);
            }
        },
        alertarVerConta() {
            if (this.podeVerConta) {
                Swal.fire({ title: "Desculpe!", text: "Você não poderá ver a conta enquanto houver itens no carrinho de compras." });
            }
        }
    },
    components: { BarraDeBuscar }
}
</script>

<style scoped>

.swal2-title{
    font-family: Arial, Helvetica, sans-serif !important;
    color: red;
}
.swal-title{
    font-family: Arial, Helvetica, sans-serif !important;
    color: green;
}
.swal2-popup {
  font-size: 2.6rem !important;
  font-family: Georgia, serif;
}


.row {
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    background: var(--cor1);
    color: white;
}

.img-container {
    width: 10vh;
    /* height: 100%; */
    height: 10vh;
    background: var(--cor1-muito-escura);
}

.logo {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    max-width: 80%;
    max-height: 80%;
}

.ic-carrinho {
    height: 25px; filter: invert(100%);
}

.preco {
  display: flex;
  flex-grow: 1;
  height: 10vh;;
}

.icone-texto {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    color: white;
    text-decoration: none;
    font-size: 14px;
    justify-content: center;
    margin-inline: auto;
    padding-inline: 15px;
}

.icone-texto img {

}

.icone-texto p {
    margin-left: 10px;
    font-size: 12px;
}

.menu-estatico-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}




</style>