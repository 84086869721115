<template>
    <div style="color:white">
        <MenuTopTemplate :titulo="'Dados do endereço'" :onPressedClose="() => { this.$router.go(-1); }"/>
        <button class="btn-fechar-top" type="button" @click="voltar">X</button>

        <div v-if="loading">
            <p style="color: white;">loading</p>
            
        </div>


        <TelefoneComDDD :mostrar="true" @callback="digitouTelefone"/>


        <div v-if="clienteNovo" class="card">
            <p style="font-weight: bold;">Parece que você ainda não tem cadastro conosco!</p>  
            <p style="font-size: 0.8rem; margin-top: 10px;">Por favor, entre com seus dados abaixo para que possamos realizar sua entreaga!</p>
            <p>{{clienteNovoTelefone}}</p>
        </div>

        <div class="anim-entrada" style="margin-bottom: 100px; max-width: 90%; margin-inline: auto;">
            <DadosEndereco 
                :usarEnderecoAntigo="clienteNovo ? false : true" 
                :exigirNome="clienteNovo" 
                :telefoneCliente="clienteNovoTelefone" 
                @onFinished="voltar"
            />
        </div>
         
       
    </div>
</template>

<script>
import MenuTopTemplate from '@/components/Base/MenuTopTemplate.vue';
import DadosEndereco from '@/components/Delivery/DadosEndereco.vue';
import TelefoneComDDD from '@/components/Delivery/TelefoneComDDD.vue';

export default {
    name: "DeliveryEnderecoView",
    components: { MenuTopTemplate,  DadosEndereco, TelefoneComDDD },
    data() {
        return {
            clienteNovo: false,
            clienteNovoTelefone: null,
        }
    },
    computed: {
        loading() {return this.$store.state.loading}
    },
    mounted() {
        this.clienteNovo = this.$route.query.novo
        this.clienteNovoTelefone = this.$route.query.telefone
    },
    updated() {

    },
    methods: {
        voltar() {
            this.$router.go(-2);
        },

        async digitouTelefone(dados) {
            let telefone = dados.telefone

            
            try {
                await this.$store.dispatch("enviarTelefone", telefone)
                this.clienteNovo = false;
                this.clienteNovoTelefone = telefone
            } catch (error) {
                if (error == 501) {
                    console.log("cliente nao cadastrado")
                    this.clienteNovo = true
                    this.clienteNovoTelefone = telefone
                } 
                if (error == 2424) { // o cliente existe, mas nao veio a taxa de entrega
                    console.log("o cliente existe, mas nao veio a taxa de entrega")
                    this.clienteNovo = false
                    this.clienteNovoTelefone = telefone
                } 
            }
   
        }
    }
}
</script>

<style>

</style>