
class ClienteDadosModel {

    /**
     * 
     * @param {string} id 
     * @param {string} cnpj 
     * @param {string} cpf 
     * @param {string} email 
     * @param {string} nome 
     * @param {string} senha 
     * @param {string | number} telefone 
     */
    constructor(id, cnpj, cpf, email, nome, senha, telefone) {
        this.id = id
        this.cnpj = cnpj
        this.cpf = cpf
        this.email = email
        this.nome = nome
        this.senha = senha
        this.telefone = telefone
    }

    
    /**
     * 
     * @param {object} json 
     * @returns {ClienteDadosModel}
     */
    fromJson(json) {
        this.id = json["Id"] ?? json["id"]
        this.cnpj = json["cnpj"] ?? json["cnpj"]
        this.cpf = json["cpf"] ?? json["cpf"]
        this.email = json["email"] ?? json["email"]
        this.nome = json["nome"] ?? json["nome"]
        this.senha = json["senha"] ?? json["senha"]
        this.telefone = json["telefone"] ?? json["telefone"]
        return this
    }


}

export default ClienteDadosModel