<template>
    <div class="etapas-counter">
        <div v-for="(n, index) in quantidadeTotal" :key="index">
            <div v-if="index != indexCorrente" class="circulo vazio"></div>
            <div v-if="index == indexCorrente" class="circulo selecionado"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EtapasCounter",
    props: {
        quantidadeTotal: Number,
        indexCorrente: Number,
    },
    methods: {},
}
</script>

<style scoped>

.etapas-counter {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-block: 15px;
}

.circulo {
    border: 1px solid white;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin-inline: 5px;
}

.vazio {
    background: rgba(0, 0, 0, 0.2);
}
.selecionado {
    background: rgba(255, 255, 255, 0.8);
    height: 15px;
    width: 15px;
}


</style>