
//
//
//------------------------ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ROTA de PRODUCAO ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼
export const rotaDeProducaoPadrao = "https://analytics-api.dalcatech.com.br/cardapio/v3"; // PRESTAR ATENCAO na versao v2 ou v3 !!!!
// export const rotaDeProducaoPadrao = "https://api-teste.dalcatech.com.br/cardapio/v3"; // PRESTAR ATENCAO na versao v2 ou v3 !!!!

//
//------------------------ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ROTA de PRODUCAO HARD ROCK ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼
export const rotaDeProducaoHardRock = "https://hrcpoa.dalcatech.com.br/cardapio/v3";

//
// ---------------------------- ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ROTA de TESTE ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ --------------------------------------
// export const rotaDeProducaoPadrao = "http://192.168.100.231:8000/cardapio/v3"; //PRESTAR ATENCAO no ip desta maquina!!!
// export const rotaDeProducaoPadrao = "https://ipfixo.dalcatech.com.br/cardapio/v3";
 //PRESTAR ATENCAO no ip desta maquina!!!
// export const rotaDeProducaoPadrao = "http://192.168.100.28:8000/cardapio/v2";


export const VERSAO = "1.0.0" // IMPORTANT! lembrar disso aqui que vai na rota buscaritens

/** indica se devemos usar rota do hard rock */
var usarRotaHardRock = false;

export function setarUsarRotaHardRock() {
    usarRotaHardRock = true;
}

/**
 * Alterna entre rota padrao ou rota HardRocK (pq  eles usam ip fixo)
 * @returns {String} rota 
 */
export const rotaDeProducao =  () => {
    if (usarRotaHardRock) {
        console.log("%c usando rota de hard rock", "background: red");
        return rotaDeProducaoHardRock;
    }
    console.log("%c usando rota padrao", "background: green");
    return rotaDeProducaoPadrao
};


//