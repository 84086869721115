<template>
    <div class="center">
        <div class="top"></div>
        <div class="flex"></div>
        
        <img :src="this.$store.state.logo" alt="" />
        
        <h1>Desculpe-nos!</h1>
        <p>Estamos fechados no momento.</p>
        <br>
        <div v-if="descricaoAberturaCasa != null && descricaoAberturaCasa != ''">
            <p>{{ descricaoAberturaCasa }}</p>
        </div>
        
        <div class="flex"></div>
        <div class="bottom"></div>
    </div>
</template>

<script>
export default {
    name: "CasaFechadaView",
    props: { descricaoAberturaCasa: String}
}
</script>

<style scoped>

.flex {
    flex: 1; 
}

.top, .bottom {
    height: 15vh;
    width: 80vw;
}

.top {background: linear-gradient(to top, rgba(255, 255, 255, 0.3), transparent 100%)}

.bottom {background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), transparent 100%)}

.center {
    height: 100vh;
    color: white;
}

p {text-align: center; margin-inline: 15%;}

img {
    width: 50%;
}

</style>