


class StoragePedidos {
    static salvar(cnpj, numeroEmpresaFK, mp_paymentId, pixQrCodeData, pedido) {
        const json = JSON.stringify({
            cnpj: cnpj,
            numeroEmpresaFK: numeroEmpresaFK,
            pixQrCodeData:pixQrCodeData,
            mp_paymentId:mp_paymentId,
            pedido: pedido,
            data: new Date().toISOString(),
        })
        localStorage.setItem("pedido-pix", json)

        console.log("salvando pedido no local storage")
    }

    static deletar() {
        localStorage.removeItem("pedido-pix")
        console.log("limpando local storage")
    }

    static checar() {
        console.log("checando local storage... ... ...")
        const pedidSalvo = localStorage.getItem("pedido-pix")
        if (pedidSalvo == null) {
            return null
        } else {

            // vamos pegar a data agora e somar duas hoaras pra ver se o pedido ja nao expirou
            const hoje = new Date();
            hoje.setHours(hoje.getHours() + 4)

            const parsed = JSON.parse(pedidSalvo)
            
            // vamos pegar a data do pedido em si para comaparar
            parsed.data = new Date(parsed.data)

            console.log("-----------------------------------------------------------------------");
            console.log(parsed.data);
            console.log(hoje);
            console.log(parsed.data <= hoje);
            console.log(parsed.data > hoje);

            console.log("pedidSalvo tirado do local storage")
            console.log(pedidSalvo)

            if (parsed.data <= hoje) {
                return parsed
            } else {
                return null
            }
        }
    }
}

export default StoragePedidos