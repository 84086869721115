<template>
    <div style="height: 100%;">
        <div class="bottom-bar">
                <!-- <hr /> -->
                <div class="bottom-bar-row">
                    <div>
                        <h4>Total:</h4>
                        <p>R$ {{ valorFinalTotal }}</p>
                    </div>

                    <button :class="[podeEnviar ? 'btn-verde' : 'btn-desabilitado']" @click="[podeEnviar ? onClickConfirmar() : funcaoAnimarTextoDesabilidado()]">
                        <img src="../../assets/icons/ic-check.png" alt="" />
                        <p>ENVIAR PEDIDO</p>
                    </button>
                </div>
            </div>
    </div>
</template>

<script>
export default {
    name: "BottomBarCarrinho",
    components: {
    },
    props: {
        podeEnviar: Boolean,
        valorFinalTotal: undefined,
        onClickConfirmar: Function,
    }
}
</script>

<style scoped>
.bottom-bar {
    background: white;
    padding: 5px;
    padding-bottom: 35px;
    height: 100%;
}


.bottom-bar-row {
    width: 100%;
    display: block;
}
.bottom-bar-row > div {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.bottom-bar-row > div > h4 {
    font-weight: bold;
    color: var(--primaryDark_azul);
    font-size: 18px;
}
.bottom-bar-row > div > p {
    font-weight: bold;
    color: green;
    font-size: 22px;
}
.bottom-bar-row > button {
    width: 100%;
    height: 60px;
    justify-content: center;
}

</style>