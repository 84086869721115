<template>
    <section class="top-bar">
        <div v-if="temImagem">
            <img id="mercadoriaImg" :src="this.imagem" alt="mercadoria"  @error="onErrorImagem" ref="imagemzinha">
        </div>
        <div :class="temImagem ? 'titulo-descricao' : 'titulo-descricao-sem-imagem'">
            <h3>{{ this.titulo }}</h3>
            <p v-if=" this.descricao != null &&  this.descricao != ''">{{ this.descricao }}</p>
        </div>

    </section> 
</template>


<script>
export default {
    name: "TopBar",
    props: {
        imagem: String,
        titulo: String,
        descricao: String,
    },
    data() {
        return {
            temImagem: true
        }
    },
    computed: {},
    methods: {
        /**
         * caso a url da imagem nao esteja funcionando, deve entao usar  a fallback image i-c-camera.png dos assets
         */
        onErrorImagem() {
            this.temImagem = false;
            this.$refs.imagemzinha.src = require(`../../assets/icons/ic-camera.png`);
        },
    }

}
</script>

<style>
.top-bar {
    margin: 0;
    background: black;
    
}

/* mobiles  */
@media only screen and (max-width: 720px) { 
    #mercadoriaImg {
        margin: 0;
        width: 100%;
        aspect-ratio: 1.5;
        object-fit: cover;
    }
}

/* desktops */
@media only screen and (min-width: 720px) { 
    #mercadoriaImg {
        margin: 0;
        width: 40%;
        aspect-ratio: 1.5;
        object-fit: cover;
    }
}

#nomeDaMercadoria {

    width: 100%;
    background: black;
    color: white;
    font-size: 18px;
    padding-block: 15px;
    margin: 0;
    margin-top: -4px;
    word-wrap: normal;
}
.titulo-descricao {
    color: white;
    padding-inline: 20px;
    padding-block: 10px;
}
.titulo-descricao h3 {
    margin: 0;
}
.titulo-descricao p {
    margin-top: 10px;
    font-size: 14px;
}
.titulo-descricao-sem-imagem {
    color: white;
    padding-inline: 20px;
    padding-top: 70px;
    padding-bottom: 30px;
    background: linear-gradient(to top, transparent, var(--cor1-muito-escura));
}
.titulo-descricao-sem-imagem h3 {
    margin: 0;
}
.titulo-descricao-sem-imagem p {
    margin-top: 10px;
    font-size: 14px;
}

</style>