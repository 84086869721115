
export function setarCores(cor1, cor2, textoEscuro) {
    
    document.documentElement.style.setProperty('--cor1', cor1);
    document.documentElement.style.setProperty('--cor1-clara', clarearCor(cor1, 10));
    document.documentElement.style.setProperty('--cor1-escura', escurecerCor(cor1, 35));
    document.documentElement.style.setProperty('--cor1-muito-escura', escurecerCor(cor1, 120));

    document.documentElement.style.setProperty('--cor2', cor2);
    document.documentElement.style.setProperty('--cor2-clara', clarearCor(cor2, 20));
    document.documentElement.style.setProperty('--cor2-escura', escurecerCor(cor2, 30));

    document.documentElement.style.setProperty('--cor-texto', textoEscuro ? "black" : "white");

    document.documentElement.style.setProperty('--gradiente-background', "linear-gradient(to bottom, var(--cor1) 10%, " + escurecerCor(cor1, 200) + ")");
}


function clarearCor(hex, percentage) {
    // Remove the '#' symbol if present
    hex = hex.replace('#', '');

    // Convert the hex color to RGB values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Calculate the lightened RGB values
    const lightenValue = 1 + (percentage / 100);
    const lightenedR = Math.min(Math.round(r * lightenValue), 255);
    const lightenedG = Math.min(Math.round(g * lightenValue), 255);
    const lightenedB = Math.min(Math.round(b * lightenValue), 255);

    // Convert the lightened RGB values back to hex
    const lightenedHex = `#${(lightenedR).toString(16).padStart(2, '0')}${(lightenedG).toString(16).padStart(2, '0')}${(lightenedB).toString(16).padStart(2, '0')}`;

    return lightenedHex;
}

function escurecerCor(hexColor, amount) {
    // Remove the '#' symbol if present
    hexColor = hexColor.replace('#', '');

    // Parse the hex color into RGB components
    var r = parseInt(hexColor.substr(0, 2), 16);
    var g = parseInt(hexColor.substr(2, 2), 16);
    var b = parseInt(hexColor.substr(4, 2), 16);

    // Calculate the darkened RGB values
    r = Math.max(r - amount, 0);
    g = Math.max(g - amount, 0);
    b = Math.max(b - amount, 0);

    // Convert the darkened RGB values back to hex
    var darkenedHexColor = '#' + (r * 0x10000 + g * 0x100 + b).toString(16).padStart(6, '0');

    return darkenedHexColor;
}