
const DEBUG = true;

/**
 * 
 * @param {Array} textos 
 */
export const CONSOLOGAR = (textos) => {
    if (DEBUG) {
        if (typeof(textos) == String) {
            console.log(textos)
        } else {
            for (var i = 0; i < textos.length; i++) {
                console.log(textos[i])
            }
        }
    }
}

