import axios from "axios";
import { rotaDeProducao } from "./rotas";
import { CONSOLOGAR } from "@/components/Controle/debug";


/**
 * @function apiSincronizacao()
 * @desc Busca a tabela sincronizacaoservicos para ver caiu a conexao do local com o cloud
 * retorna true quando pode avacar normalmente e false quando a internet do local caiu
 * @param {String} cnpj 
 * @param {int} idTerminal 
 * @returns {new Promise<boolean>} pode avancar? true = sim, false = nao
 */
export async function apiSincronizacao(cnpj, idTerminal) {
    let cnpjConvertido = cnpj.replaceAll(",", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");


    return new Promise(  (resolve, reject) => {

        let urlProd = rotaDeProducao() +"/sinc?cnpj=" + cnpjConvertido + "&idTerminal=" + idTerminal;   // para testes --> cnpj=00906885019092 idTerminal=72
        axios.get(urlProd).then((data) => {
            let json = data.data;
            CONSOLOGAR(["pode avancar => ", json["podeAvancar"], "; dobanco => ", json["do_banco"], "; do servidor => ", json["do_servidor"]])
            // return json["podeAvancar"];
            if (json["podeAvancar"] == true || json["podeAvancar"] == "true") {
                resolve(true)
            } else {
                throw "erro, nao sincronnizado"
            }
        }).catch((err) => {
            CONSOLOGAR([err])
            reject(false)
        });

    })

}

