import { render, staticRenderFns } from "./TipoComboNormal.vue?vue&type=template&id=473f9eee&scoped=true&"
import script from "./TipoComboNormal.vue?vue&type=script&lang=js&"
export * from "./TipoComboNormal.vue?vue&type=script&lang=js&"
import style0 from "./TipoComboNormal.vue?vue&type=style&index=0&id=473f9eee&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "473f9eee",
  null
  
)

export default component.exports