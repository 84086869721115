<template>
    <div class="delivery-view-corpo">

        <MenuTopTemplate :titulo="'Dados para entrega'" :onPressedClose="() => { this.$router.go(-1); }"/>

        <button class="btn-fechar-top" type="button" @click="voltar">X</button>


        <div v-if="loading && !pedidoEnviado" class="center img-loading-container">
            <img src="../assets/loading.gif" alt="" class="img-loading">
        </div>
 
        <!-- para testar -->
        <!-- <button @click="() => {this.onSubmitPagamentoMp('debit')}">pix</button> -->

        <div v-if="!loading && pedidoEnviado">
            <DeliverySucessoView/>
        </div>

        <div v-if="!loading && !pedidoEnviado">
            <TelefoneComDDD @callback="enviarTelefone" :mostrar="true" :loadingEnviarTelefone="this.loadingTelefone" :whiteBackground="false" />

            <div v-if="voltouRespostaTelefone && !clienteExistente" class="card">
                <p style="font-weight: bold;">Parece que você ainda não tem cadastro conosco!!</p>  
                <p>Por favor, entre com seus dados abaixo para que possamos realizar sua entreaga!</p>
            </div>           

    <!-- form para preencher endereco  -->    
            <div v-if="voltouRespostaTelefone">
            
    <!-- endereco ou tirar no local  -->
                <div v-if="clienteExistente">
                    <div class="card">
                        <p>Ola, <b>{{ dadosClienteApi.nome }}</b>!</p>
                        <p>Por favor, confirme o endereço de entrega e selecione <b>"Enviar pedido"</b>.</p>
                        <div style="margin-block: 10px;">
                            <ControleEnderecos :enderecos="todosEnderecos" @callback="callbackEnderecoController"/>
                        </div>
                    </div>
                </div>

    <!-- endereco cado cliente nao exista -->
                <div v-if="!clienteExistente">
                    <DadosEndereco  :exigirNome="true" :telefoneCliente="this.telefone"/>
                </div>

    <!-- mensagem nao pode enviar pedido  -->
                <div class="card" v-if="this.$store.state.deliveryTaxas == 'erro' && !retirarNoLocal && this.casaFechada == false">
                    <p>Desculpe! Infelizmente nós ainda não fazemos entregas para este bairro.</p>
                </div>

    <!-- mensagem a casa esta fechada  -->
                <div class="card" style="border: solid 5px red" v-if="this.casaFechada == true">
                    <p>Desculpe! Infelizmente este estabelecimento está fechado no momento para pedidos delivery.</p>
                </div>
                
                <!-- tipo de pagamento            --> 
                
                <div v-if="this.casaFechada == false && (this.$store.state.deliveryTaxas != 'erro' || retirarNoLocal)">
                    <TipoPagamento @callback="callbackTipoPagamento" :valorTotalPedidoMaisTaxa="valorTotal" @pagarPeloAppSelecionado="(val) => {this.pagarPeloApp = val}"/>
                </div>

                <div v-if="this.casaFechada == false && pagarPeloApp && this.$store.state.mp_public_key != null && (this.$store.state.deliveryTaxas != 'erro' || retirarNoLocal)">
                    <mp-pagamento 
                    :total="this.valorTotal"
                    :pedido="this.montarPedidoFinal()"
                    @onSubmit="onSubmitPagamentoMp"
                    />
                </div>
             

    <!-- botao enviar pedido  -->
                <div v-if="!pagarPeloApp && (this.$store.state.deliveryTaxas != 'erro' || retirarNoLocal) && this.casaFechada == false">


                    <div class="card" v-if="this.$store.state.deliveryTaxas != 'erro' || retirarNoLocal ">

                        <div class="resumo-pedido">
                            <span>
                                <p>Valor do pedido:</p>
                                <p class="valor-pedido"> {{ valoresEmReais.pedido }}</p>
                            </span>

                            <div v-if="!retirarNoLocal">
                                <span>
                                    <p>Taxa de entrega:</p>
                                    <p class="valor-taxa"> {{ valoresEmReais.entrega }}</p>
                                </span>
                                <span>
                                    <p>Valor total:</p>
                                    <p class="valor-total"> {{ valoresEmReais.total }}</p>
                                </span>
                                <span>
                                    <p>Tempo estimado de entrega:</p>
                                    <p class="valor-tempo-entrega">{{ this.taxaDeEntrega.TempoMedioEntrega  }} min</p>
                                </span>
                            </div>

                        </div>

                        <br>

                        <p v-if="!podeEnviarPedido" style="color: red; font-size: 12px;">Para poder enviar o pedido, verifique se realizamos entregas no bairro escolhido e verifique se um método de pagamento foi selecionado </p>
                    
                        <div v-if="habilitarBotaoEnviar">
                            <button v-if="!podeEnviarPedido" class="btn-normal" style="background: grey;">Enviar pedido</button>
                            <button v-if="podeEnviarPedido" class="btn-normal" @click="enviarPedido">Enviar pedido</button>
                        </div>

                    </div>
                </div>


                

            </div> 


            <div style="margin-bottom: 100px;">
                
            </div>
        </div>


    </div> 
</template>

<script>`   `
import MenuTopTemplate from "@/components/Base/MenuTopTemplate.vue";
import ApiDelivery, {} from "../api/ApisDelivery"
// import PedidoModel from '@/model/pedidoModel'; 
// import apiEnviarPedido from '@/api/ApiEnviarPedido';  
import TelefoneComDDD from '@/components/Delivery/TelefoneComDDD.vue';
import DadosEndereco from "@/components/Delivery/DadosEndereco.vue";
import ControleEnderecos from "@/components/Delivery/ControleEnderecos.vue";
import TipoPagamento from "@/components/Delivery/TipoPagamento.vue";
import Swal from 'sweetalert2';
import DeliverySucessoView from "./DeliverySucessoView.vue";
// eslint-disable-next-line no-unused-vars
import { converterParaDinheiroString } from "@/utils/Dinheiro";
import { setarCores } from '@/utils/SetarCores';
import MpPagamento from '@/components/mercadopago/MpPagamento.vue';
import MpStatusScreenPix from '@/components/mercadopago/MpStatusScreenPix.vue';
export default {
    name: "DeliveryView",

    props: {
        // pedido: PedidoModel  
    },
    // In your 'delivery' component
    mounted() {
        // const data = this.$route.params;      // Access the data using the key you specified
        // const dataq = this.$route.query;      // Access the data using the key you specified
    },

    data() {
        return {
            loading: false,
            loadingTelefone: false,
            loadingCep: false,
            telefone: "",
            casaFechada: true,
          
            nomeCliente: "",
            voltouRespostaTelefone: false,
            clienteExistente: false,
            cep: "",
            cepNaoEncontrado: false,
            /**@type {EnderecoModel} */
            endereco: null,
            todosEnderecos: [],
            dadosClienteApi: null,
 
            // dados de callback
            tipoPagamento: null,
            retirarNoLocal: false,

            pedidoEnviado: false,

            dataagora: Date(),

            habilitarBotaoEnviar: true,

            pagarPeloApp: this.$store.state.mp_public_key != null,

        };
    }, 

    
    created() {
        this.verficiarSeACasaEstaAberta();
        setarCores(this.$store.state.cor1, this.$store.state.cor2)
    },

    computed: {

        pedido() {
            return this.$store.state.deliveryPedido;
        },  

        taxaDeEntrega() {
            return this.$store.state.deliveryTaxas; 
        },

        valorTotal() {
            if (this.$store.state.deliveryTaxas && this.$store.state.deliveryTaxas.TaxaEntrega) {
                if (this.retirarNoLocal) {
                    return this.$store.state.deliveryPedido.valorTotalCarrinho
                }
                return this.$store.state.deliveryPedido.valorTotalCarrinho + this.$store.state.deliveryTaxas.TaxaEntrega
            }
            return this.$store.state.deliveryPedido.valorTotalCarrinho
        },

        podeEnviarPedido() {
            if (this.casaFechada) {
                return false;
            }
            if (this.tipoPagamento == null) {
                return false;
            } else {
                if (this.$store.state.deliveryRetirarNoLocal) {
                    return true
                } else {
                    if (this.$store.state.deliveryTaxas == null) {
                        return false 
                    }
                    return true
                }
            }
        },

        valoresEmReais() {
            return {
                pedido: converterParaDinheiroString(this.pedido.valorTotalCarrinho),
                entrega: converterParaDinheiroString(this.taxaDeEntrega.TaxaEntrega),
                total: converterParaDinheiroString(this.valorTotal),        
            }
        }
    },

    methods: { 

        

        buscarDados() {},

        verficiarSeACasaEstaAberta() {
            this.loading = true;
            ApiDelivery.verificarCasaEstaAbertaAgora(this.$store.state.cnpj, this.$store.state.numeroEmpresaFK, this.$store.state.idTerminal)
            .then((res) => {
                console.log(res)
                this.casaFechada = false;

            })
            .catch((err) => {
                console.log(err)
                this.casaFechada = true;
            })
            .finally(() => {
                this.loading = false;
            })
        },


        async enviarTelefone22(dados) {
            console.log("êeenviarndo telefone")
            this.loadingTelefone = true;
            let telefone = dados.telefone
            try {
                let res = await this.$store.dispatch("enviarTelefone", telefone)
                this.clienteNovoTelefone = telefone
                this.clienteExistente = true;
                this.voltouRespostaTelefone = true;
                this.todosEnderecos = res.dadosEnderecos
                this.dadosClienteApi = res.dadosPessoais 
            } catch (error) {
                console.log("deu aqui meu maninho")
                if (error == 501) {  
                    this.clienteNovo = true
                    this.clienteNovoTelefone = telefone
                }
                if (error == 2424) {
                    console.log("deu aqui erro 2424 meu mano segundo")
                    console.log(error, error)
                    this.clienteNovo = true
                    this.clienteNovoTelefone = telefone
                }
                console.log(error, error)


                /** vamos navegar para a pagina de cadastro de endereco */
                this.$router.push({
                    path: "/deliveryendereco",
                    query: { novo:true, telefone: this.telefone },
                })
            } finally {
                this.loadingTelefone = false;
                this.voltouRespostaTelefone = true;
            }
   
        },

        enviarTelefone(dados) {
            
            this.telefone = dados.telefone;
            if (this.telefone.length < 12 && this.telefone.length > 9) {
                if (!this.loadingTelefone) {
                    this.loadingTelefone = true;

                    ApiDelivery.buscarCliente(this.$store.state.cnpj, this.telefone)
                    
                        /** caso nao retorne erro, entao o cliente ja esta cadastrado. O retorno sera os dados do cliente */
                        .then(res => {
                            this.loadingTelefone = false;
                            this.voltouRespostaTelefone = true;
                            this.clienteExistente = true;
                            this.todosEnderecos = res.dadosEnderecos
                            this.dadosClienteApi = res.dadosPessoais 
    
                            /** setar endereco no STATE */
                            this.$store.dispatch("setDeliveryEndereco", res.dadosEnderecos[0])
                            
                            /** Agora vamos buscar a taxa de entrega e ver se entregamos neste bairo */
                            const state = this.$store.state
                            
                            ApiDelivery.buscarTaxaDeEntrega(state.cnpj, state.numeroEmpresaFK, res.dadosEnderecos[0].bairro)
                                .then(tax => {
                                    this.$store.dispatch("setDeliveryTaxas", tax)
                                }).catch(err2 => {
                                    console.log(err2);
                                    this.$store.dispatch("setDeliveryTaxas", "erro") 
                                })
                            
                        })

                        /** caso retorne erro 501 eh pq o cliente ainda nao foi cadastrado */
                        .catch(err => {
                            console.log("erro aqui meu mano");
                            console.log(err);
                            this.loadingTelefone = false; 
                            this.voltouRespostaTelefone = true;
                            this.clienteExistente = false;

                            /** vamos limpar o state caso ja tenha alguma coisa la */
                            this.$store.dispatch("setDeliveryTaxas", null) 
                            this.$store.dispatch("setDeliveryEndereco", null) 
                            
                            /** vamos navegar para a pagina de cadastro de endereco */
                            this.$router.push({
                                path: "/deliveryendereco",
                                query: { novo:true, telefone: this.telefone },
                            })
                        })
                }
            } else {
                Swal.fire({title: "Telefone inválido"})
            }
        },


        /** 
         * o retorno do elemento TipoPagamento
         * @param {Object} data - { pagamentoId: number, trocoPara: string | number } 
         * 
         * SE o param {data} for = "mp", entao o usuario escoheu pagar pelo app. Isso pode ser verificado no template
         * usado a var { this.tipoPagamento.pagamentoId == "mp" }
         */
        callbackTipoPagamento(data) {
            console.log(data)
            this.tipoPagamento = data
        },

        
        /** 
         * o retorno do elemento EnderecoController
         * @param {Object} data - {retirarNoLocal: this.retirarNoLocal} 
         */
        callbackEnderecoController(data) {
            this.retirarNoLocal = data.retirarNoLocal
            this.$store.dispatch("setDeliveryRetirarNoLocal", data.retirarNoLocal)
            this.tipoPagamento = null

            // essa logica serva para caso o usuario ja tenha selecionado o endereco de entrega, gere o pix do MP
            // e depois ele queira mudar o endereco, dai tem que regenerar o pix
            let holder = this.pagarPeloApp
            this.pagarPeloApp = null
            setTimeout(() => {
                this.pagarPeloApp = holder
            }, 1000);
        },


        validarDados() {
            // se for pagar pelo app, nao precisamos das validacoes que esta funcao faz
            if (this.pagarPeloApp ) {
                return true
            }
            if (this.tipoPagamento == null) {
                Swal.fire({title: "Por favor, selecione o tipo de pagamento"})
                return false
            } else if (this.tipoPagamento.pagamentoId == null) {
                Swal.fire({title: "Por favor, selecione o tipo de pagamento"})
                return false
            } else if (this.tipoPagamento.trocoPara != null  && this.tipoPagamento.trocoPara != "" && parseFloat(this.tipoPagamento.trocoPara) < this.valorTotal) {
                Swal.fire({title: "O valor do pagamento em dinheiro não pode ser inferior ao valor total do pedido."})
                return false
            } else {
                return true
            }
        },

        /**
         * Funcao chamada depois que o pagamento mp é finalizado.
         * @param {string} payment_method_id = pix | debit | credit
         */
        async onSubmitPagamentoMp(payment_method_id) {
            // vamos colocar um timeout aqui pra ter certeza que a rota de verificar pagamento pix
            // ja parou de ser chamada
            setTimeout(() => {
                if (this.tipoPagamento == null) {
                    this.tipoPagamento = {}
                }

                if (payment_method_id != null) {
                    if (payment_method_id.includes("bank")) { // pix vem como 'bank_transfer'
                        this.tipoPagamento.pagamentoId = "web pix"
                    }
                    if (payment_method_id.includes("debi")) {
                        this.tipoPagamento.pagamentoId = "web debito"
                    }
                    if (payment_method_id.includes("cred")) {
                        this.tipoPagamento.pagamentoId = "web credito"
                    }
                }
                this.enviarPedido();
            }, 2000);
        },

        montarPedidoFinal() {
            let pedido = this.$store.state.deliveryPedido
            pedido.tipo = "delivery"
            let pedidoFinal = {
                endereco: this.retirarNoLocal ? "retirar" : this.$store.state.deliveryEndereco,
                pagamento: this.tipoPagamento ??  "web pix",
                pedido: pedido,
                cnpj: this.$store.state.cnpj,
                empresaFK: this.$store.state.numeroEmpresaFK,
                cliente: {id: this.dadosClienteApi.id ,telefone: this.telefone, nome: this.dadosClienteApi.nome},
                taxaEntrega: this.retirarNoLocal ? 0.0 : this.taxaDeEntrega.TaxaEntrega,
                // valorTotalCarrinho: this.$store.state.deliveryPedido.valorTotalCarrinho,
                valorTotalCarrinho: this.pedido.valorTotalCarrinho,
                created_at: new Date(),
            }
            return pedidoFinal
        },

        /**
         * Esta funcao vai montar a estrutura de dados do endereco e entao
         * vai enviar o pedido usando a funcaio @function apiEnviarPedido
         * que retornara uma Promise.
         */
        async enviarPedido() {
            try {
                if (this.validarDados()) {
                    this.loading = true
                    this.habilitarBotaoEnviar = false
                    let deliveryconfig = await ApiDelivery.verificarCasaEstaAbertaAgora(this.$store.state.cnpj, this.$store.state.numeroEmpresaFK, this.$store.state.idTerminal);

                    if (deliveryconfig.aberto) { 

                        /** vamos montar o pedido */ 
                        let pedidoFinal = this.montarPedidoFinal()
                        console.log(pedidoFinal)
                        

                        ApiDelivery.enviarPedido(pedidoFinal).then(res => {
                            console.log("res pedido final")
                            console.log(res)
                            this.pedidoEnviado = true
                            this.loading = false
                            this.$store.commit("SET_LIMPAR_ITENS_CARRINHO")
                        }).catch(err => {
                            console.log(err)
                            this.loading = false
                            Swal.fire({
                                title: "Desculpe-nos!",
                                text: "Ocorreu um erro. Por favor, verifique sua conexão e tente novamente mais tarde."
                            })
                        })

                        
                    } else {
                        this.loading = false
                        let html = "O estabelecimento encontra-se <b>fechado</b> no momento!"
                        html += "<br><br>"
                        html += deliveryconfig.descricao
                        Swal.fire({
                            title: 'Desculpe-nos!',
                            html: html
                        })
                    }
                }
            } catch (error) {
                Swal.fire({
                    title: 'Desculpe-nos!',
                    html: error
                })
                this.$router.back();
            }
        },
        

        voltar() {
            this.$router.back();
        },
    },

    // eslint-disable-next-line vue/no-unused-components
    components: { MenuTopTemplate,  TelefoneComDDD, DadosEndereco, ControleEnderecos, TipoPagamento, DeliverySucessoView, MpPagamento, MpStatusScreenPix }
}
</script>

<style scoped>

.delivery-view-corpo {
    color: white;
    /* background: blue; */
}

p {margin: 10px}
.card {
    color: black; 
    margin-block: 15px;
    margin-inline: 15px;
    border: white solid 2px;
    padding: 20px;
    border-radius: 15px;
    background: linear-gradient(to top left, white, rgb(220, 220, 220));
    /* max-width: 80%; */ 
    animation: card-anim 0.5s forwards;
}

@keyframes card-anim {
    from { opacity: 0; transform: translateX(-30%) }
    to { opacity: 1; transform: translateX(0%)}
}

.resumo-pedido span {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.img-loading-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.img-loading {
    height: 7vh;
}

.input-btn {
    display: flex;
}
.input-btn input {
    width: 80%;
    margin-right: 10px;
}

.valor-pedido,
.valor-taxa,
.valor-tempo-entrega,
.valor-total { font-weight: bold; font-size: 1.1rem ;}

.valor-pedido { color: var(--valor-azul); }
.valor-taxa { color: var(--valor-amarelo); }
.valor-total { color: var(--valor-verde); }
.valor-tempo-entrega { color: var(--valor-verde); }

</style>