<template>
    <div class="backgrounds">
        <section class="top-bar">
            <TopBar :imagem="item.imagem" :titulo="item.nome" :descricao="item.descricao"/>
        </section>

        <EtapasCounter :indexCorrente="etapaIndex" :quantidadeTotal="3" />

        <!-- massa e borda -->
        <div :class="etapaClassName">

            <!-- sabores  -->
            <cada-etapa 
                v-if="etapaIndex == 0"
                :indexEtapaCorrente="etapaIndex"
                :totalEtapas="item.etapas.length"
                :isPizza="true"
                :etapa="item.etapas.pizza_sabores"
                @onSelectedItems="onSelectedSabores"
            />

            <!-- borda  -->
            <borda-e-massa
                v-if="etapaIndex == 1"
                :indexEtapaCorrente="etapaIndex"
                :totalEtapas="item.etapas.length"
                :etapa="item.etapas.pizza_bordas"
                :isBorda="true"
                @onSelectedBorda="onSelectedBorda"
            />

            <!-- massa  -->
            <borda-e-massa
                v-if="etapaIndex == 2"
                :indexEtapaCorrente="etapaIndex"
                :totalEtapas="item.etapas.length"
                :etapa="item.etapas.pizza_massas"
                :isBorda="false"
                @onSelectedMassa="onSelectedMassa"
            />


        </div>
        <!-- <div :class="etapaClassName">
            <cada-etapa 
                :indexEtapaCorrente="etapaIndex"
                :totalEtapas="item.etapas.length"
                :etapa="item.etapas[etapaIndex]"
                @onSelectedItems="onSeletectedItemsNaEtapa"
            />
        </div> -->

    </div>
</template>

<script> 

import EtapasCounter from '@/components/Base/EtapasCounter.vue';
import TopBar from '../TopBar.vue';
import BordaEMassa from './pizzas/BordaEMassa.vue';
import CadaEtapa from './CadaEtapa.vue';

/**
 * Quando o item é do tipo mercadoria. Depois de adicionar o item, retora para a pagina anterior
 * @vue-prop {Object} item é o item especifico escolhido
 * @vue-data {Number} [quantidade = 1] quantidade de mercadorias, sendo que inicia com 1
 * @vue-data {Number} [precoTotal=this.item.preco] é preco da mercadoria
 * @vue-computed {Boolean} [esconderPrecoUnitario] manda como prop para o componente BottomBar se ele deve esconder o preco unitario ou nao. Ele sempre deve
 * esconder caso a quantidade de itens selecionados eja igual a 1, valido apenas para mercadorias; 
 */
export default { 
  
    nome: "TipoComboPizza",

    props: {
        item: Object,
    },
    
    data() {
        return { 
            etapaIndex: 0, // 0 = borda, 1 = massa, 2 = sabores
            etapaClassName: "etapa",
            bordaEscolhida: {},
            massaEscolhida: {},
            saboresEscolhidos: [],
        }
    }, 

    computed: {
      temBordasParaSelecionar() {
        return this.item.etapas.pizza_bordas.length > 0
      },
      temMassasParaSelecionar() {
        return this.item.etapas.pizza_massas.length > 0
      },
    },

    created() {
        // this.scrollToTop();
        this.consolar()
    },

    updated() {
    },

    watch: {
        etapaIndex() {

        }
    },

    methods: {

        consolar() {
        },



        irProximaEtapa() {
            if (this.temBordasParaSelecionar && this.etapaIndex != 1) {
                this.etapaIndex = 1
            } else if (this.temMassasParaSelecionar && this.etapaIndex != 2) {
                this.etapaIndex = 2
            } else {
                this.enviarCarrinho()
            }
        },

        
        onSelectedSabores(items) {
            this.saboresEscolhidos = items


            // animar e mudar o ined da etapa
            this.etapaClassName = "etapa-sair"
            setTimeout(() => {
                this.etapaClassName = "etapa"
                this.irProximaEtapa()
            }, 500);
            
        },

        onSelectedBorda(item) {
            this.bordaEscolhida = item;
            
            // animar e mudar o ined da etapa
            this.etapaClassName = "etapa-sair"
            setTimeout(() => {
                this.etapaClassName = "etapa"
                this.irProximaEtapa()
            }, 500);
        },

        onSelectedMassa(item) {
            this.massaEscolhida = item;
            this.enviarCarrinho()
        },


        /**
         * Esse preco depende se o cliente setou no allfood para cobrar pelo sabor
         * mais caro ou cobrar por cada sabor.
         */
        precoSoPizza() {
            let fracao = 1 / this.saboresEscolhidos.length
            let precoFinal = 0.0

            // caso o cliente escolha cobrar pelo valor mais caro
            if (this.$store.state.pizzaCobrarMaior) {
                let escolhidos = [...this.saboresEscolhidos]
                escolhidos.sort((a,b ) => b.preco - a.preco)
                return escolhidos[0].preco
            } 
            
            // caso contrario
            else {
                for (var i = 0; i < this.saboresEscolhidos.length; i++) {
                    const item = this.saboresEscolhidos[i];
                    precoFinal += item.preco * fracao
                }
            }
            return precoFinal
        },

        precoTodasAsVariacoesJuntas() {
            let variacoes = 0.0
            for (var i = 0; i < this.saboresEscolhidos.length; i++) {
                const item = this.saboresEscolhidos[i];
                variacoes += (item.precoApenasVariacoes ?? 0.0)
            }
            return variacoes;
        },


        /**
         * Vamos calcular o valor total, somando o valor base da pizza mais o valor da borda mais o valor da massa
         * e tambem vamos um por um (pizza, borda, massa) checkando se eles nao tem promocao.
         */
        calcularPrecoParaEnviar() {
            let valorPizza  = this.precoSoPizza() + this.precoTodasAsVariacoesJuntas()
            let valorBorda  = this.bordaEscolhida.precoTotal ?? 0.0
            let valorMassa  = this.massaEscolhida.precoTotal ?? 0.0
            
            if (this.bordaEscolhida.valorPromocao != null && this.bordaEscolhida.valorPromocao != 0) {
                valorBorda  = this.bordaEscolhida.valorPromocao
            }
            
            if (this.massaEscolhida.valorPromocao != null && this.massaEscolhida.valorPromocao != 0) {
                valorMassa  = this.massaEscolhida.valorPromocao
            }
            
            return parseFloat(valorPizza + valorBorda + valorMassa)
        },



        /**
         * é o metodo que envia a mercadoria para o carrinho envia em fomato de objeto o id, o nome, o preco unitario, a quantidade escolhida
         * e o preco total final do item. Depois de adicionar o item, retora para a pagina anterior
         */
        enviarCarrinho() {
            let itemFinalizado = {
                tipo: "comboPizza",
                id: this.item.id,
                nome: this.item.nome,
                preco: this.calcularPrecoParaEnviar(),
                valorUnitarioPagoNoSmartPos: this.calcularPrecoParaEnviar(),
                quantidade: 1,
                precoTotal: this.calcularPrecoParaEnviar(),
                precoPizza: this.precoSoPizza(),
                subitens: {
                    borda: this.bordaEscolhida, 
                    massa: this.massaEscolhida, 
                    sabores: this.saboresEscolhidos, 
                }
            }
            this.$store.dispatch('setItensCarrinho', itemFinalizado)
            this.$router.back()
        }

    },
    components: {
    TopBar,
    EtapasCounter,
        BordaEMassa,
        CadaEtapa
}

}
</script>

<style scoped>


.etapa {
    animation: mudar-etapa 0.5s forwards;
}
@keyframes mudar-etapa {
    from { transform: translateX(50%); opacity: 0;}
    to { transform: translateX(0%); opacity: 1;}
}

.etapa-sair {
    animation: mudar-etapa-sair 0.5s forwards;
}
@keyframes mudar-etapa-sair {
    from { transform: translateX(0%); opacity: 1;}
    to { transform: translateX(-50%); opacity: 0;}
}


.backgrounds {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.top-bar {
    margin: 0;
}



</style>