<template>
    <div class="background">

        <button class="btn-fechar-top" type="button" @click="voltar">
            X
        </button>

        <TipoMercadoria 
            v-if="this.item.tipo == 'mercadoria'"
            :item="this.item"
        />

        <TipoProduto 
            v-if="this.item.tipo == 'produto'"
            :item="this.item"
        />

        <TipoComboNormal 
            v-if="this.item.tipo == 'comboNormal'"
            :item="this.item"
        />

        <TipoComboPorTamanho 
            v-if="this.item.tipo == 'comboPorTamanho'"
            :item="this.item"
        />

        <TipoComboPorEtapa 
            v-if="this.item.tipo == 'comboPorEtapa'"
            :item="this.item"
        />

        <TipoComboPizza 
            v-if="this.item.tipo == 'comboPizza'"
            :item="this.item"
        />

    </div>
</template>

<script>
import TipoMercadoria from '../components/ListaItensVenda/Tipos/TipoMercadoria.vue'
import TipoProduto from '../components/ListaItensVenda/Tipos/TipoProduto.vue'
import { setarCores } from '@/utils/SetarCores'
import TipoComboNormal from '../components/ListaItensVenda/Tipos/TipoComboNormal.vue'
import TipoComboPorTamanho from '@/components/ListaItensVenda/Tipos/TipoComboPorTamanho.vue'
import TipoComboPorEtapa from '@/components/ListaItensVenda/Tipos/TipoComboPorEtapa.vue'
import TipoComboPizza from '@/components/ListaItensVenda/Tipos/TipoComboPizza.vue'

/**
 * @param {object} item vem do store
 */
export default {
    name: "CadaItemView",
    props: {
    },

    data() {
       return {
         item: this.$store.state.itemSelecionado
       }
    },

    created() {
        setarCores(this.$store.state.cor1, this.$store.state.cor2)
    },
    
    methods: {

        /**
         * limpar os itens temporarios e dai voltar para o cardapio
         */
        voltar() {
            this.$store.dispatch('setLimparItemTemporario')
            this.$router.back()
        }
    },

    components: {
    TipoMercadoria,
    TipoProduto,
    TipoComboNormal,
    TipoComboPorTamanho,
    TipoComboPorEtapa,
    TipoComboPizza,
}

}
</script>

<style scoped>
.backround {
    background: red;
}
h1 {
    color: black;
}
p {
    color: black
}


</style>