import Swal from "sweetalert2"
import { CONSOLOGAR } from "./debug"

export const controlePedirNumeroCartao = () => {
    return new Promise((resolve, reject) => {
        Swal.fire({
            title: 'Digite o número da sua comanda',
            html: `<input type="text" id="comanda" style="border-radius: 12px; width: 90%; background: rgb(220,220,220); text-align: center; font-weight: bold; font-size: 20px; padding-block: 15px" placeholder="comanda">`,
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Cancelar',
            focusConfirm: false,
        preConfirm: () => {
            const comanda = Swal.getPopup().querySelector('#comanda').value
            if (!comanda) {
                Swal.showValidationMessage(`Por favor, entre com o número da sua comanda`)
            }
            return { comanda: comanda }
        }
        }).then((result) => {
        
            /** o resultado vem assim: ${result.value.comanda} */
            CONSOLOGAR([result.value])
            resolve(result.value.comanda)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const controleConfirmarNumeroCartao = (numero) => {
    return new Promise((resolve, reject) => {
        Swal.fire({
            title: `Confirme o número de sua comanda`,
            html: `<h2 style="color: black;">${numero}</h2>`,
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Cancelar',
            focusConfirm: false,
        preConfirm: () => {}}).then((result) => {
            if (result.isConfirmed) {
                resolve(true)
            } else {
                resolve(false)
            }
        }).catch((err) => {
            console.log(err)
            reject(false)
        })
    })
}