<template>
        <div class="titulo-classe-descricao">
            <h3>{{ titulo }}</h3>
            <p v-if="subtitulo != '' && subtitulo != null">{{ subtitulo }}</p>
            <hr>
        </div>
</template>

<script>
export default {
    name: "TituloDePaginaView",
    props: {
        titulo: String,
        subtitulo: String
    }
}
</script> 

<style scoped>
.titulo-classe-descricao {
    padding-inline: 20px;
    padding-block: 15px;
}

.titulo-classe-descricao hr {
    margin: 0;
}
.titulo-classe-descricao p {
    margin-top: 10px;
    font-size: 13px;
}

</style> 