<template>
    <div class="corpo">

        
        
        <img class="img-back" :src="this.$store.state.logo" alt="">
        
        <div style="z-index: 2;">
            <img class="img-front" :src="this.$store.state.logo" alt="">
        
            <h2 style="animation-delay: 0.1s;" class="anim-subir anim-titulo">Pedido enviado!</h2>
            
            <p  style="animation-delay: 0.6s;" class="texto anim-subir">Agora basta aguardar até que seu pedido esteja pronto.</p>
            <br>
            <br>
            <p  style="animation-delay: 1.1s;" class="texto anim-subir">Você pode checar o status do seu pedido clicado em <b>Ver pedido</b> abaixo</p>
            <br>
            
            <button @click="verPedido"  style="animation-delay: 2s;" class="anim-subir btn-normal">Ver pedido</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "DeliverySucessoView",
    methods: {
        verPedido() {
            this.$router.push({path: "/statusdelivery"})
        }
    }
}
</script>

<style scoped>


.corpo {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    max-width: 100vw;
    padding-inline: 15vw;
    margin: 0;
    background: linear-gradient(to bottom, var(--cor1-muito-escura), black, var(--cor1-muito-escura));
    /* background: linear-gradient(to bottom, var(--cor1-escura), black, var(--cor1-escura)); */
}

.img-back { width: 300vw; position: absolute; filter: saturate(12%) blur(5px); opacity: 0.15; z-index: 1;}
.img-front { width: 35vw; }

.texto {
    font-weight: 100;
}

.anim-subir {
    opacity: 0;
    transform: translateY(20%);
    animation: anim-subir-anim 1s forwards;
}

@keyframes anim-subir-anim {
    from    {opacity: 0; transform: translateY(20%);}
    to      {opacity: 1; transform: translateY(0%);}
}


</style>