<template>
    <div class="backgrounds">


<!-- topbar ----------------------- -->
        <section class="top-bar"><TopBar :imagem="item.imagem" :titulo="item.nome" :descricao="item.descricao"/></section>

<!-- os itens que este combo tem    -->
        <div id="lista-itens-do-combo">
            <p id="texto-itens-do-combo">Itens do combo:</p>
            <div  v-for="subitem in item.subitens" :key="subitem.codigo">
                <!-- <p style="background: green; color: white; padding: 15px " >{{ JSON.stringify(subitem) }}</p> -->
                <div style="color: black; background: white; display: flex;  text-align: start; padding-block: 8px; padding-inline: 15px">
                    <p style="width: 15%; text-align: start">{{ subitem.quantidade }} x </p>
                    <p style="flex-grow: 1; text-align: start">{{ subitem.descricao }}</p>
                </div>      
            </div>
        </div>

<!-- cada subitem ---------- -->
        <div  v-for="(subitem, index) in item.subitens" :key="index">  

            <produto-controller 
                :item="subitem"
                @onClickConfirmarEsteItem="retornarProduto"
            />
            
        </div>

<!-- bottom bar com preco --------- -->
        <BottomBar
            :quantidade="this.quantidade"
            :temPrecoUnit="true" 
            :temPrecoTotal="true" 
            :precoUnit="precoUnitarioCalculado" 
            :precoTotal="this.precoTotal"
            :precoVariacao="precoDasVariacoes" 
            :promocao="promocao"
            :clicarSelecionar="enviarCarrinho"
            :clicarSelecionarNaoPodeAvancar="() => {}"
            :esconderPrecoUnitario="esconderPrecoUnitario"
            :podeAvancar="podeAvancar"/>

    </div>   
</template>

<script>

import BottomBar  from "../BottomBar.vue";  
import TopBar from '../TopBar.vue';
import { CONSOLOGAR } from '@/components/Controle/debug';
import ProdutoController from './controle/ProdutoController.vue';

/**
 * Quando o item é do tipo mercadoria. Depois de adicionar o item, retora para a pagina anterior
 * @vue-prop {Object} item é o item especifico escolhido
 * @vue-data {Number} [quantidade = 1] quantidade de mercadorias, sendo que inicia com 1
 * @vue-data {Number} [precoTotal=this.item.preco] é preco da mercadoria
 * @vue-computed {Boolean} [esconderPrecoUnitario] manda como prop para o componente BottomBar se ele deve esconder o preco unitario ou nao. Ele sempre deve
 * esconder caso a quantidade de itens selecionados eja igual a 1, valido apenas para mercadorias; 
 */
export default {
  
    nome: "TipoComboNormal",

    props: {
        item: Object,
    },
    
    computed: {

        podeAvancar() {
            let quantidadeTotal = this.subitensEnviar.length
            let quantidadePodeAvancar = 0

            for (var i = 0; i < this.subitensEnviar.length; i++) {
                if (this.subitensEnviar[i].podeAvancar  == true) {
                    quantidadePodeAvancar++
                } 
            }

            return quantidadeTotal == quantidadePodeAvancar
        },

        /**
         * Vai calcular apenas os precos das variacoes e cada subitem somando tudo
         * @returns {Number}
         */
         precoDasVariacoes() {
            let preco = 0.0
            for (var i = 0; i < this.subitensEnviar.length; i++) {
                const item = this.subitensEnviar[i]
                preco += item.precoApenasVariacoes
            }
            return preco
        },
        
        esconderPrecoUnitario() {
            if (this.quantidade == 1) {
                return true
            }
            return false
        },
        
        precoUnitarioCalculado() {
            if (this.item.promocao == 0 || this.item.promocao == null) {
                return this.item.preco;
            } else {
                return this.item.promocao;
            }
        },
        
    },
    
    data() {
        return {
            quantidade: 1,
            precoTotal: this.item.preco,
            promocao: this.item.promocao,
            todasPerguntasForcadasRespondidas: false,
            todasVariacoesObrigatoriasRespondidas: false,
            perguntasForcadas: [],
            todosSubitens: [],
            subitensEnviar: [],
            soVariacoes: 0.0
        }
    },

    created() {
        this.criarVariaviesIniciais();
        this.separarSubitens();
        this.scrollToTop();

        for (let i = 0; i < this.item.subitens.length; i++) {
            CONSOLOGAR(["subitem", JSON.stringify(this.item.subitens[i])]);
        }
    },

    methods: {

         /** rolar pra cima quando inicia a pagina */
         scrollToTop() {
            window.scrollTo(0,0);
        },


        separarSubitens() {
            let todosSubitens = []
            for (var i = 0; i < this.item.subitens.length; i++) {
                let elem = this.item.subitens[i]
                for (var x = 0; x < elem.quantidade; x++) {
                    todosSubitens.push(elem)
                }
            }
            this.todosSubitens = todosSubitens
        },

        calcularQuantidadeDeCadaSubitem() {
            console.log(this.item)
        },

        /** 
         * Traz o produto do constrole produto e adiciona na lista para enviar
         * para o carrinho. 
         * @param {Object} prod produto vindo do ControleProduto
         */
        retornarProduto(prod) {
            this.calcularQuantidadeDeCadaSubitem()
            let incluso = this.subitensEnviar.findIndex(p => p.codigo == prod.codigo)
            if (incluso == -1) {
                this.subitensEnviar.push(prod)
            } else {
                this.subitensEnviar = this.subitensEnviar.filter(p => p.codigo != prod.codigo)
                this.subitensEnviar.push(prod)
            }
        },
        


        /**
         * o subitem vem com as perguntas forcadas numa chave [perguntaforcada]. Precisamos arrumar isso.
         * Vamos criar uma chave [perguntasForcadasIniciais] pra cada subitem e colocar essas perguntas la
         * 
         * tambem vamos criar uma chave [perguntasForcadas] que por enquanto estara vazia, vai receber
         * as perguntas ja com a resposta. Isso é feito deste jeito aqui para podermos manter as mesmas
         * nomenclaturas de pergunta forcada dos outros tipos de item venda
         * 
         * tambem vamos criar as [perguntasForcadasIniciais] e as [variacoesRespondidas]
         */ 
        criarVariaviesIniciais() {

            for (var i = 0; i < this.todosSubitens.length; i++) {
                const elem = this.todosSubitens[i]
                
                // perguntas forcadas
                elem.perguntasForcadasIniciais = elem.perguntaforcada
                elem.perguntasForcadas = []

                //variacoes iniciais
                elem.variacoesRespondidas = []
                elem.adicionar = []

                // FICHA TEC
                elem.fichaTecFinal = elem.fichatecnica 
                elem.retirar = []
                
            }
        },


        /** calcula o preco vendo se tem promocao ou nao */
        calcularPrecoParaEnviar() {
            return this.precoTotal + this.precoDasVariacoes
        },

        /**
         * é o metodo que envia a mercadoria para o carrinho envia em fomato de objeto o id, o nome, o preco unitario, a quantidade escolhida
         * e o preco total final do item. Depois de adicionar o item, retora para a pagina anterior
         */
        enviarCarrinho() {

            let itemFinalizado = {
                tipo: "comboVendaConjunta",
                id: this.item.id,
                nome: this.item.nome,
                preco: this.calcularPrecoParaEnviar(),
                valorUnitarioPagoNoSmartPos: this.calcularPrecoParaEnviar(),
                quantidade: this.quantidade,
                precoTotal: this.precoTotal,
                perguntasForcadas: this.perguntasForcadas,
                subitens: this.subitensEnviar
            }
            this.$store.dispatch('setItensCarrinho', itemFinalizado)
            this.$router.back()
        }

    },
    components: {
        BottomBar,
        TopBar,
        ProdutoController,
    }


}
</script>

<style scoped>

.backgrounds {
    display: flex;
    flex-direction: column;
    background: var(--gradiente-background)
}

.top-bar {
    margin: 0;
}

.bodys{
    flex-grow: 1;
}


#quantidadeTitulo {
    margin: 0;
    padding: 10px;
    font-size: 14px;
    color: white;
}

#texto-itens-do-combo {
    background: white;
    font-size: 0.9rem;
    color: #999;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-inline: 10px;
    text-align: center;
    /* line-height: .5rem; */
}

#lista-itens-do-combo {
    background: white;
    padding-bottom: 15px;
}

#mercadoriaImg {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

#nomeDaMercadoria {
    width: 100%;
    background: black;
    color: white;
    font-size: 18px;
    padding: 10px;
    margin: 0;
}

.btnMaisOuMenos {
    border-radius: 5px;
    background: var(--primaryDark_azul);
    color: white;
    font-weight: 800;
    font-size: 25px;
    padding: 3px;
    width: 40px;
}

#quantidadeEscolhida {
    font-weight: 800;
    margin: 10px;
    font-size: 25px;
    color: white;
}

#card-pergunta-forcada-venda-conjunta {
    margin-top: 30px;
}

#card-pergunta-forcada-venda-conjunta > p {
    color: white;
    margin: 0px;
    font-weight: lighter;
}
#card-pergunta-forcada-venda-conjunta > h1 {
    margin-top: 0px;
    font-size: 1.4rem;
    padding-inline: 15px;
}



</style>