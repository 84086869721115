<template>
    <section class="background-gradiente">
        <menu-top-template :titulo="'Dados da conta'" :onPressedClose="() => { this.$router.go(-1); }"/>
        <div class="card-pagina">
            
            <button class="btn-fechar-top" type="button" @click="voltar">X</button>

            <p class="titulo">CONTA</p>
            <hr>

            <div style="display: flex">

                <div v-if="!porCartao" class="coluna">
                    <p>MESA</p>
                    <img  v-if="loading" src="../assets/loading.gif" alt="" style="height: 30px" />
                    <h5  v-if="!loading">{{this.mesa}}</h5>
                </div>
                
                <div v-if="porCartao" class="coluna">
                    <p>COMANDA</p>
                    <img  v-if="loading" src="../assets/loading.gif" alt="" style="height: 30px" />
                    <h5  v-if="!loading">{{this.numeroCartao}}</h5>
                </div>

                <div class="coluna">
                    <p>VALOR A PAGAR</p>
                    <img  v-if="loading" src="../assets/loading.gif" alt="" style="height: 30px" />
                    <h5 v-if="!loading" style='color: green'>R$ {{valorArrumado}}</h5>
                </div>
            
            </div>
            
        </div>
    </section>
</template>

<script>
import { setarCores } from '@/utils/SetarCores'

import {apiSincronizacao} from "../api/ApiSincronizacao"
import { controlePedirNumeroCartao } from '@/components/Controle/pedirNumeroCartao'
import { apiBuscarStatusMesa } from '@/api/ApiPegarMesa'
import { CONSOLOGAR } from '@/components/Controle/debug'
import MenuTopTemplate from '@/components/Base/MenuTopTemplate.vue'
// import { CONSOLOGAR } from '@/components/Controle/debug'
export default {
    components: { MenuTopTemplate },
    name: "ContaView",
    data() {
        return {
            loading: false,
            dadosConta: {},
            mesa:"",
            valor:"",
            satus:"",
            numeroCartao: null,
            porCartao: this.$store.state.porCartao
        }
    },

    created() {
        if (this.porCartao) {
            this.pedirNumeroCartao()
        } else {
            this.refreshStatus(null)
        } 
        setarCores(this.$store.state.cor1, this.$store.state.cor2)
    },
    computed: {
        valorArrumado() {
            if (this.valor == null) {
                return "0,00"

            } else if (this.valor == 0) {
                return "0,00"  
            } else {
                return this.valor.toFixed(2).replace(".", ",")
            }
        }
    },

    methods: {

        test() {
            // let dadosUrl = {cnpj: this.$store.state.cnpj, idTerminal: this.$store.state.idTerminal};
            apiSincronizacao( this.$store.state.cnpj, this.$store.state.idTerminal);
        },

        pedirNumeroCartao() {
            controlePedirNumeroCartao().then((resolve) => {
                this.numeroCartao = resolve
                this.refreshStatus(resolve)
            }).catch((rej) => CONSOLOGAR([rej]))
        },

        /**
         * o numero pode ser nulo, indicando que estamos usando MESA
         * ou um integer que indica que estamos usando CARTAO
         * 
         * essa funcao vai tratar isso
         * @param {integer} numero 
         */
        refreshStatus(numero) {
            this.loading = true;
            apiBuscarStatusMesa(this.$store.state.cnpj, this.$store.state.idTerminal, this.$store.state.numeroEmpresaFK, numero).then((res) => {
                this.$store.dispatch("setStatusEConta", {statusMesa: res.status, totalContaMesa: res.valor_total});
                this.mesa = res.numero_mesa;
                this.satus = res.status;
                this.valor = res.valor_total;
                this.loading = false;
            });
        },

        voltar() {
            this.$router.back();
        },
    }
}
</script>

<style scoped>

.coluna {display: block; width: 50%;}

.coluna p {font-size: 12px; color: black;}

.coluna h5 {font-size: 18px; color: black;}

</style>