<template>
    <div style="position: relative; height: 350px; width: 100%;">
        <div id="barcode-reader" style="position: absolute; top: 0; bottom: 0; right: 0; left: 0; background-color: rgb(25, 25, 25); margin: 0; clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%);">

        </div>

    </div>
</template>

<script>
export default {
    name: "BarcodeQuaggaComponent",
    data() {
        return {
            qua: null,
        }
    },
    created() {
        this.conso()
    },
    beforeDestroy() {
        console.log("BarcodeQuaggaComponent being destroyed");
        this.qua.stop()
        this.qua.offDetected()
    },
    watch: {
        codigo() {
            console.log("codigo foi alterado")
        }
    },
    methods: {
        conso() {
           setTimeout(() => {
            // const qua = window.Quagga
            this.qua = window.Quagga

            // Initialize the barcode reader
            this.qua.init({
                inputStream: {
                    name: "Live",
                    type: "LiveStream",
                    target: document.querySelector('#barcode-reader'), // Container element
                    constraints: {
                        width: window.innerWidth ,
                        height: 800,
                        facingMode: "environment" // Use back camera if available
                    }
                },
                decoder: {
                    // readers: ["code_128_reader", "ean_reader", "ean_8_reader", "code_39_reader", "upc_reader", "upc_e_reader"] // Types of barcodes to read
                    readers: [ "code_39_reader", "code_128_reader"] // Types of barcodes to read
                }
            }, (err) => {
                if (err) {
                    console.error(err);
                    return;
                }
                console.log("Quagga initialization succeeded");
                this.qua.start(); // Start scanning
            });

            // Callback after barcode is detected
            this.qua.onDetected((data) => {
                console.log("Barcode detected: ", data.codeResult.code);
                // alert("Barcode detected: " + data.codeResult.code);
                localStorage.setItem("barcode", data.codeResult.code)
                this.iDidIt(data.codeResult.code)
                // this.$emit("onDetected", data.codeResult.code)
                this.qua.stop()
                this.qua.offDetected()
            });
           }, 800)

        },


        iDidIt(code) {
            console.log("%cI did it :: " + code, "color: yellow")
            this.$emit("onDetected",code)
        }
    },
}
</script>

<style scoped>

</style>