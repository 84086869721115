<template>
  <div class="bottom-bar">
            <hr>
            <div v-if="this.temPrecoUnit && esconderPrecoUnitario == false" class="precos">
                <span>Preço unitário:</span>
                <p>R$ {{ precoUnitarioCalculado }}</p> 
            </div>

            <!-- preco sem variacao  -->
            <div v-if="precoVariacao == null || precoVariacao == 0.0" :class="[this.animar ? 'precos precos-anim' : 'precos']" :key="this.precoTotalCalculado" >
                <span>Preço total:</span >
                <p v-if="precoTotal != null">R$ {{ precoTotalCalculado }}</p>
                <p v-if="precoTotal == null" style="font-size: 0.9rem; color: #aaa;">Selecione um item</p>
            </div>

            <!-- preco com variacao  -->
            <div v-if="precoVariacao != null && precoVariacao > 0.0" :class="[this.animar ? 'precos precos-anim' : 'precos']" :key="this.precoTotalCalculado" >
                <span>Preço total:</span >
                <div style="display:flex; justify-content: center">
                    <p style="margin-right:10px; color: rgb(00, 250, 0)">R$ {{ precoTotalCalculado }}</p> <p style="fontSize: 20px; color: rgb(250, 250, 0)">R$ {{ precoTotalComVariacao }}</p>
                    
                </div>
            </div>

            <button v-if="podeIr" @click="this.clicarSelecionar" class="btn-verde"> <img src="../../assets/icons/ic-check.png" alt=""> <p>CONFIRMAR</p> </button>
            <button v-if="!podeIr" @click="this.clicarSelecionarNaoPodeAvancar" class="btn-desabilitado"> <img src="../../assets/icons/ic-check.png" alt=""> <p>CONFIRMAR</p> </button>
        </div>
</template>

<script>

/**
 * A barra que fica na parte de baixo mostrando o preo uniatio eo preco total, nas paginas de esolher quantidade de um item.
 * 
 * @vue-prop {Boolean} temPrecoUnit indica se tem preco unitario neste item
 * @vue-prop {String} precoUnit preco unitario
 * @vue-prop {Boolean} temPrecoTotal indica se tem preco total
 * @vue-prop {String} precoTotal preco total
 * @vue-prop {String} precoTotalComVariacao preco total depois de adicionadas variacoes
 * @vue-prop {Function} clicarSelecionar botao selecionar, adicionar o item ao carrinho
 * @vue-prop {esconderPrecoUnitario} se a quantidade selecionada for 1, entao esconder a linha "preco unitario"
 * @vue-data {Boolean} animar serve para saber quando animar este componente
 */
export default {
    name: "BottomBar",
    
    props: {
        quantidade: Number,
        temPrecoUnit: Boolean,
        precoUnit: Number,
        promocao: Number,
        temPrecoTotal: Boolean,
        precoTotal: Number,
        precoVariacao: Number,
        clicarSelecionar: Function,
        clicarSelecionarNaoPodeAvancar: Function,
        podeAvancar: Boolean,
        esconderPrecoUnitario: Boolean
    },
    
    computed: {

        podeIr() {
            if (this.podeAvancar == null || this.podeAvancar == undefined || this.podeAvancar == 'undefined') {
                return true;
            } 
            return this.podeAvancar;
        },

        precoUnitarioCalculado() {
            if (this.precoUnit != null) {
                if (this.precoUnit.toString().includes(",")) {
                    return this.precoUnit
                } else {
                    return this.precoUnit.toFixed(2).replace(".", ",")
                }
            }
            return ""
        },

        precoTotalCalculado() {
            if (this.promocao == 0 || this.promocao == null) {
                return (parseFloat(this.precoTotal) * parseInt(this.quantidade)).toFixed(2).replace(".", ",");
            } else {
                return (parseFloat(this.promocao) * parseInt(this.quantidade)).toFixed(2).replace(".", ",");
            }
        },

        precoTotalComVariacao() {
            if (this.promocao == 0 || this.promocao == null) {
                return ((this.precoVariacao + this.precoTotal) * this.quantidade).toFixed(2).replace(".", ","); 
            } else {
                return ((this.precoVariacao + this.promocao) * this.quantidade).toFixed(2).replace(".", ",");
            }
        }
    },
    
    data() {
        return {
            animar: false,
        }
    },

    watch: {
        precoTotal() {
            this.animar = true;
            setTimeout(()=>{
                this.animar = false
            }, 900)
        }
    },

}
</script>

<style scoped>

.bottom-bar {padding: 10px;}

.bottom-bar button {
    margin-top: 10px;
    margin-left: auto;
    margin-right: 0;
}

.precos {display: flex; justify-content: space-between; color: white; padding-block: 5px;}



.precos > p {font-size: 20px;}

.precos-anim {opacity: 0.0;animation: anims 0.8s ease 0.0s  forwards;}

.btn-desabilitado {
    background: rgba(150, 150, 150, 1.0);
}

@keyframes anims {to {opacity: 1.0;}}

.btn-verde, .btn-desabilitado {
    width: 100%;
    height: 60px;
    justify-content: center;
}

</style>