<template>
    <div :class="taxas == 'erro' ? 'card-outline-error' : 'card-outline'" style="height: 100%;">

            
       
            
        <div>
            <div style="width: 100%;">
                <p>{{ this.endereco.rua }}, {{ this.endereco.numero }}</p>
                <p><b>{{ this.endereco.bairro }}</b></p>
                <p>{{ this.endereco.cidade }}, {{ this.endereco.uf }}</p>
                <br>

                <!-- exibir caso nao entreguem neste bairro -->
                <div v-if="taxas == 'erro'">
                    <p style="background: red; color: white; padding: 15px; margin: -15px; margin-bottom: 5px; margin-top: 1px;">Sem entrega para este bairro</p>
                </div>

                <!-- exibir apenas caso entreguem neste bairro --> 
                <div v-if="taxas != 'erro'">
                    <p>Taxa de entrega: <b>{{ trocarPorVirgula(this.taxas.TaxaEntrega) }}</b></p>
                    <p>Tempo estimado: <b>{{ this.taxas.TempoMedioEntrega  }} min</b></p>
                </div>

                <div  class="btn-editar" @click="onClickEditar">
                    <img src="../../assets/icons/editar.png" alt="" />
                    <p>editar</p>
                </div>

            </div>
           
        </div>

    </div>
    
</template>

<script>
import { converterParaDinheiroString } from '@/utils/Dinheiro'
// import ApiDelivery from '@/api/ApisDelivery'
// import EnderecoModel from '@/model/enderecoModel'
export default {
    name: "CardEndereco",
    props: {
        // endereco: EnderecoModel,
    },
    data() {
        return {
            // endereco: this.$store.state.deliveryEndereco,
            // taxas: this.$store.state.deliveryTaxas,
        }
    },
    computed: {
        endereco() {
            return  this.$store.state.deliveryEndereco
        },
        taxas() {
            return  this.$store.state.deliveryTaxas
        }
    },
    methods: {
        trocarPorVirgula(dindin) {
            return converterParaDinheiroString(dindin)
        },

        onClickEditar() {
            this.$emit("onClickEditar")
        },


    }

}
</script>

<style scoped>

.linha-vertical {
    
}
.btn-editar {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cor1-muito-escura);
    width: calc(100% + 20px);
    height: 40px;
    margin-top: 10px;
    border-radius: 5px;
    margin-inline: -10px;
    margin-bottom: -10px;
    border-end-end-radius: 17px;
    border-end-start-radius: 17px;
}

.btn-editar > * { margin-inline: 3px; }

.btn-editar img { height: 20px; max-width: 20px; filter: invert(100%); }
.btn-editar p { color: white; }

</style>