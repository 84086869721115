<template>
    <div class="backgrounds">
        <section class="top-bar">
            <TopBar :imagem="item.imagem" :titulo="item.nome" :descricao="item.descricao"/>
        </section>

        <produto-controller
            :item="this.item"
            @onClickConfirmarEsteItem="confirmarItem"
        />
        
        <section class="bodys">
            <p id="quantidadeTitulo">Escolha a quantidade</p>
            <div class="divBotaoMaisMenos">
                <button id="btnDecrementarQuantidade" class="btnMaisOuMenos" @click="decrementarQuantidade()">-</button>
                <span id="quantidadeEscolhida"> {{ this.quantidade }} </span>
                <button class="btnMaisOuMenos" @click="incrementarQuantidade()">+</button>
            </div>
        </section>

        
        <BottomBar
            :quantidade="this.quantidade"
            :temPrecoUnit="true" 
            :temPrecoTotal="true" 
            :precoUnit="precoUnitarioCalculado" 
            :precoTotal="this.precoTotal" 
            :promocao="promocao"
            :clicarSelecionar="enviarCarrinho"
            :esconderPrecoUnitario="esconderPrecoUnitario"
            :podeAvancar="this.itemFinal.podeAvancar"/>

    </div>
</template>

<script>

import BottomBar  from "../BottomBar.vue";  
import TopBar from '../TopBar.vue';
import ProdutoController from './controle/ProdutoController.vue';
/**
 * Quando o item é do tipo mercadoria. Depois de adicionar o item, retora para a pagina anterior
 * @vue-prop {Object} item é o item especifico escolhido
 * @vue-data {Number} [quantidade = 1] quantidade de mercadorias, sendo que inicia com 1
 * @vue-data {Number} [precoTotal=this.item.preco] é preco da mercadoria
 * @vue-computed {Boolean} [esconderPrecoUnitario] manda como prop para o componente BottomBar se ele deve esconder o preco unitario ou nao. Ele sempre deve
 * esconder caso a quantidade de itens selecionados eja igual a 1, valido apenas para mercadorias; 
 */
export default {
  
    nome: "TipoMercadoria",

    props: {
        item: Object,
    },

    computed: {
        temPerguntaForcada() {
            return this.item.perguntaforcada.length != 0
        },
        esconderPrecoUnitario() {
            if (this.quantidade == 1) {
                return true
            }
            return false
        },
        precoUnitarioCalculado() {
            if (this.item.promocao == 0 || this.item.promocao == null) {
                return this.item.preco.toFixed(2).replace(".", ",");
            } else {
                return this.item.promocao.toFixed(2).replace(".", ",");
            }
        },

        /**
         * se nao houver  perguntaforcada, ja retornar true dizendo
         * que o usuario pode avancar. Senao, esperar todas as perguntas serem respondidas
         */
        verificarSePodeAvancar() {
            if (this.item.perguntaforcada.length == 0) {
                return true;
            }
            return false;
        },
    },
    
    data() {
        return {
            quantidade: 1,
            precoTotal: this.item.preco,
            promocao: this.item.promocao,
            itemFinal: {},
        }
    },

    methods: {

        /**
         * Vai ser chamada pelo emit da @see ProdutoController e vai setar na var [this.itemFinal]
         * Nada precisa ser setado aqui. Observacoes, perguntra forcada, etc.. Tudo vai ser setado no @see ProdutoControlle
         */
        confirmarItem(item) {
            this.itemFinal = item
        },


        /** Diminui a quantidade escolhida ao pressionar o botao menos */
        decrementarQuantidade() {
            if (this.quantidade == 1) {
                this.desabilitarBotaoDecrementar()
            } else {
                this.habilitarBotaoDecrementar()
                this.quantidade--
                this.precoTotal = (this.item.preco);
                this.promocao = (this.item.promocao);
            }
        },

        /** Aumenta a quantidade escolhida ao pressionar o botao mais */
        incrementarQuantidade() {
            console.log(this.item)
            this.quantidade++
            this.precoTotal = (this.item.preco);
            this.promocao = (this.item.promocao);
            if (this.quantidade == 1) {
                this.desabilitarBotaoDecrementar()
            } else {
                this.habilitarBotaoDecrementar()
            }
        },

        /** traz as observacoes do modulo ObservacoesDePrepado e seta aqui */
        setarObservacoesDePreparo(obs) {
            this.observacoes = obs
            console.log("setando obs")
            console.log(this.observacoes)
        },

        /** Desabilita o botao decrementar quando nao pode mais diminuir, quando a quantidade é menor do que 1 */
        desabilitarBotaoDecrementar() {
            var btnDecrementarQuantidade = document.getElementById("btnDecrementarQuantidade")
            btnDecrementarQuantidade.disabled = true
        },

        /** habilita o botao decrementar quando a quantidade é maior que 1 */
        habilitarBotaoDecrementar() {
            var btnDecrementarQuantidade = document.getElementById("btnDecrementarQuantidade")
            btnDecrementarQuantidade.disabled = false
        },

        /** calcula o preco vendo se tem promocao ou nao */
        calcularPrecoParaEnviar() {
            if (this.item.promocao == null) {
                return parseFloat(this.item.preco);
            } else {
                return parseFloat(this.item.promocao);
            }
        },

        /**
         * é o metodo que envia a mercadoria para o carrinho envia em fomato de objeto o id, o nome, o preco unitario, a quantidade escolhida
         * e o preco total final do item. Depois de adicionar o item, retora para a pagina anterior
         */
        enviarCarrinho() {
            let itemFinalizado =  this.itemFinal
            itemFinalizado.tipo = "mercadoria"
            itemFinalizado.quantidade = this.quantidade
            this.$store.dispatch('setItensCarrinho', itemFinalizado)
            this.$router.back()
        }

    },
    components: {
        BottomBar,
        TopBar,
        ProdutoController
    }

}
</script>

<style scoped>

.backgrounds {

    display: flex;
    flex-direction: column;
    background: var(--gradiente-background)
}

.top-bar {
    margin: 0;
}
.bodys{
    /* overflow-y: auto; */
    flex-grow: 1;
}

#quantidadeTitulo {
    margin: 0;
    padding: 10px;
    font-size: 14px;
    color: white;
}

#mercadoriaImg {
    width: 100%;
    height: 180px;
    object-fit: cover;
}


#nomeDaMercadoria {
    width: 100%;
    background: black;
    color: white;
    font-size: 18px;
    padding: 10px;
    margin: 0;
}

.btnMaisOuMenos {
    border-radius: 5px;
    background: var(--primaryDark_azul);
    color: white;
    font-weight: 800;
    font-size: 25px;
    padding: 3px;
    width: 40px;
}

#quantidadeEscolhida {
    font-weight: 800;
    margin: 10px;
    font-size: 25px;
    color: white;
}



</style>