<template>
    <div class="backgrounds">
        <TopBar :imagem="item.imagem" :titulo="item.nome" :descricao="item.descricao" class="top-bar" />


        <ProdutoController
            :item="item"
            @onClickConfirmarEsteItem="retornarProduto"
        />

        <!-- quantidade  -->
        <section class="bodys"> 
            <p id="quantidadeTitulo">Escolha a quantidade</p>
            <div class="divBotaoMaisMenos">
                <button id="btnDecrementarQuantidade" class="btnMaisOuMenos" @click="decrementarQuantidade()">-</button>
                <span id="quantidadeEscolhida"> {{ this.quantidade }} </span> 
                <button class="btnMaisOuMenos" @click="incrementarQuantidade()">+</button>
            </div>
        </section>

        <BottomBar
            :quantidade="this.quantidade"
            :temPrecoUnit="true" 
            :temPrecoTotal="true"
            :precoVariacao="precoSoVariacao"
            :precoUnit="precoUnitarioCalculado" 
            :promocao="promocaoTotal"
            :precoTotal="this.precoTotal" 
            :clicarSelecionar="enviarCarrinho"
            :clicarSelecionarNaoPodeAvancar="clicarSelecionarNaoPodeAvancar"
            :podeAvancar="verificarSePodeAvancar"
            :esconderPrecoUnitario="esconderPrecoUnitario"
        />
 
    </div>
</template> 

<script>
import TopBar from "../TopBar.vue";
import BottomBar from "../BottomBar.vue";
import Swal from 'sweetalert2';
import ProdutoController from "./controle/ProdutoController.vue";
// import QuantidadeEscolher  from "../QuantidadeEscolher.vue"

/**
 * @vue-prop {object} item é o produto a ser vendido
 * 
 * @vue-data {Number} [precoTotal=item.preco] é o preco do produto a ser vendido
 * @vue-data {Number} [promocao=item.promocao] é o preco do produto a ser vendido se ele tiver promocao
 * @vue-data {Number} precoTotalComVariacao é o preco do produto a ser vendido com a variacao
 * @vue-data {object} [fichaTecInicial=item.fichatecnica] traz a ficha tecnica inicial do item
 * @vue-data {object} [fichaTecFinal=item.fichatecnica] é a fichatecnica final, ou seja, retirando os itens escolhidos pelo usuario
 * @vue-data {object} [numeroVariacoesObrigatorias=0] é o numero de variacoes obrigatorias
 * @vue-data {object} [variacoesRespondidas=[]] sao as variacoes que o usuario ja respondeu ate o momento
 * @vue-data {object} [todasVariacoesObrigatoriasRespondidas=false] indique se todas as variacoes obrigatorias ja foram respondidas
 * @vue-data {object} [todasPerguntasForcadasRespondidas=false] indique se todas as perguntas forcadas ja foram respondidas
 * @vue-data {object} [perguntasForcadas=[]] é a lista de perguntas forcadas que sera preenchida
 * 
 * @vue-computed {boolean} verificarSePodeAvancar se nao houver variacaofichatecnica nem perguntaforcada, ja retornar true dizendo que o usuario pode avancar. Caso contrario, vai verificar se
 * tem perguntaforcada e se tem variacaofichatecnica e se as perguntaforcada e se tem variacaofichatecnica  obrigatorias foram respondidas
 * @vue-computed {Array} variacoesObrigatorias sao todas as variacoes de ficha tecnica que o usuario é obrigado a responder para poder lancar o item no carrinho. Retorna uma lista de variacoes 
 * obrigatorias.
 */
export default {
    nome: "TipoProduto",
    props: {
        item: Object,
    },
    data() {
        return {
            quantidade: 1,
            precoTotal: this.item.preco,
            promocao: this.item.promocao,
            precoTotalComVariacao: 0,
            fichaTecInicial: this.item.fichatecnica,
            fichaTecFinal: this.item.fichatecnica,
            numeroVariacoesObrigatorias: 0,
            variacoesRespondidas: [],
            todasVariacoesObrigatoriasRespondidas: false,
            todasPerguntasForcadasRespondidas: false,
            perguntasForcadas: [],   
            produtoEnviar: null,

        };
    },
    computed: {
        precoSoVariacao() {
            if (this.produtoEnviar == null) {
                return 0
            } else {
                return this.produtoEnviar.precoApenasVariacoes
            }
        },
        esconderPrecoUnitario() {
            if (this.quantidade == 1) {
                return true
            }
            return false
        },
        precoUnitarioCalculado() {
            if (this.item.promocao == 0 || this.item.promocao == null) {
                return this.item.preco;
            } else {
                return this.item.promocao;
            }
        },

        promocaoTotal() {
            return this.item.promocao
        },


        /**
         * se nao houver variacaofichatecnica nem perguntaforcada, ja retornar true dizendo
         * que o usuario pode avancar
         */
        verificarSePodeAvancar() {
            if (this.produtoEnviar ==  null) {
                return false;
            } else {
                return this.produtoEnviar.podeAvancar
            }
            
        },
    },
    components: {
        BottomBar,
        TopBar,
        ProdutoController,
    },
    created() {
        this.scrollToTop();
    },
    methods: {
        /** rolar pra cima quando inicia a pagina */
        scrollToTop() {
            window.scrollTo(0,0);
        },

        retornarProduto(prod) {
            this.produtoEnviar = prod;
            console.log("retornando produto")
            console.log(prod)
        },


 /** Diminui a quantidade escolhida ao pressionar o botao menos */
        decrementarQuantidade() {
            if (this.quantidade == 1) {  
                this.desabilitarBotaoDecrementar()
            } else {
                this.habilitarBotaoDecrementar()
                this.quantidade--
                this.precoTotal = (this.item.preco); 
            }
        },

        /** Aumenta a quantidade escolhida ao pressionar o botao mais */
        incrementarQuantidade() {
            this.quantidade++
            this.precoTotal = (this.item.preco);
            if (this.quantidade == 1) {
                this.desabilitarBotaoDecrementar()
            } else {
                this.habilitarBotaoDecrementar()
            }
        },

        /** Desabilita o botao decrementar quando nao pode mais diminuir, quando a quantidade é menor do que 1 */
        desabilitarBotaoDecrementar() {
            var btnDecrementarQuantidade = document.getElementById("btnDecrementarQuantidade")
            btnDecrementarQuantidade.disabled = true
        },

        /** habilita o botao decrementar quando a quantidade é maior que 1 */
        habilitarBotaoDecrementar() {
            var btnDecrementarQuantidade = document.getElementById("btnDecrementarQuantidade")
            btnDecrementarQuantidade.disabled = false
        }, 

      

        /** Exibe um alert dizendo que as etapas obrigatorias ainda nao foram todas respondidas */
        clicarSelecionarNaoPodeAvancar() {
              Swal.fire({
                title: '<p style="color: #77a; font-family: Arial, Helvetica">Por favor, responda todas as etapas obrigatorias acima.<p>',
                showConfirmButton: true,
            });
        },


        /** decidir se vai usar o preco comum ou da promocao */
        enviarPrecoOuPromocao() {
            if (this.promocao == 0 || this.promocao == null) {
                return this.item.preco;
            } else {
                return this.item.promocao;
            }
        },

        /**
         * envia  para o carrinho
         * 
         * Vamos pegar o produto vindo do @see ProdutoController e vamos adicionar apenas duas chaves: "tipo" e "quantidade"
         */
        enviarCarrinho() {
            let itemFinalizado = this.produtoEnviar
            itemFinalizado.tipo = "produto"
            itemFinalizado.quantidade = this.quantidade

            console.log("itemFinalizado")
            console.log(itemFinalizado)
            this.$store.dispatch("setItensCarrinho", itemFinalizado);
            this.$router.back();
        },
    },
};
</script>

<style scoped>
.backgrounds {
    /* background: var(--primaryDark); */
    background: var(--gradiente-background)
}

.top-bar {
    margin: 0;
}

.btn-remover-adicionar {
    background: transparent;
}
.btn-remover-adicionar > img {
    height: 28px;
    width: 28px;
}


.ficha-tec {
    align-items: center;
    margin-block: 15px;
    margin-inline: auto;
    width: 90%;
    display: flex;
    justify-content: space-between;
}


#quantidadeTitulo {
    margin: 0;
    padding: 10px;
    font-size: 14px;
    color: white;
}

#background {
    background: var(--primaryDark_azul);
    position: fixed;
    top: 0%;
    bottom: 0;
    left: 0;
    right: 0;
}

.divSelecionarProduto {
    background: var(--cor2);
    padding: 10px;
}

#textoSimples {
    padding: 0;
    margin: 0;
}

#precoUnitario {
    background: var(--cor2);
    color: var(--texto-na-cor-principal);
    padding: 5px 20px;
    margin: 0;
    font-size: 14px;
}

#precoTotal {
    background: var(--primaryDark_azul);
    color: white;
    padding: 10px 20px;
    font-size: 16px;
}

.btnMaisOuMenos {
    border-radius: 5px;
    background: var(--primaryDark_azul);
    color: white;
    font-weight: 800;
    font-size: 25px;
    padding: 3px;
    width: 40px;
}

#quantidadeEscolhida {
    font-weight: 800;
    margin: 10px;
    font-size: 25px;
    color: white;
}

.swal2-title {
    font-size: 30px !important;
}
</style>

 