import ClienteDadosModel from "./clienteDadosModel";
import EnderecoModel from "./enderecoModel";

class ClienteModel {

    /**
     * 
     * @param {ClienteDadosModel} dadosPessoais 
     * @param {Array<EnderecoModel>} dadosEnderecos 
     */
    constructor(dadosPessoais, dadosEnderecos) {
        this.dadosPessoais = dadosPessoais;
        this.dadosEnderecos = dadosEnderecos;
    }

    
    /**
     * 
     * @param {object} json 
     * @returns {ClienteModel}
     */
    fromJson(json) {
        this.dadosPessoais = new ClienteDadosModel().fromJson(json["dadosPessoais"])
        this.dadosEnderecos = []
        for (var i = 0; i < json["dadosEnderecos"].length; i++) {
            this.dadosEnderecos.push(new EnderecoModel().fromJson(json["dadosEnderecos"][i]))
        }
        return this
    }


}

export default ClienteModel