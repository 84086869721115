<template>
    <div style="padding-bottom: 50px; background: black">


        
        <DestaquesDestaques/> 

        <p v-if="promocoes.length > 0" style="margin:0px">PROMOÇÕES DO DIA</p>

        <DestaquesPromocoes :promocoes="promocoes"/>

        <div style="margin-bottom: 55px"></div>

    </div>
</template>

<script>
import DestaquesPromocoes from '@/components/Destaques/DestaquesPromocoes.vue'
import DestaquesDestaques from "../components/Destaques/DestaquesDestaques.vue"

/**
 * @vue-data {Array} [destaques] array de cada item com objs do id do item da aba destaques. Params: id, nome, preco, promocao, descricao, imagem, tipo
 * @vue-computed {Array} [promocoes] array de cada item que tem promocao
 */
export default {
    //
    name: "AbaDestaques",
    //
    data() {
        return {
            destaques: this.$store.state.destaques,
            itensVenda: this.$store.state.produtos,
            indexItemMostrando: 0
        }
    },
    computed: {
        // itemMostrar() {
        //     let item = null
        //     setTimeout(() => {
        //         item = this.destaques[indexItemMostrando]
        //     }, 1000);
        // },
        promocoes() {
            var promocoes = []
            for (var classe of this.itensVenda) {
                for (var item of classe.itensVenda) {
                    if (item.promocao != null && item.promocao < item.preco) {
                        promocoes.push(item);
                    }
                }
            }
            return promocoes;
        },    
    },

    created() {
    },
    methods: {

    },
    components: {
        DestaquesPromocoes, DestaquesDestaques
    }

}
</script>

<style scoped>  

p {
    padding-top: 30px;
    padding-bottom: 10px;
    color: white;
}

</style>