<template>
    <div>
        <button>
            <p :class="classTxtAvancar">Avançar</p>
            <p class="txt-itens">Itens selecionados {{ itensRespondidos.toString() }}/{{  itensNaEtapa.toString() }}</p>
            <!-- <br> -->
            <div class="txt-itens" v-if="jaEscolheuTodosItens">
                <p class="txt-itens" style="font-weight: bold; background: rgba(0,0,0,0.5)" v-if="!perguntasObrigatoriasRepondidas">Por favor, responda todos os tópicos obrigatórios.</p>
                <!-- <p class="txt-itens" v-if="perguntasObrigatoriasRepondidas">Pronto!</p> -->
            </div>
            
        </button>

        <div class="preenchimento"  ref="refPreenchimento">
            
        </div>
    </div>
</template>

<script>
export default {
    name: 'EtapasBotaoAvancar',
    props: {
        itensNaEtapa: Number,
        itensRespondidos: Number,
        perguntasObrigatoriasRepondidas: Boolean,
        permiteEscolherMenosItens: Boolean,
        isPrimeiraEtapa: Boolean,
    },
    data() {
        return {
            percentage2: 1,
            // classTxtAvancar: "txt-avancar"
        }
    },
    // 15 30 25 50
    computed: {
        jaEscolheuTodosItens(){
            if (this.itensRespondidos == this.itensNaEtapa) return true
            return false 
        },
        percentage() {
            if (this.permiteEscolherMenosItens) {
                if (this.isPrimeiraEtapa) {
                    if (this.itensRespondidos > 0) {
                        return 100
                    } else {
                        return 5 
                    }
                } else {
                    return 100
               
                }
            } else {
                let perc = (this.itensRespondidos  / this.itensNaEtapa) * 100
                return perc
            }
        },
        classTxtAvancar() {
            if (this.itensRespondidos  == this.itensNaEtapa && this.perguntasObrigatoriasRepondidas) {
               return "txt-avancar completo"
            } else {
               return "txt-avancar"
            } 
        },
    },
    created() {
        setTimeout(() => {
            this.preencher()
        }, 1000);
    },
    updated() {
        // this.preencher()
    },
    watch: {
        itensRespondidos() {
            this.preencher()
        }
    },
    methods: {
        preencher() {
            let btn = this.$refs.refPreenchimento;
            btn.style.clipPath = `polygon(0% 0%, ${this.percentage}% 0%, ${this.percentage}% 100%, 0% 100%)`
        }
    },
}
</script>

<style scoped>

button, .preenchimento {
    width: 100%;
    height: 65px;
}

button {
    /* background: linear-gradient(to right, blue 1%, black 1%); */
    /* transition: background-color 0.5s; */
    background: linear-gradient(to bottom, #aaa, #666);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.preenchimento {
    position: absolute;
    top: 6px;
    left: 6px;
    background: linear-gradient(to bottom, var(--cor1), black);
    z-index: 1;
    width: calc(100% - 12px);
    clip-path: polygon(0% 0%, 5% 0%, 5% 100%, 0% 100%);
    transition: clip-path 0.5s;
}

.txt-itens, .txt-avancar {
    z-index: 5;
    margin: 0;
}

.txt-avancar {
    font-size: 16px;
    color: rgba(255,255,255,0.5)
}
.txt-itens {
    font-size: 13px;
}
.completo {
    font-weight: bold;
    color: white;
}

</style>