<template>
    <div class="container" @click="abriuItemVenda">
        <div :style="{ display: temImagem ? 'block' : 'none'}">
            <img
            :src="item.imagem"
            class="imagens"
            @error="onErrorImagem"
            ref="imagemzinha"
        />            
        </div>

        <div :class="porColunas ? 'titulo-preco-block' : 'titulo-preco'" :style="{ paddingBlock: temImagem ? '10px' : '15px'}">
            <div class="titulo-preco-nome">
                <p style="width: 100%;">{{ this.item.nome }}</p>
            </div>

            <div v-if="item.tipo != 'comboPorTamanho' && item.tipo != 'comboPorEtapa' && item.tipo != 'comboPizza'" class="titulo-preco-preco">
                <!-- preco normal  -->
                <p v-if="!temPromocao">
                    R$ {{ item.preco.toFixed(2).replace(".", ",") }}
                </p>

                <!-- preco PROMO  -->
                <div v-if="temPromocao">
                    <p v-if="promocaoMenorQueValorNormal"  style="text-decoration: line-through; color: black; font-size: 12px">
                        R$ {{ item.preco.toFixed(2).replace(".", ",") }}
                    </p>
                    <p v-if="promocaoMenorQueValorNormal"  style="font-weight: bolder">
                        R$ {{ item.promocao.toFixed(2).replace(".", ",") }}
                    </p>
                    <p v-if="!promocaoMenorQueValorNormal" >
                        R$ {{ item.promocao.toFixed(2).replace(".", ",") }}
                    </p>
                </div>
            </div>

            <!-- o combo por tamanho nao tem preco especifico, depende dos itens dentro dele que o cliente escolher -->
            <div v-if="item.tipo == 'comboPorTamanho'" class="titulo-preco-preco">
                <!-- preco normal  -->
                <span>A partir de</span>
                <p >R$ {{ precoComboTamanho }}</p>
            </div>

            <!-- o combo por tamanho nao tem preco especifico, depende dos itens dentro dele que o cliente escolher -->
            <div v-if="item.tipo == 'comboPorEtapa'" class="titulo-preco-preco">
                <!-- preco normal  -->
                <span>A partir de</span>
                <p >R$ {{ precoComboEtapa }}</p>
            </div>

            <!-- o combo por tamanho nao tem preco especifico, depende dos itens dentro dele que o cliente escolher -->
            <div v-if="item.tipo == 'comboPizza'" class="titulo-preco-preco">
                <!-- preco normal  -->
                <span>A partir de</span>
                <p >R$ {{ precoComboPizza }}</p>
            </div>

        </div>

    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
/**
 * Mostra o card de cada item
 * @vue-data {Boolean} precoVaria - verifica se o preco pode mudar
 * @vue-data {string} altImg - imagem para ser usada caso o cliente nao tenha imagem
 * @vue-prop {object} item - é o item inteiro dentro da lista itensVenda do store
 * @vue-computed {Array} itensVenda é o que vem do store e tem todos os itens da classe selecionada
 */
export default {
    nome: "CadaItemVenda",
    props: {
        item: Object,
        
    },
    computed: {
        ...mapState(["itensVenda"]),
        temPromocao() {
            if (this.item.promocao != null) {
                return true;
            }
            return false;
        },
        promocaoMenorQueValorNormal() {
            if(this.item.promocao != null && parseFloat(this.item.promocao) < parseFloat(this.item.preco)) {
                return true;
            }
            return false;
        },

        precoComboTamanho() {
            let menorPreco = 150000
            if (this.item.subitens != null) {
                for (var i = 0; i < this.item.subitens.length; i++) {
                    const elem = this.item.subitens[i].valorVenda
                    if (elem < menorPreco) {
                        menorPreco = elem
                    }
                }
            }
            return  menorPreco.toFixed(2).replace(".", ",")
        },

        precoComboEtapa() {
            return  this.item.preco.toFixed(2).replace(".", ",")
        },

        precoComboPizza() {
            return  this.item.preco.toFixed(2).replace(".", ",")
        },

        porColunas() {
            return this.$store.state.organizarPorColunas
        }
        
    },
    created() {
        this.adicionarSimboloMais();
    },

    data() {
        return {
            precoVaria: "",
            classe: "container",
            altImg: "../../assets/ham.png",
            temImagem: true,
        };
    },
    methods: {
        /**
         * se o preco for variavel, vai adicionar um '+' no preco para o usuario saber que o preco pode ser maior
         */
        adicionarSimboloMais() {
            let precoVariavel = this.item["preco"] == null ? true : false;
            if (precoVariavel) {
                this.precoVaria = "+";
            } else {
                this.precoVaria = "";
            }
        },

        /**
         * caso a url da imagem nao esteja funcionando, deve entao usar  a fallback image i-c-camera.png dos assets
         */
        onErrorImagem() {
            this.temImagem = false;
            // this.$refs.imagemzinha.src = require(`../../assets/icons/ic-camera.png`);
        },

        /**
         * Abre a tela do item clicado.
         * Adiciona o item no store, na var setItemSelecionado para depois poder ser lido da store.
         * vai para a classe views/CadaItemView
         *
         * @see CadaItemView
         */
        abriuItemVenda() {
            this.$store.dispatch("setItemSelecionado", this.item);
            if (this.$store.state.isMenuEstatico) {
                this.$router.push({
                    name: "menu-estatico-item",
                    params: {
                        item: this.item,
                        id: this.id,
                    },
                });
            } else {
                this.$router.push({
                    name: "item",
                    params: {
                        item: this.item,
                        id: this.id,
                    },
                });
            }
        },

        /**
         * adicionar o id do item junto com o preco e o nome para confirmacao no final da compra
         */
        clicouItemVenda() {
            this.$store.state.itensCarrinho.push({
                id: this.id,
            });
        },
        ...mapActions({
            setItensCarrinho: "setItensCarrinho",
        }),
    },
};
</script>

<style scoped>

.container {
    display: block;
    align-items: center;
    border-radius: 10px;
    background: white;
    animation: container 0.6s forwards;
    height: 100%;
}

@keyframes container {
    from {opacity: 0.0; scale: 0.9};
    to {opacity: 1; scale: 1.0}
}

.imagens {
    /* /* height: 140px; */
    width: 100%;
    aspect-ratio: 210 / 100; /* antes era 210/140 que eh a resolucao da imagem mesmo; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-position: 0% 50%;
    object-fit: cover;
}



.titulo-preco {
    display: flex;
    /* justify-content: space-between; */
    margin: 0;
    height: fit-content;
    padding: 10px;
    max-width: 100%;

}
.titulo-preco-block {
    display: block;
    padding: 10px;
    height: 80px;
    

}

.titulo-preco-nome {
    margin: 0;
    padding: 0;
    color: var(--primaryDark_azul);
    font-size: 15px;
    text-align: left;
    font-weight: bolder;
    flex: 2 1 10px; 
    
}

.titulo-preco-preco {
    margin: 0;
    padding: 0;
    color: green;
    font-weight: bold;
    font-size: 15px;
    text-align: right;
    flex: 1 1 10px; 
}

.titulo-preco-preco > span {font-size: 0.7rem; font-weight: 500;}
</style>