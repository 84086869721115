<template>
    <div class="card corpo-pix">
        <!-- <h2>{{ paymentId }}</h2> -->
        <div style="display: flex;">
            <img src="../../assets/icons/alerta.gif" alt="" style="object-fit: contain; width: 40%;">
            <h3 style="color: black;">Para efetivar o pedido, retorne a esta página após o pagamento.</h3>
        </div>
        <div id="canvas" ref="canvasRef"></div>
        <div>
            <p>Copie o código de pagamento clicando no botão abaixo</p>
            <button v-if="!isCopiado" @click="onClickCopiarCodigo">Copiar código</button>
            <button v-if="isCopiado" @click="onClickCopiarCodigo"> <img src="../../assets/icons/ic-check.png" alt=""><span>Copiado!</span></button>
        
        </div>
    </div>
</template>

<script>
import { apiMercadoPagoBuscarStatusPagamento } from '@/api/ApiMercadoPago'
import Swal from 'sweetalert2'

export default { 
    name: "MpStatusScreenPix",

    props: {
        paymentId: null,
        pix_data: String,
        cnpj: null,
        numeroEmpresaFK: null, //
    },
    
    data() {
        return  {
            isDestroyed: false,
            isCopiado: false,
        }
    },

    created() {
        this.checar()
    },

    mounted() {
        this.qrcodar()
    },

    beforeDestroy() {
        this.destruirComponente()
    },

    methods: {
        onClickCopiarCodigo() {
            navigator.clipboard.writeText(this.pix_data);
            this.isCopiado = true;
        },
        qrcodar() {
            const qrCode = new  window.QRCodeStyling({
                // width: "100%",
                // height: "100%",
                type: "svg",
                data: this.pix_data,
                // image: "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",
                dotsOptions: {
                    color: "#4267b2",
                    type: "rounded" 
                },
                backgroundOptions: {
                    color: "#e9ebee",
                },
                imageOptions: {
                    crossOrigin: "anonymous",
                    margin: 10
                }
            });

            const ddd =this.$refs.canvasRef
            qrCode.append(ddd)
        },

        
        checar() {

            apiMercadoPagoBuscarStatusPagamento(this.cnpj, this.numeroEmpresaFK, this.paymentId)
                .then((res) => {
                    console.log(res.status);
                    if (res.status == "pending") {
                        if (this.isDestroyed == false) { // usamos isso apra checar se o usuario ainda esta nesta tela
                            setTimeout(() => {
                                this.checar();
                            }, 1500);
                        }
                    } else {
                        this.$emit("onApproved")
                    }
                }).catch((err) => {
                    Swal.fire({
                        title: "Deu erro aqui",
                        text: err
                    })
                })

        },

        destruirComponente() {
            this.isDestroyed = true
        },

    }

}
</script>

<style scoped>

h4, h5,  p { 
    color: black;
}

.corpo-pix p {
    margin: 15px;
}

button img {
    margin-right: 10px;
    height: 25px;
    filter: invert(100%);
}

button {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

#statusScreenBrick_container, .corpo-pix{
    margin-inline: 15px;
}
</style>