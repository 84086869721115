<template>
    <div class="corpo">
        <!-- ---- DESTAQUES ----  -->
        <button @click="onClick('destaques')" :to="{ name: 'carrinho' }" :style="selecionado == 'destaques' ? 'background: var(--cor2)' : ''" class='btn-expand'>
            <div>
                <img  src="../../assets/icons/ic_star.svg" alt="" class="ic-carrinho" />
            </div>
            <p>DESTAQUES</p>  
        </button>
        <!-- ---- CARDAPIO ----  -->
        <button @click="onClick('cardapio')" :to="{ name: 'carrinho' }" :style="selecionado == 'cardapio' ? 'background: var(--cor2)' : ''"  class='btn-expand btn-barras'>
            <div>
                <img  src="../../assets/icons/ic_menu.svg" alt="" class='ic-carrinho'/>
            </div>
            <p>CARDÁPIO</p>  
        </button>
        <!-- ---- CARRINHO DE COMPRAS ----  -->
        <!-- <router-link v-if="!this.$store.state.isMenuEstatico" @click="onClick('carrinho')"  :to="{ name: 'carrinho' }" :style="selecionado == 'carrinho' ? 'background: var(--cor2)' : ''"  :class="[numeroItens != 0 ? 'btn-expand btn-expand-animar' : 'btn-expand']"> -->
        
        <button @click="onClick('carrinho')" :to="{ name: 'carrinho' }" :style="selecionado == 'carrinho' ? 'background: var(--cor2)' : ''"  class='btn-expand btn-barras' v-if="!this.$store.state.isMenuEstatico">
            <div>
                <div v-if="numeroItens != 0" class="numero"><div>{{numeroItens}}</div></div>
                <img  src="../../assets/icons/ic-carrinho.svg" alt="" :class="[numeroItens != 0 ? 'ic-carrinho-animar' : 'ic-carrinho']" />
            </div>
            <p>CARRINHO</p>  
        </button>
        <!-- </router-link> -->

    </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
    export default {
        name: "MenuBaixo",
        props: {
            onClickDestaques: Function,
            onClickCardapio: Function,
            onClickCarrinho: Function,
        },
        data() {
            return {
                selecionado: this.$store.state.abaSelecionada,
                mesa: Number,
                numeroItensCarrinho: this.$store.state.itensCarrinho.length
            }
        },
        computed: {
            ...mapGetters({
                casaAberta: "getCasaAberta",
            }),
            numeroMesa() {
                return this.$store.state.mesa
            },
            numeroItens() {
                let carrinho = this.$store.state.itensCarrinho;
                let qtd = 0;
                for (let item of carrinho) {
                    qtd = qtd + item.quantidade
                }
                return qtd;
            }
        },
        methods: {
            onClick(botao) {
                // a barra de busca desaparece
                this.$store.dispatch("setMostrarBarraBusca", false);

                // a classe selecionada volta a ser a primeira classe de intens
                this.$store.dispatch("selecionarClasseInicial"); 

                // vai para a aba correta
                switch (botao) {
                    case "destaques":
                        this.selecionado = "destaques"
                        this.$store.dispatch('setAbaSelecionada', "destaques")
                        break;
                    case "cardapio":
                        if (this.casaAberta == false) { 
                            this.exibirAlertCasaFechada()
                         }
                        this.selecionado = "cardapio"
                        this.$store.dispatch('setAbaSelecionada', "cardapio")
                        break;
                        case "carrinho":
                        if (this.casaAberta == false) { 
                            this.exibirAlertCasaFechada()
                         } else {
                            this.selecionado = "carrinho"
                            this.$store.dispatch('setAbaSelecionada', "carrinho")
                         }

                        break;
                    default:
                        break;
                }
            },

            exibirAlertCasaFechada() {
                Swal.fire({
                    title: "Estabelecimento fechado",
                    text: "Infelizmente o estabelecimento está fechado no momento. Você poderá ver os itens do cardápio e o status do seu pedido, mas não poderá acessar o carrinho."
                })
            }
        }
    }
</script>

<style scoped>

.btn-barras {
    /* margin-inline: 2px; */
}

.numero {
    position: absolute;
    margin: 0;
    padding: 0;
    z-index: 5;
    font-size: 12px;
    background: red;
    color: white;
    border-radius: 20px;
    transform: translateX(-10px) translateY(-10px);
    width: 20px;
    height: 20px;
}

.numero div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.icone {
    height: 30px;
    filter: invert(100%);
}

.corpo {
    background: black;
    height: 65px;
    width: 100%;
    /* padding-block: 10px; */
    display: flex;
}

.corpo p {
    font-size: 12px;
}

.btn-expand {
    color: white;
    /* width: 100%; */
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    text-decoration: none;
    align-content: center;
    align-items: center;
    background: var(--gradiente-btn-conta);
    padding-inline: 5px;
    flex-grow: 1;
}
.btn-expand-animar {
    animation: btn-anim 5s infinite;
}
@keyframes btn-anim {
    0% {background: var(--gradiente-verde)}
    50% {background: var(--gradiente-verde)}
    100% {background: var(--gradiente-verde)}    
}

.btn-expand img {
    /* padding-inline: 15px; */
    margin: 0;
    text-align: start;
}


.ic-carrinho {
    height: 20px; filter: invert(100%);
}
.ic-carrinho-animar {
    height: 20px; filter: invert(100%);
    animation: animCarrinhoIcon 0.8s linear;
}
@keyframes animCarrinhoIcon {
    0% {transform: rotateZ(0deg)}
    50% {transform: rotateZ(10deg)}
    100% {transform: rotateZ(0deg)}
}


</style>