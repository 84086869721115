<template>
    <div class="card-item">
        <div class="card-item-proporcao-sabores">
            <div v-for="(sabor, index) in sabores" :key="index" class="row-proporcao-sabores">


                <div class="row-proporcao">
                    <p>{{ proporcaoComputed }}</p>
                </div>

                <div class="row-sabores">
                    <h4>{{ sabor.nome }}</h4>

                    <!-- verificar se tem fichatecnica, escrever SEM + nome do item  -->
                    <div v-if="sabor.retirar != null && sabor.retirar.length > 0">
                        <p class="texto-sem" v-for="(elem, index) in sabor.retirar" :key="index">
                            {{ "SEM " + elem.nome }}
                        </p>
                    </div>

                    <!-- verificar se tem VARIACAO fichatecnica, e escrever COM + nome do item  -->
                    <div v-if="sabor.adicionar != null && sabor.adicionar.length > 0">
                        <div class="texto-sem" v-for="(elem, index) in sabor.adicionar" :key="index">
                            <p class="texto-sem" v-for="opcao in elem.opcoesEscolhidas" :key="opcao.id">
                                {{ "COM " + opcao.nome }}
                            </p>
                        </div>
                    </div>

                </div>


                <div class="row-preco">
                    <div class="container-un-to"> To </div>
                    <p class="preco"> {{ precoPorProporcao(sabor.preco, sabor.precoApenasVariacoes).toFixed(2).replace(".", ",") }}</p>
                </div>

            </div>
        </div>



        <!-- botao excluir  -->
        <button class="card-item-btn-exlcuir" @click="clicouExcluir">
            <img src="../../assets/icons/ic-trash.png" alt="" class="img-trash"/>
        </button>
    </div>
</template>

<script>
export default {
    name: "CardSaborPizzaCarrinho",
    props: {
        sabores: Array, //
        saborMaisCaro: Number,
        combo: Object, // esse objeto vem so para necessidade de excluir um combo, precisamos saber o codigo do combo e nao do subitem
        precoPizza: Number, // preco da pizza sem contar a borda e a massa
        temSubitem: Boolean,
        onExcluir: Function,
    },
    created() {
        this.consolo()
    },
    computed: {
        proporcaoComputed() {
            if (this.sabores.length == 1) {
                return 1
            } 
            return "1/" + this.sabores.length
        },

        precoComputed() {
            return this.precoPizza.toFixed(2).replace(".", ",")
        },
    },
    methods: {
        consolo() {
        
        },

        precoPorProporcao(precoDoSabor, precoApenasVariacoes) {
            if (this.$store.state.pizzaCobrarMaior) {
                return (this.saborMaisCaro * 1 / this.sabores.length) + (precoApenasVariacoes ?? 0.0)
            }
            return (precoDoSabor * 1 / this.sabores.length) + (precoApenasVariacoes ?? 0.0)
        },


        clicouExcluir() {
            if (this.temSubitem) {
                this.onExcluir(this.combo)
            } else{
                this.onExcluir(this.item)
            }
        },

        nomeItem() {
            if (this.item.nome == null) {
                return this.item.descricao;
            } else {
                return this.item.nome;
            }
        },

        precoSubitemCombo() {
            if (this.item.valorPromocao != null) {
                return this.item.valorPromocao.toFixed(2).replace(".", ",")
            } else {
                return this.item.preco.toFixed(2).replace(".", ",")
            }
        },

        precoSubitemComboComQuantidade() {
            if (this.item.valorPromocao != null) {
                return (parseFloat(this.item.valorPromocao) * this.item.quantidade).toFixed(2).replace(".", ",")
            } else {
                return (parseFloat(this.item.preco * this.item.quantidade)).toFixed(2).replace(".", ",")
            }
        }
    }

}
</script>

<style scoped>

/* CORPO  */
.card-item {
    display: flex;
    /* background: rgb(230, 230, 230); */
    background: white;
    border-radius: 5px;
    margin-block: 7px;
    /* box-shadow: -0px 2px 5px 0px rgba(0, 0, 0, 0.1); */
    border: solid 1px black;
    z-index: 155;
    width: 100%;
}


/* CENTRALIZAR  */
.card-item-preco  .container-un-to, .card-item-preco  .preco {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/* WIDTHS  */
.card-item-proporcao-sabores  {
    width: 90%;
}
.card-item-btn-exlcuir  {
    width: 10%;
}
/* dentro do .card-item-proporcao-sabores  */
.row-proporcao {
    width: 10%;
}

.row-sabores {
    width: 70%;
}

.row-preco {
    width: 25%;
    /* background: green; */
}


/* RESTO  */
.preco {
    color: darkGreen;
    text-align: start;
    margin-left: 5px;
}

.card-item-proporcao-sabores {
}

.row-preco {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
}



.row-preco  .container-un-to {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    background: darkGreen;
    height: 100%;
    color: white;
    font-size: 11px;
    width: 25%;
}

.row-preco  .preco {
    font-size: 14px;
    width: 80%;
}


.card-item-btn-exlcuir {
    margin: 0;
    max-height: 100%;
    background: var(--cor-vermelho);
    min-height: 35px;
    border-top-right-radius: 5px;
    border-end-end-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-item-btn-exlcuir img {
    object-fit: contain;
    filter: invert(100%);
    max-height: 20px;
}

.row-proporcao-sabores {
    display: flex;
}
.row-proporcao {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-inline: 7px;
    font-size: 0.8rem;   
    font-weight: bold; 
    border-right: solid 2px black;
    margin-right: 7px;
    margin-block: 10px;
}
.row-sabores {
    margin-block: 10px;
}


.texto-sem {
    font-size: 14px;
    text-align: start;
    margin-top: 5px;
    font-size: 0.8rem;
    color: var(--primaryDark_azul);
}

h4 {
    color: black;
    text-align: left;
    margin-inline: 0;
}

.txt-peq {
    font-size: 0.8rem;
    text-align: start;
}


</style>
