<template>
    <div class="card anim-entrada" >
        <div>
            <p><b>Escolha o tipo de pagamento</b></p>
            <br>

            <div class="como-pagar-container" v-if="temMercadoPago">
                <span :class="pagarPeloAppSelecionado ? 'btn-selecionado' : 'botao-tipo-pagamento'" @click="onClickPaguePeloApp" >Pague pelo app</span>
                <span :class="!pagarPeloAppSelecionado ? 'btn-selecionado' : 'botao-tipo-pagamento'" @click="onClickPagueNaEntrega" >Pague na entrega</span>
            </div>

            <div v-if="!pagarPeloAppSelecionado">
                <div v-if="padrao.length > 0">
                    <div class="botao-tipo-pagamento" @click="abrirPagamento('padrao')">
                        <span>Dinheiro / PIX</span>
                    </div>
                    
                    <div class="container-opcoes-pagamento" v-if="padraoAberto" >
                        <div class="img-check" v-for="(tipo, index) in padrao" :key="index"  @click="selecionar(tipo.id)">
                            <img v-if="selecionado != tipo.id" src="../../assets/icons/ic-unchecked.png" alt="">
                            <img v-if="selecionado == tipo.id" src="../../assets/icons/ic-checked.png" alt="">
                            <p :class="selecionado == tipo.id ? 'li-selecionado' : ''">{{ tipo.nome }}</p>
                        </div>
                    </div>


                    <!-- troco -->
                    <div v-if="selecionado == 1" class="anim-entrada" style="display: block; ">
                        <!-- <p> Valor total: {{ valorTotalComVirgula }}</p> -->
                        <p style="margin-bottom: 8px;">Troco para quanto?</p>
                        <div style="display: flex; align-items: center; max-width: 100%; justify-content: center; margin-inline: 30px;">
                            <p style="margin-right: 15px; font-weight: bold; font-size: 1.2rem; color: green;">R$</p>
                            <input type="number" style="flex: 1;" v-model="troco" @input="onChangedInput" :disabled="naoPrecisoDeTroco" placeholder="0,00">
                        </div>

                        <div v-if="!validouValorTroco" class="mensagem-troco-errado">
                            O valor do pagamento em dinheiro não pode ser <b>menor</b> do que o valor do pedido. 
                        </div>
                        
                        <br>
                        
                        <div class="img-check" @click="precisoDeTroco">
                            <img v-if="!naoPrecisoDeTroco" src="../../assets/icons/ic-unchecked.png" alt="">
                            <img v-if="naoPrecisoDeTroco" src="../../assets/icons/ic-checked.png" alt="">
                            <p :class="naoPrecisoDeTroco ? 'li-selecionado' : ''" style="padding: 5px">Não preciso de troco</p>
                        </div>
                        <br>
                    </div>
                </div>


                <div v-if="credito.length > 0">
                    <div class="botao-tipo-pagamento" @click="abrirPagamento('credito')">
                        <span>Cartões de crédito</span>
                    </div>

                    <div class="container-opcoes-pagamento" v-if="creditoAberto">
                        <div class="img-check" v-for="(tipo, index) in credito" :key="index" @click="selecionar(tipo.id)">
                            <img v-if="selecionado != tipo.id" src="../../assets/icons/ic-unchecked.png" alt="">
                            <img v-if="selecionado == tipo.id" src="../../assets/icons/ic-checked.png" alt="">
                            <p  :class="selecionado == tipo.id ? 'li-selecionado' : ''">{{ tipo.nome }}</p>
                        </div>
                    </div>
                </div>

                <div v-if="debito.length > 0">
                    <div class="botao-tipo-pagamento" @click="abrirPagamento('debito')">
                        <span>Cartões de débito / vale refeição</span>
                    </div>

                    <div class="container-opcoes-pagamento" v-if="debitoAberto">
                        <div class="img-check" v-for="(tipo, index) in debito" :key="index" @click="selecionar(tipo.id)">
                            <img v-if="selecionado != tipo.id" src="../../assets/icons/ic-unchecked.png" alt="">
                            <img v-if="selecionado == tipo.id" src="../../assets/icons/ic-checked.png" alt="">
                            <p  :class="selecionado == tipo.id ? 'li-selecionado' : ''">{{ tipo.nome }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <br>
                
        </div>

    </div>
</template>

<script>
import ApiDelivery from '@/api/ApisDelivery'
import { converterParaDinheiroString } from '@/utils/Dinheiro'

export default {


    name: "TipoPagamento",

    props: {
        valorTotalPedidoMaisTaxa: Number
    },

    data() {
        return {
            pagarPeloAppSelecionado: this.$store.state.mp_public_key != null,
            credito: [],
            debito: [],
            padrao: [],
            selecionado: null,
            seleciouDinheiro: false,
            troco: "",
            naoPrecisoDeTroco: false,

            padraoAberto: false,
            creditoAberto: false,
            debitoAberto: false,

            temMercadoPago: this.$store.state.mp_public_key != null
        }
    },

    computed: {
        valorTotalComVirgula() {
            return converterParaDinheiroString(this.valorTotalPedidoMaisTaxa)
        },
        validouValorTroco() {
            if (this.naoPrecisoDeTroco) {
                return true
            }
            if (this.troco == "") {
                return true
            }
            if (parseFloat(this.troco) >= parseFloat(this.valorTotalPedidoMaisTaxa)) {
                return true
            }
            return false
        }
    },

    mounted() {
        this.buscarMetodosDePagamento()
    },

    methods: {
        onClickPaguePeloApp() {
            this.pagarPeloAppSelecionado = true
            this.enviarFinal()
            this.$emit("pagarPeloAppSelecionado", true)
        },
        onClickPagueNaEntrega() {
            this.pagarPeloAppSelecionado = false
            this.$emit("pagarPeloAppSelecionado", false)

            // assim que o usuario clica em "pague na entrega", vamos setar o meio de pagamento para nulo
            this.selecionado = null
            this.$emit("callback", null) 
        },
        buscarMetodosDePagamento() {
            ApiDelivery.buscarTiposDePagamento(this.$store.state.cnpj, this.$store.state.numeroEmpresaFK)
            .then((res)=> {
                console.log(res)
                this.credito = res.credito
                this.debito = res.debito
                this.padrao = res.padrao
            })
            .catch((err)=> {alert(err)})
        },

        precisoDeTroco() {
            this.naoPrecisoDeTroco = !this.naoPrecisoDeTroco
        },

        abrirPagamento(tipo) {
            switch (tipo) {
                case "padrao":
                    this.padraoAberto = !this.padraoAberto
                    break;
                    case "credito":
                    this.creditoAberto = !this.creditoAberto
                    break;
                    case "debito":
                    this.debitoAberto = !this.debitoAberto
                    break;
                default:
                    break;
            }
        },

        selecionar(id) {
            this.selecionado = id
            if (this.selecionado == 0) {
                this.seleciouDinheiro = true
            } else {
                this.seleciouDinheiro = false
                this.troco = ""
            }
            this.enviarFinal()
        },

        /** toda vez que digitarmos o trocoi, vamos mandar no callback */
        onChangedInput() {
            this.enviarFinal()
        },

        enviarFinal() {
            if (this.pagarPeloAppSelecionado) {
                this.$emit("callback", { pagamentoId: "mp", trocoPara: null })
            } else {
                this.$emit("callback", { pagamentoId: this.selecionado, trocoPara: this.naoPrecisoDeTroco ? null : this.troco })
            }

        }

    }
}

</script>

<style scoped>

.como-pagar-container {
    display: flex;
    width: 100%;
}

.botao-tipo-pagamento, .btn-selecionado {
    width: 100%;
    min-height: 50px;
    padding: 5px;
    border-bottom:solid 1px var(--cor1-muito-escura);
    border-radius: 5px;
    margin-block: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.botao-tipo-pagamento > * {
    padding-top: 35px;
    padding-bottom: 5px;
}

.btn-selecionado > * {
    padding-top: 35px;
    padding-bottom: 5px;
}

.botao-tipo-pagamento {
    color: var(--cor1-muito-escura);
}

.btn-selecionado {
    background: var(--cor1-muito-escura);
    color: white;
}

.container-opcoes-pagamento {
    margin-block: 15px;
    animation: container-opcoes-pagamento-anim 0.5s forwards;
    transform-origin: top center;
}
.container-opcoes-pagamento p {
    margin-block: 8px;
    padding: 5px;
    padding-inline: 8px;
    font-size: 0.9rem;
    border-radius: 5px;
    border: solid 1px black;
    text-align: start;
}

@keyframes container-opcoes-pagamento-anim {
    from { transform: scaleY(0%); }
      to { transform: scaleY(100%); }
}

h5 {
    margin: 0;
    margin-top: 35px;
    text-align: start;
    
}
img {
    height: 25px;
    margin-right: 15px;
}

.img-check {
    display: flex;
    align-items: center;
}

@keyframes img-anim {
    0%, 100% {scale: 1; }
    50% {scale: 1.1; }
}

.ul {padding-block: 10px;}

.ul div {  display: flex; }
.ul p { margin: 0; padding: 0;  }
.li-selecionado {  
    font-weight: bold; color:white; 
    background: var(--cor1-muito-escura);
    animation: img-anim 0.3s forwards;
}

.mensagem-troco-errado {
    color: red
}

</style>


<!-- 80400418 -->