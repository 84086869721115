<template>
    <div id="corpo">
        <h1>login</h1>
        <p>login</p>
        <div id="form">
            <div>
                <p>Usuario CPF</p>
                <input type="text" id="name" @change="setName">
            </div>
            <div style="margin-top: 15px">
                <p>Senha</p>
                <input type="text" id="senha" @change="setSenha">
            </div>
        </div>

        <dados-endereco/>
    </div>
</template>

<script>
import DadosEndereco from "@/components/Delivery/DadosEndereco.vue"

export default {
    name: "UserLogin",
    components: { DadosEndereco },
} 
</script>

<style scoped>
#corpo {
    color: white;
}
#form {
    margin-block: 30px;
    margin-inline: 30px;
    border: white solid 2px;
    padding: 30px;
    border-radius: 15px;
    /* max-width: 80%; */
}

#form  input {
    width: 100%;
    height: 30px;
    border-radius: 8px;
    text-align: center;
}


</style>