import axios from "axios";
import { rotaDeProducao } from "./rotas";

/**
 * 
 * @param {*} cnpj 
 * @param {*} numeroEmpresaFK 
 * @param {*} dadosPagamento 
 * @returns {Promise}
 */
export async function apiMercadoPagoCriarPagamento(cnpj, numeroEmpresaFK, dadosPagamento) {
    let cnpjConvertido = cnpj.replaceAll(",", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
    const url = rotaDeProducao() + "/pagar"

    const body = {
        cnpj: cnpjConvertido,
        numeroEmpresaFK: numeroEmpresaFK, 
        dadosPagamento: dadosPagamento, 
    }
    console.log("body do pagamento")
    console.log(body)
    return new Promise((resolve, reject) => {
        axios.post(url, body).then((response) => {
            console.log("===================================================")
            console.log("resposta")
            console.log(response.data)

            resolve(response.data)
  
        })
        .catch((err) => {
            reject(err)
        })
    })
}

/**
 * 
 * @param {*} cnpj 
 * @param {*} numeroEmpresaFK 
 * @param {*} pagamento_id 
 * @returns {Promise}
 */
export async function apiMercadoPagoBuscarStatusPagamento(cnpj, numeroEmpresaFK, pagamento_id) {
    let cnpjConvertido = cnpj.replaceAll(",", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
    const url = rotaDeProducao() + `/pagamentoStatus?cnpj=${cnpjConvertido}&numeroEmpresaFK=${numeroEmpresaFK}&pagamento_id=${pagamento_id}`

    return new Promise((resolve, reject) => {
        axios.get(url).then((response) => {
            console.log("resposta do apiMercadoPagoBuscarStatusPagamento")
            console.log(response.data)
            resolve(response.data)
        })
        .catch((err) => {
            reject(err)
        })
    })
}
