<template>
    <div v-if="2==2">
        <div v-if="temImagem">
            <img :src="imagem" alt="" @error="onErrorImagem" />
        </div>
        

        <div class="overlay">
            
        </div>
    </div>
</template>

<script>
import { CONSOLOGAR } from '../Controle/debug';
export default {
    name: "DestaquesApenasImagem",
    props: {
        imagem: String,
    },
    data() {
        return {
            temImagem: true
        }
    },

    methods: {
        onErrorImagem() {
            CONSOLOGAR("erop");
            this.temImagem = false;
        },
    },
};
</script>

<style scoped>
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, transparent, black);
    width: 100%;
    height: 300px;
    z-index: 20;
}
img {
    position: relative;
    width: 100%;
    height: 300px;
    object-fit: cover;
}
</style>