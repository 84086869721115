<!-- /* eslint-disable */ -->
<template>
    <div >
        <QrcodeStream @detect="onDetect"></QrcodeStream>
    </div>
</template>

<script>
/* eslint-disable */
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'

export default {
    name: "QrcodeScanner",
    data() {
        return {
            qrCodeLido: null,
        }
    },

    mounted() {
        if (this.usarScanner)
        this.onCreate()
    },

    beforeUnmount() {
        console.log("saindo da camera")
        // aqui vamos parar a camera
        this.destroyCamera()
    },

    beforeDestroy() {
        console.log("beforeDestroy")
        this.destroyCamera()
    },
    destroyed() {
        console.log("destroyed")
        this.destroyCamera()
    },

    

    

    computed: {
        // usado apenas caso nao use Scanner
        podeEnviar() {
            if (this.usarScanner == false) {
                if (this.numeroDigitado != null && this.numeroDigitado.length > 0) {   
                    return true  
                } else {
                    return false
                }
            }
            return true;
        }
    },
    
    methods: {
        async onCreate() {
            // console.log(window)
        },

        async onDetect(val) {
            val.then(response => {
                console.log(response.content); // Access the fulfilled value here
                // alert(response.content)
                // this.destroyCamera();
                this.enviarTextoLido(response.content)
            });
        },

        enviarTextoLido(textoLido) {
            this.$emit("onDetected",textoLido)
        },

        destroyCamera() {
            // this.videoElem.srcObject.getTracks().forEach((track) => { 
            //     if (track.readyState == 'live') {
            //         track.stop(); // para a camera por track
            //     }
            // });
            // this.cameraDestroyed = true
            // console.log("cameraDestroyed")
        }
    },
    components: {
        QrcodeStream
        // QrcodeStream
        // StreamBarcodeReader
    }
}
</script>

<style scoped>
.btn-enviar {
    width: 80%; height: 40px; margin-top: 15px;
}
.qr-body {
    margin-block: 15px;
    height: 100%;
}
.qr-camera {
    width: 100vw; 
    height: 250px;
    position: relative;
}
.webcam-preview {
    width: 100vw;
    height: 100%;
    object-fit: cover;
}
.scan-bar-anim {
    position: absolute;
    width: 100%;
    margin-inline: 0%;
    height: 2px;
    top: 25px;
    background: red;
    animation: scan-bar-anim 3s infinite;
}
@keyframes scan-bar-anim {
    0%, 100% { top: 50px  }
    50%      { top: 190px }
}
</style>