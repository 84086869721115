<template>   

    <div class="destaques">

        <div class="imagens">
            
            <div @click="onClickDestaques(item.nome)" v-for="(item, index) in destaques" :key="index" class="item btnanim" :style="index == indexItemMostrando ? 'display: block' : 'display:none'">
                
                <div>
                    <DestaquesApenasImagem :imagem="item.imagem" />
                
                    <div class="overlay"></div>
                
                    <div class="textos">
                        <h2>{{item.nome}}</h2>
                        <p>{{item.descricao}}</p>        
                    </div>
                </div>
            </div>

        </div>


        <div class="buttons">
            <img @click="mudarItem(false)" class="rotated" src="../../assets/icons/ic_arrow.svg" alt="">
            <img @click="mudarItem(true)" src="../../assets/icons/ic_arrow.svg" alt="">
            <!-- <h1> = </h1> -->
        </div>
    </div>    

</template>

<script>
import DestaquesApenasImagem from './DestaquesApenasImagem.vue';

/**
 * @vue-data {Array} [destaques] array de cada item com objs do id do item da aba destaques. Params: id, nome, preco, promocao, descricao, imagem, tipo
 * @vue-computed {Array} [promocoes] array de cada item que tem promocao
 */
export default {
    name: "DestaquesDestaques",
    data() {
        return {
            destaques: this.$store.state.destaques,
            indexItemMostrando: 0
        };
    },
    created() {
        this.mudarItemAutomaticamente();
    },
    methods: {

        mudarItemAutomaticamente() {
            setTimeout(() => {
                if (this.indexItemMostrando < this.destaques.length - 1) {
                    this.indexItemMostrando = this.indexItemMostrando + 1;
                }
                else {
                    this.indexItemMostrando = 0;
                }
                this.mudarItemAutomaticamente();
            }, 8000);
        },

        mudarItem(praFrente) {
            if (praFrente) {
                if (this.indexItemMostrando < this.destaques.length - 1) {
                    this.indexItemMostrando = this.indexItemMostrando + 1;
                }
                else {
                    this.indexItemMostrando = 0;
                }
            }
            else {
                if (this.indexItemMostrando > 0) {
                    this.indexItemMostrando = this.indexItemMostrando - 1;
                }
                else {
                    this.indexItemMostrando = this.destaques.length - 1;
                }
            }
        },
        
        onClickDestaques(nomeItemClicado) {
            let produtos = this.$store.state.produtos;
                let esteItem = null;
                produtos.forEach((cadaClasse) => {
                    cadaClasse.itensVenda.forEach((cadaItemDaLista) => {
                        if (cadaItemDaLista.nome == nomeItemClicado) {
                            esteItem = cadaItemDaLista;
                        }
                    });
                });
                this.$store.dispatch("setItemSelecionado", esteItem);
            if (this.$store.state.isMenuEstatico == false) {
                this.$router.push({name: "item", params: {item: esteItem,id: esteItem.id}});
            } else {
                this.$router.push({name: "menu-estatico-item", params: {item: esteItem,id: esteItem.id}});
            }
        },
    },
    components: { DestaquesApenasImagem }
}
</script>

<style scoped>

p {
    color: white;
}

.destaques {
    position: relative;
    height: 300px;
    
}

.destaques .imagens {
    position: absolute;
    width: 100%;
    animation: aparecer 0.8s forwards;
}

@keyframes aparecer {
    from {opacity: 0.0;};
    to {opacity: 1.0; };
}

.btnanim {
    animation: containerazul 0.5s forwards;
}

@keyframes containerazul {
    from {opacity: 0.0; transform: rotateY(90deg)};
    to {opacity: 1.0; transform: rotateY(0deg)};
}


.destaques .buttons {
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: space-between;
    top: 50%;
    transform: translateY(-50%);
    z-index: 50;
}

.buttons img {
    height: 30px;
    margin: 5px;
    /* box-shadow: 1px 1px 5px 5px black; */
}

.rotated {
    transform: rotateY(180deg);
}

.item {
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 300px;
}

.item img {
    position: absolute;
    height: 300px;
    width: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;    
}


.item .overlay {
    position: absolute;
    background: linear-gradient(to bottom, transparent, black);
     width: 100%;
    height: 300px;
    z-index: 20;
}

.item .textos {
    position: absolute;
    bottom: 20px;
    right: 10px;
    left: 10px;
    /* top: 50px; */
    z-index: 30;
}


</style>