<template>
    <div class="erro-versao">
        <!-- <img :src="this.$store.state.logo" alt=""> -->
        <h2>Ops!</h2>
        <p>{{ errText }}</p>
        <!-- <h2>{{ this.$router.params }}</h2> -->
    </div>
</template>

<script>

export default {
    name: "ErroVersao",
    
    props: {}, // vem na rota quando chama  this.$router.push({ name: '', params: { id: 123 }})

    data() {
        return {
            errText: ""
        }
    },

    created() {
        this.setParams()
    },

    methods: {
        setParams() {
            this.errText = this.$route.params.err
        }
    },

}
</script>

<style scoped>
p {color: white;}

.erro-versao {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.erro-versao img {
    height: 15vh;
    padding-inline: 20px;
}

.erro-versao h2 {
    padding-block: 10px;
}

</style>