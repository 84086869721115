<template>
    <div :class="!selecionouAlterarDados ? 'btn-alterar-dados' :  whiteBackground ? 'card' : 'form'" v-if="mostrar">

        <div v-if="selecionouAlterarDados">
            <p>Por favor, digite seu numero de celular</p> 
            <div class="input-flex">
                <input type="number" v-model="ddd" placeholder="DDD" maxlength="2" id="input1" @input="moveToNext" />
                <input type="number" v-model="telefone" placeholder="000000000"    ref="input2" @keyup.enter="enviar" > 
                <button v-if="!loadingEnviarTelefone" class="form-btn" @click="enviar">Confirmar</button>
                <div v-if="loadingEnviarTelefone" style="background: transparent;  min-width: 70px;"><img src="../../assets/loading.gif" alt="" style="height: 35px"></div>
            </div>
        </div>

        <div v-if="!selecionouAlterarDados">
            <button @click="onClickAlterarDados" style="width: 90%; margin-inline: 5%;height: 50px; border-radius: 5px;">Alterar telefone</button>
        </div>
    </div>

</template>

<script>
export default {
    name: "TelefoneComDDD",
    props: {
        mostrar: Boolean,
        loadingEnviarTelefone: Boolean,
        whiteBackground: Boolean,
    },
    data() {
        return { 
            focus: false,
            ddd: "",
            telefone: "",
            selecionouAlterarDados: true,
        }
    },
    computed: {
        clienteExisteNaBase() {
            if (this.ddd && this.telefone && this.telefone.length > 2) {
                return true
            }
            return false;
        }
    },
    created() {
        this.buscarLocal()
    },
    methods: {

        buscarLocal() {
            this.ddd = localStorage.getItem('save-ddd')
            this.telefone= localStorage.getItem('save-tel')
            if (this.ddd && this.telefone && this.telefone.length > 2) {
                this.selecionouAlterarDados = false
                this.enviar()
            }
        },
        
        enviar() {
            localStorage.setItem('save-ddd', this.ddd)
            localStorage.setItem('save-tel', this.telefone)
            this.$emit('callback',{telefone:  this.ddd + this.telefone})
        },

        moveToNext(currentInput) {
            currentInput.preventDefault()
            if (currentInput.target.value.length == 2) {
                this.$refs["input2"].focus();
                this.focus = true;
            }
        },

        onClickAlterarDados() {
            this.selecionouAlterarDados = !this.selecionouAlterarDados
        }
    }
}
</script>

<style scoped >

p { margin-bottom: 10px;}

.input-flex {
    display: flex;
    width: 100%;
}
.input-flex input:first-child {
    width: 40px;
    margin-right: 10px;
}
.input-flex input:nth-child(2) {
    margin-right: 10px;
    flex-grow: 1;
}


.btn-enviar {
    min-width: 70px;
}

.btn-alterar-dados {
    margin: 5px;
    margin-top: 10px;
    z-index: 4;
}
.btn-alterar-dados button {
    padding: 5px;
    padding-inline: 10px;
}
</style>