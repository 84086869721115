<template>
    <section class="card" style="max-width: 100%; margin: 0; border-radius: 0;" v-if="observacoes != null && observacoes.length > 0">
        <div class="" style="margin-bottom: 10px;">
            <p>Alguma observação no preparo?</p>
            <!-- <div class="obrigatorio">Obrigatório</div> -->
        </div>

        <!-- <div>
            <p>{{  JSON.stringify(this.$store.state.classeSelecionadaObservacoes) }}</p>
        </div>
        <div>
            <p>{{  JSON.stringify(this.observacoesSelecionadas) }}</p>
        </div> -->

        <div
            ref="opcaoref"
                class="opcao"
                v-for="(opcao, index) in observacoes"
                v-bind:key="index"
                @click="onClickOpcao(opcao)"
            >
            <!-- adicionar  --> 
            <img
                src="../../assets/icons/ic-checked.png"
                alt=""
                 style="filter: invert(0%)"
                v-if="observacoesSelecionadas.includes(opcao)"
            />

            <!-- remover  -->
            <img
                src="../../assets/icons/ic-unchecked.png"
                style="filter: invert(0%)"
                alt=""
                v-if="!observacoesSelecionadas.includes(opcao)"
                
            />

            <div
                style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                "
            >
                <p>{{ opcao }}</p>

                <!-- <p v-if="opcao.valorAdicionais > 0.0" style="width: 30%; color: #bbb">R$ +{{ opcao.valorAdicionais.toFixed(2).replace(".", ",") }}</p> -->
            </div>
        </div>
    </section>
</template>

<script>
// import { animarOpcao } from '../outros/Animacao';
export default {
    name: "ObservacaoDeClasse",

    props: {
        // pergunta: Object,
    },

    data() {
        return {
            observacoes: this.$store.state.classeSelecionadaObservacoes,
            observacoesSelecionadas: [],
        };
    },

    created() {
        this.enviarParaParent()
    },

    methods: {

        onClickOpcao(opcao) {
            if (this.observacoesSelecionadas.includes(opcao)) {
                this.removerObservacao(opcao)
            } else {
                this.adicionarObservacao(opcao)
            }
            this.enviarParaParent()
        },

        adicionarObservacao(opcao) {
            this.observacoesSelecionadas.push(opcao)
        }, 
        removerObservacao(opcao) {
            this.observacoesSelecionadas = this.observacoesSelecionadas.filter((e) => e != opcao)
        },

        enviarParaParent() {
            if (this.observacoesSelecionadas != null) {
                this.$emit("onSelect", this.observacoesSelecionadas);
            }             
        },
        
    },
};
</script>

<style scoped>


.obrigatorio {
    
    padding-block: 1px;
    font-size: 16px;
    text-align: center;
    color: red;
    font-weight: bold;
    margin-bottom: 10px;
    /* animation: anim-maximo 1s; */
}


</style>