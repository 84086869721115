<template>
<div class="barra">
    <img src="../../assets/icons/ic-search.png" alt="">
    <input type="text" name="barra" @input="onChange">
</div>
</template>

<script>
export default {
    name: "BarraDeBusca",
    methods: {
        onChange(e) {
            this.$store.dispatch("setItemBuscado", e.target.value )
        }
    }
}
</script>

<style scoped>

    .barra { height: auto; background: white; display: flex; align-items: center;}

    img {height: 25px; margin-left: 10px; filter: opacity(50%); }

    input { flex-grow: 1; margin-inline: 10px; margin-block: 5px;  padding-block: 0px;  } 

</style>