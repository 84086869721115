
/**
 * Converte para dinheiro no formato brasileiro
 * @param {string | number} valorNumerico 
 * @returns Valor em reais com R$ e virgula
 */
export function converterParaDinheiroString(valorNumerico) {
    if (valorNumerico == null || valorNumerico.length == 0) {
        return ""
    }
    let valorStr = valorNumerico.toFixed(2);
    // let valorStr = valorNumerico.toString();
    let novoValor = valorStr.replace(".", ",")
    return `R$ ${novoValor}`
}