<template>
    <div class="corpo-etapa">
        <div class="itens-da-etapa">
            <h3>{{ etapa.nome }}</h3>
            <h4 :class="classQtdMax">Selecione a {{ isBorda ? 'borda' : 'massa' }}</h4>


<!-- bordas -->
            <div v-for="(item, index) in etapa" :key="index">
                <div class="item-row clicable" @click="selecionouItem(item)">
                    <img v-if="produtoSelecionado.codigo  == item.codigo " src="../../../../assets//icons//ic_radio_checked.svg" alt="">
                    <img v-if="produtoSelecionado.codigo  !== item.codigo " src="../../../../assets//icons//ic_radio_unchecked.svg" alt="">
                    <div class="item-row-expand">
                        <p>{{ item.descricaoCompleta }}</p>
                        <p>{{ converterValorVendaEmStr(valorMostrar(item)) }}</p>
                    </div>
                </div>
            </div>



        </div>
   
        <hr>


        <div class="btn-avancar-bottom">
            <button :class="podeAvancar ? `btn-avancar` : `btn-avancar desativado`" @click="avancar">Avançar</button>
        </div>
    </div> 
</template>

<script>

export default {
    name: "BordaEMassa",
    props: {
        indexEtapaCorrente: Number,
        totalEtapas: Number,
        etapa: Array,
        isBorda: Boolean
    },

    computed: {
        podeAvancar() {
            if ( this.produtoSelecionado.codigo != null) {
                return true
            }
            return false
        },
    },
    watch: {
        isBorda() {
            this.produtoSelecionado = {}
        }
    },
    data() {
        return {
            classQtdMax: 'qtd-max',
            produtoSelecionado: {},
        };
    },
    methods: {

        valorMostrar(item) {
            if (item.valorPromocao != null && item.valorPromocao != 0.0) {
                return item.valorPromocao
            }
            return item.valorVenda
        },

        converterValorVendaEmStr(valor) {
            let valorFixed = valor.toFixed(2).replace(".", ",")
            return "R$ " + valorFixed
             
        },


        /**
         * Verificar se o item é mercadoria ou produto
         * @param {*} item
         */
        selecionouItem(item) {
            this.produtoSelecionado = item
        },

        computarAdicionais() {
            
        },



        /**
         * Enviar para o parent o item selecionado
         * @param {*} item
         */
        avancar() {

            if (this.podeAvancar) {

                const itemFinalizado = {
                    podeAvancar: this.podeAvancar,
                    id: this.produtoSelecionado.codigo,
                    codigo: this.produtoSelecionado.codigo,
                    nome: this.produtoSelecionado.descricaoCompleta,
                    preco: this.valorMostrar(this.produtoSelecionado), 
                    precoApenasVariacoes: this.valorMostrar(this.produtoSelecionado),
                    valorUnitarioPagoNoSmartPos: this.valorMostrar(this.produtoSelecionado),
                    fichaTecnica: null,
                    quantidade: 1,
                    retirar: null,
                    adicionar: null,
                    perguntasForcadas: null,
                    precoTotal:this.valorMostrar(this.produtoSelecionado),
                }

                if (this.isBorda) {
                    this.$emit("onSelectedBorda", itemFinalizado);
                } else {
                    this.$emit("onSelectedMassa", itemFinalizado);
                }
            }
        },
    },
    components: {   }
}
</script>

<style scoped>

.corpo-etapa > .itens-da-etapa{
    padding-block: 10px;
    background: linear-gradient(to bottom, var(--cor1-escura),black);
}

p {color: white;}

.itens-da-etapa {
    padding-inline: 10px;
}
.item-row, .item-row-expand {
    display: flex;
    align-items: center;
    margin-block: 15px; 
    width: 100%;
}
.item-row {  justify-content: start;  }
.item-row-expand {  justify-content: space-between; }

.item-row img {height: 25px; filter: invert(0%); margin-right: 10px;}

.qtd-max {
    
}
.qtd-max-anim {
    animation: qtd-max-animation 1s forwards;
}

@keyframes qtd-max-animation {
    0%, 100%                 { transform: translateX(0%); background: none }
    10%, 30%, 50%, 70%, 90%  { transform: translateX(-2%); background: red}
    20%, 40%, 60%, 80%       { transform: translateX(2%); background: red}
}

.btn-avancar {
    height: 50px;
    width: 100%;
}

.desativado {
    background: rgb(100, 100, 100);
    color: rgb(75, 75, 75);
}

.btn-avancar-bottom {
    width: calc(100% - 20px);
    position: sticky;
    bottom: 0;
    left: 0;
    /* height: 75px; */
    background: white;
    padding: 6px;
}

</style>