<template>
    <section class="background-gradiente">
        <menu-top-template :titulo="'Chamar o garçom'" :onPressedClose="() => { this.$router.go(-1); }"/>
        <div class="card-pagina">
            
            <button class="btn-fechar-top" type="button" @click="voltar">X</button>

            <p class="titulo">CHAMAR O GARÇOM</p>
            <hr>

            <div v-if="loading" class="loading">
                <img src="../assets/loading.gif" alt="" style="height: 45px" />
            </div>

<!-- vai aparecer so se existem motivos pre cadastrados  -->
            <div v-if="!loading">
                <div v-if="listaMotivos.length > 0">
                    <p class="subtitulo">Escolha o motivo para chamar o garçom</p><br>
                    
                    <div class="grupo-motivos">
                        <div v-for="(mot, index) in listaMotivos" :key="index" class="radio-buttons">
                            <input type="radio" :id="mot" name="radio_motivos" :value="mot" @click="clicouRadio(mot)">
                            <label :for="mot">{{mot}}</label>      
                        </div>
                    </div>
                    
                    <br>
                    <br>
                    
                    <p class="subtitulo">Ou descreva abaixo o motivo</p>
                    <br>
                </div>
<!-- vai aparecer so se NAO tiverem motivos pre cadastrados  -->
                <p v-if="listaMotivos.length == 0" class="subtitulo">Descreva abaixo o motivo</p>
                
<!-- vai aparecer sempre  -->
                <textarea name="motivoGarco" id="motivoGarcom" @input="pegarTexto($event.target.value)"></textarea>

                <button class="button-chamar" @click="montarJson()">CHAMAR</button>
            </div>

        </div>
    </section>
</template>

<script>
import { apiBuscarMotivosChamarGarcom, apiChamarGarcom } from '@/api/ApiChamarGarcon';
import Swal from 'sweetalert2';
import { setarCores } from '@/utils/SetarCores';
import { CONSOLOGAR } from '@/components/Controle/debug';
import MenuTopTemplate from '@/components/Base/MenuTopTemplate.vue'
/**
 * Tela de chamar garcom
 * @vue-data {String} motivo - onde vai ficar guardado o texto do motivo para chamar o garcom
 * @vue-data {Boolean} [loading=false] - mostrar loading gif ou nao
 */
export default {
    name: "ChamarGarcomView",
    data() {
        return {
            motivo: "",
            loading: false,
            listaMotivos: []
        }
    },
    components: {MenuTopTemplate, },
    created() {
        this.buscarMotivos();
        setarCores(this.$store.state.cor1, this.$store.state.cor2)
    },
    methods:{
        /** faz a busca inicial dos motivos pre cadastrados e exibe na tela com radio buttons */
        buscarMotivos() {
            apiBuscarMotivosChamarGarcom(this.$store.state.cnpj, this.$store.state.idTerminal).then((res) => {
                this.listaMotivos = []
                for (let mot of res) {
                    this.listaMotivos.push(mot.motivo)
                }
            });  
        },

        /** volta para a tela de escolha de itens */
        voltar() {
            this.$router.back();
        },

        /** pega o texto do radio button */
        clicouRadio(motivo) {
            this.motivo = motivo
        },

        /** pega o texto do campo text area quando o usuario escreve o motivo */
        pegarTexto(texto) {
            this.motivo = texto;
        },

        /**
         * Monta o json e envia para a api
         * @property {String} cnpj cnpj da empresa
         * @property {Number} idTerminal id do Terminal
         * @property {Number} operacao operacao tem que ser 11 que significa chamar garcom
         * @property {Number} NumeroCMS_FK numero da mesa
         * @property {Number} mesa numero da mesa
         * @property {String} tipo o movito de chamar o garcom que o usuario vai digitar 
         * @property {Number} IdGarcons_FK id do garcom
         * @property {Number} CodigoCentroResultado_FK codigo (id) do centro de resultado
         * @property {Number} idCaixaLio id do caixa
         * @property {Number} numeroEmpresaFK id da filial 
         */
        montarJson() {
            let json = {
                cnpj: this.$store.state.cnpj,
                idTerminal: this.$store.state.idTerminal,
                operacao: 11,
                NumeroCMS_FK: this.$store.state.mesa,
                mesa: this.$store.state.mesa,
                tipo: this.motivo,
                IdGarcons_FK: this.$store.state.idgarcom,
                CodigoCentroResultado_FK: this.$store.state.codigoCentroResultado,
                idCaixaLio: this.$store.state.idTerminal,
                numeroEmpresaFK: this.$store.state.numeroEmpresaFK
            }
            this.loading = true;
            apiChamarGarcom(json).then((res) => {
                this.loading = false;
                CONSOLOGAR([JSON.stringify(res)])
                Swal.fire({title: "Solicitação enviada com sucesso", text: "Aguarde que o garçom virá até você." }).then(() => this.voltar())
            })
        },
    }
}
</script>

<style scoped>

.radio-buttons {
    /* background: red; */
    display: flex;
    /* border-block: solid 2px black; */
    align-content: center;
    align-items: center;
}

.radio-buttons input {
    background: blue;
    width: 10%;
    height: 20px;
}
.radio-buttons label {
    /* background: red; */
    width: 90%;
    font-size: 0.8rem;
    text-align: justify;
}

/* .radio label {margin-left: 12px;} */

.subtitulo {
    font-size: 14px;
    width: 80%;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

textarea {
    background: rgb(222, 222, 222);
    margin-bottom: 20px;
    width: 100%;
    height: 70px;
}

.button-chamar {
    margin: 20px;
    padding-block: 15px;
    padding-inline: 30px;
    border-radius: 5px;
}

.loading {
    height: 320px;
    position: relative;
    top: 50%;
    transform: translateY(30%);
}

.grupo-motivos {
    display: flex;
    flex-direction: column;
    gap: 20px;

}

</style>