<template>
    <div>
        <div class="row">

            <div class="img-container">
                <!-- <img class="logo" sc="../../assets/logo_allfood_escrita_branca.png" alt=""> -->
                <img class="logo" :src="logo" alt="">
                
            </div>

<!-- caso nao seja delivery, mostrar estes botoes  -->
            <div class="titulo"> 
                <p>{{ titulo }}</p>
            </div>

        </div>


    </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapState } from 'vuex';
import { CONSOLOGAR } from '../Controle/debug';
export default {
    name: "MenuTopTemplate",
    props: {
        titulo: String,
        icone: String,
        onPressedClose: Function
    },
    data() {
        return {
            numeroDeIensCarrinho: 0,
            precoTotalAteAgora: 0.0,
            logo: this.$store.state.logo,
            mesaOuCartao: this.$store.state.porCartao ? "COMANDA" : "MESA",
        };
    },
    computed: {
        ...mapState([
            'itensCarrinho'
        ]),
        numeroMesa() {
            if (this.$store.state.porCartao) {
                return "";
            }
            else {
                return this.$store.state.mesa;
            }
        },
        podeVerConta() {
            if (this.$store.state.itensCarrinho.length > 0) {
                return true;
            }
            return false;
        },
    },
    created() {
        this.calcularPrecoTotal();
    },
    methods: {
        clicouCarrinho() {
            CONSOLOGAR(["clicou"]);
        },
        calcularPrecoTotal() {
            for (var i = 0; i < this.itensCarrinho.length; i++) {
                this.precoTotalAteAgora = this.precoTotalAteAgora + parseFloat(this.itensCarrinho[i].precoTotal);
            }
        },
        alertarVerConta() {
            if (this.podeVerConta) {
                Swal.fire({ title: "Desculpe!", text: "Você não poderá ver a conta enquanto houver itens no carrinho de compras." });
            }
        }
    },
    components: {  }
}
</script>

<style scoped>


.row {
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    background: var(--cor1);
    color: white;
}

.img-container {
    width: 10vh;
    height: 10vh;
    background: var(--cor1-muito-escura);
}

.logo {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    max-width: 80%;
    max-height: 80%;
}

.titulo {
    /* background: green; */
    width:fit-content;
    height: 10vh;
    margin: 0;
    font-size: 1.3rem;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    animation: titulo-anim 0.5s forwards;

}

.titulo > p {
    align-content: center;
    align-items: center;
}

@keyframes titulo-anim {
    from { opacity: 0;}
    to { opacity: 1;}
}





</style>