<!-- eslint-disable no-unused-vars -->
<template>
    <div v-if="qrCodeLido == null">
        <div class="qr-body"> 
            <br>            
            <div  v-if="!usarScanner && usarQrcodeScanner">
                <!-- <video id="webcam-preview" class="webcam-preview" ref="videopreview"  autoplay></video>
                <div v-if="cameraDestroyed == false" class="scan-bar-anim"></div>
                <p id="result"></p> -->
                <h5>Por favor, aponte para o QR Code da sua comanda.</h5>
                <br>
                <QrcodeScanner @onDetected="enviarTextoLido" />
            </div>
            <div class="qr-camera" v-if="usarScanner && !usarQrcodeScanner">
                <!-- <video id="webcam-preview" class="webcam-preview" ref="videopreview"  autoplay></video>
                <div v-if="cameraDestroyed == false" class="scan-bar-anim"></div>
                <p id="result"></p> -->
                <h5>Por favor, aponte para o código de barras da sua comanda.</h5>
                <br>
                <barcode-quagga-component @onDetected="enviarTextoLido" />
            </div>

            <div class="qr-camera" v-if="!usarScanner && !usarQrcodeScanner">
                <h5>Por favor, digite o código de barras da sua comanda.</h5>
                <br>
                <div>
                    <label for="">Comanda</label>
                    <input type="number" style="width: 80%; box-sizing: border-box; margin-inline: 10%;" placeholder="000000" v v-model="numeroDigitado">
                </div>
                <button v-if="podeEnviar" class="btn-enviar" @click="enviarTextoLido">Enviar</button>
                <button v-if="!podeEnviar" class="btn-enviar" style="background: grey; color: #111" >Enviar</button>
            </div>
            
        </div> 
    </div>
</template>

<script>
// import { StreamBarcodeReader } from "@teckel/vue-barcode-reader";
// eslint-disable-next-line no-unused-vars
import { BarcodeFormat, DecodeHintType, BrowserMultiFormatReader } from '@zxing/library';
import BarcodeQuaggaComponent from './BarcodeQuaggaComponent.vue';
import QrcodeScanner from './QrcodeScanner.vue';


export default {
    name: "CameraScanner",
    data() {
        return {
            qrCodeLido: null,
            videoDevicesRecog: null,
            videoElem: null,
            cameraDestroyed: false,
            numeroDigitado: null,
            usarScanner: this.$store.state.cartaoComLeitorCodigoBarras ?? false,
            usarQrcodeScanner: this.$store.state.cartaoComQrCode ?? false,
        }
    },

    mounted() {
        if (this.usarScanner)
        this.onCreate()
    },

    beforeUnmount() {
        console.log("saindo da camera")
        // aqui vamos parar a camera
        this.destroyCamera()
    },

    beforeDestroy() {
        console.log("beforeDestroy")
        this.destroyCamera()
    },
    destroyed() {
        console.log("destroyed")
        this.destroyCamera()
    },

    

    

    computed: {
        // usado apenas caso nao use Scanner
        podeEnviar() {
            if (this.usarScanner == false) {
                if (this.numeroDigitado != null && this.numeroDigitado.length > 0) {   
                    return true  
                } else {
                    return false
                }
            }
            return true;
        }
    },
    
    methods: {
        async onCreate() {
            // const hints = new Map();
            // const formats = [BarcodeFormat.QR_CODE, BarcodeFormat.DATA_MATRIX, BarcodeFormat.UPC_A, BarcodeFormat.UPC_E, BarcodeFormat.AZTEC,];

            // hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

            // // o elemento HTML
            // this.videoElem = this.$refs.videopreview

            // // Get a stream for the rear camera, else the front (or side?) camera.
            // this.videoElem.srcObject = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' }, audio: false });
            // const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' }, audio: false });
            // stream.getVideoTracks()[0].cap

            
            // // vamos criar uma instancia do listener
            // const reader = new BrowserMultiFormatReader();

            // console.log("video funcionadno")

            // // aqui esta o listener
            // reader.decodeFromVideo(this.videoElem).then((val) => {

            //         // aqui vamos parar a camea
            //         this.destroyCamera()

            //         //enviar o texto lido
            //         this.enviarTextoLido(val.text)
            //     }).catch((err) => console.log(err)) 
        },

        enviarTextoLido(textoLido) {
            console.log("detect3ed here")
            console.log(textoLido)
            if (!this.usarScanner && this.usarQrcodeScanner) {
                this.$emit("onBarcodeLido", textoLido)  
            } else if (this.usarScanner && !this.usarQrcodeScanner) {
                // vamos setar essa var para sumir todos os elementos HTML
                this.qrCodeLido = textoLido

                // vamos mandar pro parent o qrcode ou barcode ligo
                this.$emit("onBarcodeLido", textoLido)     
            } else {
                if (this.numeroDigitado != null && this.numeroDigitado.length > 0) {   
                    // vamos mandar pro parent o qrcode ou barcode ligo
                    this.$emit("onBarcodeLido", this.numeroDigitado)     
                }
            }
        },

        destroyCamera() {
            this.videoElem.srcObject.getTracks().forEach((track) => {
                if (track.readyState == 'live') {
                    track.stop(); // para a camera por track
                }
            });
            this.cameraDestroyed = true
            console.log("cameraDestroyed")
        }
    },
    components: {
        BarcodeQuaggaComponent,QrcodeScanner
        // QrcodeStream
        // StreamBarcodeReader
    }
}
</script>

<style scoped>
.btn-enviar {
    width: 80%; height: 40px; margin-top: 15px;
}
.qr-body {
    margin-block: 15px;
    height: 100%;
}
.qr-camera {
    width: 100vw; 
    height: 250px;
    position: relative;
}
.webcam-preview {
    width: 100vw;
    height: 100%;
    object-fit: cover;
}
.scan-bar-anim {
    position: absolute;
    width: 100%;
    margin-inline: 0%;
    height: 2px;
    top: 25px;
    background: red;
    animation: scan-bar-anim 3s infinite;
}
@keyframes scan-bar-anim {
    0%, 100% { top: 50px  }
    50%      { top: 190px }
}
</style>