<template>
    <div class="card-item">
        <div class="card-item-descricao " >

<!-- titulo  -->
<!-- essa logica abaixo existe para centralizar o titulo do item  -->
<!-- verticalmente caso nao existam variacoes e nem ficha tecnica -->

            <h4  v-if="item.adicionar != null || item.retirar != null" class="">{{ temSubitem ? nomeItem() :  item.nome }}</h4>
            <h4 v-if="item.adicionar == null && item.retirar == null" class="nome-item ">{{ temSubitem ? nomeItem() :  item.nome }}</h4>

<!-- verificar se tem pergunta forcada  -->
            <div v-if="item.perguntasForcadas != null && item.perguntasForcadas.length > 0">
                <p class="texto-sem"  v-for="(elem, index) in item.perguntasForcadas" :key="index">
                    {{ elem.opcaoEscolhida }}
                </p>
            </div>

<!-- verificar se tem observacao de prepado da classe  -->
            <div v-if="item.observacoes != null && item.observacoes.length > 0">
                <p class="texto-sem"  v-for="(elem, index) in item.observacoes" :key="index">
                    {{ elem }}
                </p>
            </div>

<!-- verificar se tem fichatecnica, escrever SEM + nome do item  -->
            <div v-if="item.retirar != null && item.retirar.length > 0">
                <p class="texto-sem" v-for="(elem, index) in item.retirar" :key="index">
                    {{ "SEM " + elem.nome }}
                </p>
            </div>

<!-- verificar se tem VARIACAO fichatecnica, e escrever COM + nome do item  -->
            <div v-if="item.adicionar != null && item.adicionar.length > 0">
                <div class="texto-sem" v-for="(elem, index) in item.adicionar" :key="index">
                    <p class="texto-sem" v-for="opcao in elem.opcoesEscolhidas" :key="opcao.id">
                        {{ "COM " + opcao.nome }}
                    </p>
                </div>
            </div>

        </div>



        
        <!-- quantidade  -->
        <div class="card-item-quantidade">
            <p>x{{ quantidadeComputed }}</p>
        </div>

<!-- precos  -->
        <div class="card-item-precos" >
            <div>
                <div class="container-un-to" style="background: darkBlue"> Un </div>
                <p style="color: darkBlue; "> {{ this.precoUnitarioComputed.toFixed(2).replace(".", ",") }}</p>
                
            </div>
            <div>
                <div class="container-un-to" style="background: darkGreen" > To </div>
                <p style="color: darkGreen;">{{ this.precoTotalComputed.toFixed(2).replace(".", ",") }}</p>
            </div>
        </div>

       
        


        <!-- botao excluir  -->
        <button class="card-item-btn-excluir" @click="clicouExcluir">
            <img src="../../assets/icons/ic-trash.png" alt="" class="img-trash"/>
        </button>

    </div>
</template>

<script>
export default {
    name: "CardItemCarrinho",
    props: {
        item: Object, // caso seja combo, esse item sera um subitem do combo
        combo: Object, // esse objeto vem so para necessidade de excluir um combo, precisamos saber o codigo do combo e nao do subitem
        temSubitem: Boolean,
        onExcluir: Function,
    },
    created() {
        this.consolo()
    },
    computed: {
        quantidadeComputed() {
            if (this.item.quantidadeCombo != null) {
                return this.item.quantidadeCombo
            }
            return this.item.quantidade
        },

        precoUnitarioComputed() {
            if (this.item.tipo == "produto") {
                if (this.item.preco == null) return 0.0
                if (this.item.precoApenasVariacoes == null) return 0.0
                return (this.item.preco) + this.item.precoApenasVariacoes
            }
            if (this.item.item != null && this.item.item.valorVendaItem != null) {
                return this.item.item.valorVendaItem 
            }
            return this.item.precoTotal 
        },
        
        precoTotalComputed() {
            let precoAqui = 0.0
            if (this.item.tipo == "produto") {
                if (this.item.preco == null) return 0.0
                if (this.item.precoApenasVariacoes == null) return 0.0
                return (this.item.preco) + this.item.precoApenasVariacoes
            }
            if (this.item.item != null && this.item.item.valorVendaItem != null) {
                precoAqui = this.item.item.valorVendaItem 
            } else {
                precoAqui  = this.item.precoTotal
            }
            if (this.item.quantidadeCombo != null) {
                return precoAqui * this.item.quantidadeCombo 
            }
            return precoAqui * this.item.quantidade 
        },
    },
    methods: {
        consolo() {
            console.log("---------------- Card Item Carrinho --------------------------------")
            console.log(JSON.stringify(this.item))
            console.log("____________________________________________________________________")
        },
        clicouExcluir() {
            if (this.temSubitem) {
                this.onExcluir(this.combo)
            } else{
                this.onExcluir(this.item)
            }
        },

        nomeItem() {
            if (this.item.nome == null) {
                return this.item.descricao;
            } else {
                return this.item.nome;
            }
        },


    }

}
</script>

<style scoped>

/* GERAL  */
.centralize , .card-item-descricao, .card-item-quantidade {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    max-height: 100%;
}

/* WIDTHS  */
.card-item-descricao  {
    width: calc(60% - 20px);
    padding: 10px;
}
.card-item-quantidade {
    width: 10%;
}
.card-item-precos {
    width: 20%;
}
.card-item-btn-excluir {
    width: 10%;
}



/* RESTO  */

.loading {
    margin-top: 120px;
    margin-inline: auto;
}

.bods {
    background: white;
}

.background-brancos {
    border: solid 10px var(--primaryDark_azul);
}

.top-bar {
    background: white;
    margin-top: 5px;
}

.nome-item {
    align-content: center;
    align-items: center;
}

.container-cards {
    background: white;
    overflow-y: auto;
    flex: 1;
}

.bottom-bar {
    margin-top: 65px;
    background: white;
    margin-bottom: 5px;
}

.precoTotal > div {
    animation: precoTotalAnim 1s forwards;
}

@keyframes precoTotalAnim {
    0% {
        opacity: 0;
        background: green;
    }
    100% {
        opacity: 1;
    }
}

.card-item {
    display: flex;
    /* background: rgb(230, 230, 230); */
    background: white;
    border-radius: 5px;
    margin-block: 7px;
    /* box-shadow: -0px 2px 5px 0px rgba(0, 0, 0, 0.1); */
    border: solid 1px black;
    z-index: 155;
    min-height: 50px;
    width: 100%;
}

.card-item-descricao {
    padding-block: 5px;
    max-height: 100%;
}




.card-item-quantidade {
    font-weight: bold;
    color: darkblue;
    font-size: 15px;
}


.card-item-precos {
    /* background: red; */
    
}
.card-item-precos > div {
    height: 50%;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card-item-precos > div > .container-un-to {
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    font-size: 11px;
    text-align: start;
    justify-content: center;
    color: white;
}
.card-item-precos > div > p {
    width: 75%;
    text-align: start;
    font-size: 12px;
    margin-inline: 5px;
    letter-spacing: -1px;
}

.row-preco {
    /* height: 50%; */
}

.card-item-btn-excluir {
    display: flex;
    margin: 0;
    background: var(--cor-vermelho);
    min-height: 35px;
    border-top-right-radius: 5px;
    border-end-end-radius: 5px;
}

.card-item-btn-excluir img {
    object-fit: contain;
}
.img-trash {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    flex-grow: 1;
    filter: invert(100%);
    max-height: 20px;
    min-height: 5px;
}

.texto-sem {
    font-size: 14px;
    text-align: start;
    margin-top: 5px;
    font-size: 0.8rem;
    color: var(--primaryDark_azul);
}

h3 {
    color: black;
}

h4 {
    color: black;
    text-align: left;
    margin-inline: 0;
}

.bottom-bar-row {
    width: 100%;
    display: block;
}

.texto-desabilitado-anim {
    animation: animTextoDesab 0.3s linear forwards;
}
@keyframes animTextoDesab {
    0% {
        transform: scaleX(80%);
    }
    100% {
        transform: scaleX(100%);
    }
}
</style>

