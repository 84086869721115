<template>
    <div>

        <!-- titulo da classe  -->
        <TituloDePaginaView :titulo="this.$store.state.classeSelecionada" :subtitulo="this.$store.state.classeSelecionadaDescricao" />

   

        <!-- aqui todos os itens aparecem na lista, independente de serem combos, ou mercadorias, etc -->

        <div  class="display-items">
            <div v-for="item in itensVenda" :key="item['id']" class="cada-item">
                <CadaItemVenda :item="item"/>
            </div>
        </div>
        


        <div  style="margin-bottom: 135px"/>

    </div>
</template>

<script>
import {  mapState } from 'vuex';
import CadaItemVenda from '../ListaItensVenda/CadaItemVenda.vue'
import TituloDePaginaView from "../Base/TituloDePagina.vue"
export default {

    name: "ItensVenda",

    data() {
        return {
            classeSelecionada: this.$store.state.classeSelecionada,
        }
    },

    computed: {
        ...mapState([
            'produtos',
            'currentStr',
            'itensVenda'
        ]),
        porColunas() {
            return this.$store.state.organizarPorColunas
        }
    },

    components: {
        CadaItemVenda,TituloDePaginaView
    }

}
</script>

<style scoped>
h3{
    margin-top: 0;
    margin-bottom: 0;
}
p {color: white}
hr {
    padding: 0;
    margin-inline: 10px;
    margin-block: 0;
}

/* desktops  */
@media only screen and (min-width: 720px) {
    .display-items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        padding-inline: 10px;
    }

    .cada-item {
        height: 100%;
    }
}
/* mobiles  */
@media only screen and (max-width: 720px) {
    .display-items {
    
    }
    .cada-item {
        margin-inline: 5px;
        margin-bottom: 5px;
    }
}




</style>