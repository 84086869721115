
class PedidoModel {

    /**
     * 
     * @param {*} tipo 
     * @param {*} mesa 
     * @param {*} cartao 
     * @param {*} valorTotalCarrinho 
     * @param {*} cnpj 
     * @param {*} numeroEmpresaFK 
     * @param {*} IdGarcons_FK 
     * @param {*} numeroComanda 
     * @param {*} CodigoCentroResultado_FK 
     * @param {*} itens 
     */
    constructor(tipo, mesa, cartao, valorTotalCarrinho, cnpj, numeroEmpresaFK, IdGarcons_FK, numeroComanda, CodigoCentroResultado_FK, itens) {
        this.tipo = tipo 
        this.mesa = mesa 
        this.cartao = cartao 
        this.valorTotalCarrinho = valorTotalCarrinho 
        this.cnpj = cnpj 
        this.numeroEmpresaFK = numeroEmpresaFK 
        this.IdGarcons_FK = IdGarcons_FK 
        this.numeroComanda = numeroComanda 
        this.CodigoCentroResultado_FK = CodigoCentroResultado_FK
        this.itens = itens 
    }

}

export default PedidoModel