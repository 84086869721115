import axios from "axios";
import { rotaDeProducao } from "./rotas";
import { CONSOLOGAR } from "@/components/Controle/debug";


/**
 * Busca na tabela motivos_garcom os possiveis motivos para chamar um garcom pre-cadastrados no allfood
 * @param {String} cnpj 
 * @param {int} idTerminal 
 */
export async function apiBuscarMotivosChamarGarcom(cnpj, idTerminal) {
    let cnpjConvertido = cnpj.replaceAll(",", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
    try {
        let urlProd = rotaDeProducao() +"/motivosgarcom?cnpj=" + cnpjConvertido + "&idTerminal=" + idTerminal ;   // para testes --> cnpj=00906885019092 idTerminal=72
        let res = await axios.get(urlProd);
        return res.data;
    } catch (e) {
        CONSOLOGAR([e])
    }
}
/**
 * Envia o post com chamar garcom
 * @param {String} cnpj 
 * @param {int} idTerminal 
 * @param {Object} body {"operacao":11,"NumeroCMS_FK":22,"tipo":"Eu nao gostei do meu pao","idGarcons_FK":233,"codigoCentroResultado":42,"idCaixaLio":"72","numeroEmpresa_FK":30} 
 */
export async function apiChamarGarcom(body) {
    try {
        let urlProd = rotaDeProducao() +"/garcom";   // para testes --> cnpj=00906885019092 idTerminal=72
        let res = await axios.post(urlProd, body);
        return res.data;
    } catch (e) {
        CONSOLOGAR([e])
    }
}

