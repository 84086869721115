<template>
    <div class="background" style="width: 100%;">
<!-- menu top          -->
        <div class="menu-top">
            <MenuTop /> 
        </div>

<!-- CENTRO          -->
        <div v-if="this.$store.state.abaSelecionada == 'destaques'"  class="div-classes-e-items" style="display: block;  overflow: hidden;">
            <AbaDestaques/>
        </div>

        <div v-if="this.$store.state.abaSelecionada == 'cardapio'" class="div-classes-e-items" style="display: flex;  overflow: hidden;">
            <MenuClasses class="menu-classes" />
            <!-- <ListaItensVenda class="itens" :key="this.iteracoes" ref="toolbarChat"/> -->
            <ListaItensVenda class="itens"  ref="toolbarChat"/>
        </div>

        <div v-if="this.$store.state.abaSelecionada == 'carrinho'"  class="div-classes-e-items" style="display: block; overflow: hidden;">
            <CarrinhoView/>
        </div>
        
<!-- menu baixo          -->
        <div class="menu-bottom">
 
            <div v-if="this.$store.state.abaSelecionada == 'carrinho'" >
                <div style="height: 120px;">
                    
                    <BottomBarCarrinho
                        :valorFinalTotal="getFinalTotal"
                        :podeEnviar="podeEnviar"
                        :onClickConfirmar="onClickEnviar"
                    />

                </div>
            </div>

            <MenuBaixo />
            <!-- <MenuBaixo 
            :onClickCardapio="onClickCardapio" 
            :onClickDestaques="onClickDestaques" 
            :onClickCarrinho="onClickCarrinho"
            /> -->
        </div>

        <router-view/>
    </div>
</template>

<script>
import MenuClasses from "../components/CardapioView/MenuClasses.vue"
import ListaItensVenda from "../components/CardapioView/ListaItensVenda.vue"
import MenuTop from "../components/CardapioView/MenuTop.vue"
import MenuBaixo from '../components/CardapioView/MenuBaixo.vue'
import AbaDestaques from '../views/AbaDestaques.vue'
import {setarCores} from '../utils/SetarCores'
import CarrinhoView from "./CarrinhoView.vue"
import { mapGetters } from 'vuex'
import BottomBarCarrinho from "@/components/Carrinho/BottomBarCarrinho.vue"

export default {
    name: "CardapioView",
    data() {
        return {
            // carrinho: []
            podeEnviar: true,
        }
    },

    components: {
        MenuClasses,
        ListaItensVenda,
        MenuTop,
        MenuBaixo,
        AbaDestaques,
        CarrinhoView,
        BottomBarCarrinho
    },
    
    created() {
        setarCores(this.$store.state.cor1, this.$store.state.cor2),
        this.setarPodeEnviar();
    },

    watch: {
        /** calcula o total final assim que a tela renderiza */
        getFinalTotal() {
            if (this.getFinalTotal == 0.0) {
                this.podeEnviar = false;
            }
        },

        carrinho() {
            this.setarPodeEnviar()
        }
    },

    computed: {
        ...mapGetters({
            carrinho: "getItensCarrinho",
            casaAberta: "getCasaAberta"
        }),
        
        getFinalTotal: function () {
            return this.calcularValorTotalFinal().toFixed(2).replace(".", ",").replace(".", ",");
        },
    },
    
    methods: {

        scrollToTop() {
            window.scrollTo(0,0);
        },

        onClickEnviar() {
            this.$store.dispatch("setOnCLickEnviarPedido")
        },

        calcularValorTotalFinal() {
            let finalPrecoTotal = 0.0;
            for (var i = 0; i < this.carrinho.length; i++) {
                console.log(`preco item ${i} :: ${this.carrinho[i].preco}`)
                console.log(this.carrinho[i]) 
                finalPrecoTotal = finalPrecoTotal + parseFloat(this.carrinho[i].precoTotal) * this.carrinho[i].quantidade;
            }
            return finalPrecoTotal;
        },

                /**
         * vai alterar a var podeEnviar para true ou false dependendo do getFinalTotal
         * @see podeEnviar
         * @see getFinalTotal
         */
         setarPodeEnviar() {
            if (this.carrinho == null || this.carrinho.length == 0) {
                this.podeEnviar = false;
            } else {
                this.podeEnviar = true;
            }
        },
    }

}
</script>

<style scoped>

.menu-top {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 10vh;
}

.menu-bottom {
    position: fixed;
    bottom: 0px;
    z-index: 100;
    width: 100%;
    /* height: 10vh; */
}

.div-classes-e-items {
    padding-top: 10vh;
    min-height: 90vh;
    /* background: yellow; */
    /* background:var(--cor1); */
}

.menu-classes {
    display: block;
}

.itens {
    background:var(--cinza-fundo-itens);
    /* background:var(--cor1); */
    display: block;
    flex-grow: 1;
}

</style>
