<template>
    <div style="height: 100%; width: calc(100% - 20px); margin-inline: 10px;">
        <div v-if="semItensNoCarrinho">
                <p :class="[ this.animarTextoDesabilidado ? 'texto-desabilitado texto-desabilitado-anim' : 'texto-desabilitado']" style="margin-top: 35px;">
                    Você ainda não possui produtos no seu carrinho.
                </p>
            </div>

            <!-- card branco com cada iten do carrinho  --> 
            <div class="corpo" >

 
            <!-- iterar sobre todos os itens do carrinho --> 
                <div v-for="(item, index) in carrinho" :key="index">


                    <!-- ------------------------------------------------------caso o item seja PRODUTO ou MERCADORIA--------------------------------------------------------  -->
                    <div v-if="item.subitens == null && item.subitem == null" style="margin-top: 15px">
                        <CardItemCarrinho :item="item" :onExcluir="deletarItem" :temSubitem="false"/>
                    </div>
         

                    <!--                                                   **  ATENCAO  **                                                                            -->
                    <!--                                                                                                                                              -->
                    <!--                                                   **  COMBOS  **                                                                             -->
                    <!--                                                                                                                                              -->
                    <!--           os combos venda conjunta  tem a chave SUBITENS (plural)    - pois vem todos os itens da venda conjunta                             -->
                    <!--           os combos por tamanho     tem a chave SUBITEM (singular)   - pois vem apenas o item escolhido do combo                             -->
                    <!--           os combos pizza           tem a chave SUBITEM (singular)   - vem as chaves: {borda: item, massa:item, sabores: Array<item> }       -->

                    <!-- ------------------------------------------------------caso o item seja COMBO VENDA CONJUNTA --------------------------------------------------------  -->
                    <div v-if="item.tipo == 'comboVendaConjunta'" style="margin-top: 15px" class="card-comboVendaConjunta"> 
                        <!-- iterar nsobre cada subitem do combo  -->   
                        <div class="combo-nome-precototal">
                            <p class="texto-nome-combo">{{ item.nome }}</p>
                        </div>
                        <hr class="combo-title-hr"> 
                        <div class="combo-itens-agrupador">
                            <div v-for="(cadaSubItem, indexx) in item.subitens" :key="indexx" style="margin: 0px"> 
                                <CardItemCarrinho :item="cadaSubItem" :onExcluir="deletarItemCombo" :temSubitem="true" :combo="item"/>   
                            </div>
                        </div>
                    </div>

                    <!-- ------------------------------------------------------ caso o item seja COMBO POR TAMANHO --------------------------------------------------------  -->
                    <div v-if="item.tipo == 'comboPorTamanho'" style="margin-top: 15px" class="card-comboPorTamanho">
                        <div class="combo-nome-precototal">
                            <p class="texto-nome-combo">{{ item.nome }}</p>
                        </div>
                        <hr class="combo-title-hr">
                        <div class="combo-itens-agrupador">
                            <CardItemCarrinho :item="item.subitem" :onExcluir="deletarItem" :temSubitem="true" :combo="item"/>
                        </div>
                    </div> 

                    <!-- ------------------------------------------------------ caso o item seja COMBO PIZZA --------------------------------------------------------  -->
                    <div v-if="item.tipo == 'comboPizza'" style="margin-top: 15px" class="card-comboPizza">
                        <div class="combo-nome-precototal">
                            <p class="texto-nome-combo">{{ item.nome }}</p>
                            <p class="texto-nome-combo">R$ {{  (item.preco).toFixed(2).replace(".", ",")}}</p>
                        </div>
                        <hr class="combo-title-hr">
                        
                        <div class="combo-itens-agrupador">
                            <!-- sabores  -->
                            <div v-if="item.subitens.sabores != null && item.subitens.sabores.length > 0">
                                <CardSaborPizzaCarrinho :sabores="item.subitens.sabores" :saborMaisCaro="buscarPrecoPizzaSaberMaisCaro(item.subitens.sabores)" :precoPizza="item.precoPizza" :onExcluir="deletarItemCombo" :temSubitem="true" :combo="item"/>   
                            </div>

                            <!-- borda  -->
                            <div v-if="item.subitens.borda != null && item.subitens.borda != {}">
                                <CardItemCarrinho :item="item.subitens.borda" :onExcluir="deletarItemCombo" :temSubitem="true" :combo="item"/>
                            </div>
                            
                            <!-- massa  -->
                            <div v-if="item.subitens.massa != null && item.subitens.massa != {}">
                                <CardItemCarrinho :item="item.subitens.massa" :onExcluir="deletarItemCombo" :temSubitem="true" :combo="item"/>
                            </div>
                         
                        </div>
                    </div>

                    <!-- ------------------------------------------------------ caso o item seja COMBO POR ETAPAS --------------------------------------------------------  -->
                    <div v-if="item.tipo == 'comboPorEtapa'" style="margin-top: 15px" class="card-comboPorEtapa">
                        <div class="combo-nome-precototal">
                            <p class="texto-nome-combo">{{ item.nome }}</p>
                            <p class="texto-nome-combo">R$ {{  (item.preco).toFixed(2).replace(".", ",")}}</p>
                        </div>
                        <hr class="combo-title-hr">
                        
                        <!-- subitens  -->
                        <div class="combo-itens-agrupador">
                            <div v-for="(cadaSubItem, indexx) in item.subitens" :key="indexx" style="margin: 0px"> 
                                <CardItemCarrinho :item="cadaSubItem" :onExcluir="deletarItemCombo" :temSubitem="true" :combo="item"/>   
                            </div>
                        </div>
                       
                    </div>
                
                </div>
            </div>
    </div>
</template>

<script>
import CardItemCarrinho from "./CardItemCarrinho.vue"
import CardSaborPizzaCarrinho from "./CardSaborPizzaCarrinho.vue";
export default {
    name: "ListaItensCarrinho",
    components: {
        CardItemCarrinho,CardSaborPizzaCarrinho,
    },
    props: {
        semItensNoCarrinho: Boolean,
        carrinho: Array,
        deletarItem: Function,
        deletarItemCombo: Function,
    },
    methods: {
        buscarPrecoPizzaSaberMaisCaro(sabores) {
            let itens = [...sabores]
            itens.sort((a, b) => Number(b.preco) - Number(a.preco))
            return Number(itens[0].preco)
        }
    }
}
</script>

<style scoped>
.texto-nome-combo {
    /* color: var(--cor-1);  */
    /* color: white; */
    margin: 0px; 
    font-weight: bold; 
    text-align: start;
}
.corpo {
    max-height: 100%;
    height: 100%;
    /* background: white; */
    padding-block: 15px;
    /* overflow-y: scroll; */
}

.combo-itens-agrupador {
    margin-left: 0px;
    padding-left: 6px;
    border-left: solid white 1px;
    border-bottom: solid white 1px;
    border-end-start-radius: 10px;
    margin-bottom: 15px;
}

.combo-nome-precototal {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}


.card-comboPizza, .card-comboPorEtapa, .card-comboVendaConjunta, .card-comboPorTamanho {
    background: white;
    color: black;
    border-radius: 10px;
}

.combo-title-hr {
    margin: 0;
    height: 0;
    padding: 0;
    border-top: 1px solid #ccc;
}
</style>