<template>
    <div>
        <h2>Barcode test</h2>
        <h4
        style="z-index: 100; background: red; position: absolute;"
        >Detected: {{ codigo }} </h4>

        <div style="width: 100%;  position: relative; z-index: 8; top: 100px">
            <barcode-quagga-component @onDetected="onCodigoDetectado" />
        </div>


      
    </div>
</template>

<script>
import BarcodeQuaggaComponent from './BarcodeQuaggaComponent.vue'
export default {
    components: { BarcodeQuaggaComponent },
    name: "BarcodeTest",
    data() {
        return {
            codigo: "",
            mostrarCamera: false,
        }
    },
    created() {
        this.start();
    },
    watch: {
        codigo() {
            console.log("codigo foi alterado")
        }
    },
    methods: {
        start() {
            this.mostrarCamera = true
            this.seraIphone()
        },



        onCodigoDetectado(codigoDetectado) {
            this.codigo = codigoDetectado
            this.mostrarCamera = true
        }
    },
}
</script>

<style scoped>

</style>