class MeusPedidosModel {
    constructor(status, pedido, dataPedido) {
        this.status = status;
        this.pedido = pedido;
        this.dataPedido = dataPedido;
    }
    fromJson(json) {
        this.status = json['status'];
        this.dataPedido = json['dataPedido'];
        this.pedido = new HistoricoPedidoModel().fromJson(json['historicoPedido'])
        return this
    }

    /**
     * 
     * @returns Formated date
     */
    dataBrasil() {
        if (this.dataPedido == null) {return  ""}
        if (this.dataPedido.includes("0000")) {return  ""}

        // vamos transformar o horario vindo do server, pois ele vem assim "2023-05-11 14:25:29"

        // primerio vamos pegar os hias, separando no "-"
        let split = this.dataPedido.split("-")

        // depois vamos separar os dias do horarios separando no " " (espaco)
        let split2 = split[split.length - 1].split(" ")

        // vamos crear um new Date sem hroario
        const currentDate = new Date(`${split[0]}-${split[1]}-${split2[0]} 00:00:00`);

        // agora vamos pegar os horarios separando no ":"
        let [h, m, s] = split2[1].split(":")
        h = parseInt(h, 10)
        m = parseInt(m, 10)
        s = parseInt(s, 10)

        // vamos setar o horario
        currentDate.setHours(h)
        currentDate.setMinutes(m)
        currentDate.setSeconds(s)

        // Format date using Intl.DateTimeFormat
        const formattedDate = new Intl.DateTimeFormat('pt-BR', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        }).format(currentDate);
        return formattedDate
    }
}

export default MeusPedidosModel

class HistoricoPedidoModel {
    constructor(data, endereco, valorTotalCarrinho, taxaEntrega, itens) {
        this.data = data
        this.endereco = endereco
        this.valorTotalCarrinho = valorTotalCarrinho
        this.taxaEntrega = taxaEntrega
        this.itens = itens
    }
    fromJson(json2) {
        this.data = json2['created_at'];
        this.endereco = json2['endereco'];
        this.valorTotalCarrinho = json2['valorTotalCarrinho'];
        this.taxaEntrega = json2['taxaEntrega'];
        this.itens = []
        for (var i = 0; i < json2.pedido.itens.length; i++) {
            var item = json2.pedido.itens[i];
            this.itens.push(new ItemPedidoModel().fromJson(item))
        }
        return this
    }

    /**
     * 
     * @returns Formated date
     */
    dataBrasil() {
        const currentDate = new Date(this.data);

        // Format date using Intl.DateTimeFormat
        const formattedDate = new Intl.DateTimeFormat('pt-BR', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'UTC', // Specify the timezone if needed
        }).format(currentDate);
        return formattedDate
    }
}

class ItemPedidoModel {
    constructor(nome, preco) {
        this.nome = nome
        this.preco = preco
    }
    fromJson(json3) {
        this.nome = json3['nome'];
        this.preco = json3['preco'];
        return this
    }
}