    /**
     * 
     * @param {number} id 
     * @param {string} bairro 
     * @param {string} cep 
     * @param {string} complemento 
     * @param {string} cidade 
     * @param {string} rua 
     * @param {string} numero 
     * @param {string} uf 
     * @param {boolean} enderecoPrincipal 
     */
class EnderecoModel {
    /**
     * 
     * @param {number | string} id 
     * @param {string} bairro 
     * @param {string} cep 
     * @param {string} complemento 
     * @param {string} cidade 
     * @param {string} rua 
     * @param {string} numero 
     * @param {string} uf 
     * @param {boolean} enderecoPrincipal 
     */
    constructor(id, bairro, cep, complemento,  cidade, rua, numero, uf, enderecoPrincipal) {
        this.id = id
        this.bairro = bairro
        this.cep = cep
        this.complemento = complemento
        this.cidade = cidade
        this.rua = rua
        this.numero = numero
        this.uf = uf
        this.enderecoPrincipal = enderecoPrincipal
    }

    
    /**
     * 
     * @param {object} json 
     * @returns {EnderecoModel}
     */
    fromJson(json) {
        this.id = json["Id"] ?? json["id"]
        this.bairro = json["Bairro"] ?? json["bairro"]
        this.cep = json["CEP"] ?? json["cep"]
        this.complemento = json["Complemento"] ?? json["complemento"]
        this.cidade = json["localidade"] ?? json["Cidade"] ?? json["cidade"]
        this.rua = json["logradouro"] ?? json["rua"] ?? json["Rua"]
        this.numero = json["numero"] ?? json["Numero"]
        this.uf = json["uf"] ?? json["UF"] ?? json["Uf"]
        this.enderecoPrincipal = json["enderecoPrincipal"] ?? json["EnderecoPrincipal"]
        return this
    }


}

export default EnderecoModel