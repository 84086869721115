<template>
    <div>
            <div v-if="!clickedEditarEndereco">

                <div v-for="(endereco,index) in this.enderecos" :key="index">
                    <div class="opcaoss" @click="selecionarEntrega(false)" >
                        <img v-if="!retirarNoLocal" src="../../assets/icons/ic-checked.png" alt="">
                        <img v-if="retirarNoLocal" src="../../assets/icons/ic-unchecked.png" alt="">
                        <div style="flex: 1;">
                            <CardEndereco :endereco="endereco" @onClickEditar="editarEndereco" />
                        </div> 
                    </div>
                </div>

                <div>
                    <div class="opcaoss" @click="selecionarEntrega(true)">
                        <img v-if="retirarNoLocal" src="../../assets/icons/ic-checked.png" alt="">
                        <img v-if="!retirarNoLocal" src="../../assets/icons/ic-unchecked.png" alt="">
                        <div class="card-outline">
                            <p style="flex: 1;">Retirar no local</p>
                            <div class="endereco-retirar-no-local">
                                {{ this.$store.state.endereco.rua }}
                                {{ this.$store.state.endereco.numero }},
                                <b>{{ this.$store.state.endereco.bairro }}</b>
                            </div>
                        </div>
                        
                    </div>
                </div>

            </div>
            
            <div v-if="clickedEditarEndereco" style="color: white">
                <DadosEndereco :exigirNome="false" :usarEnderecoAntigo="true" @enderecoAtualizado="enderecoAtualizado"/> 
            </div>

    </div>
</template>

<script>
import CardEndereco from './CardEndereco.vue';
import DadosEndereco from './DadosEndereco.vue';

// import EnderecoModel from '@/model/enderecoModel'
export default {
    name: "ControleEnderecos",
    props: {
        enderecos: Array,
    },
    components: { CardEndereco, DadosEndereco },
    data() {
        return ({
            clickedEditarEndereco: false,
            retirarNoLocal: false
        });
    },
    methods: {

        editarEndereco() {
            this.$router.push("deliveryendereco")
            this.clickedEditarEndereco = true
        },

        enderecoAtualizado() {
            this.clickedEditarEndereco = false
        },

        selecionarEntrega(selecionouRetirarNoLocal) {
            this.retirarNoLocal = selecionouRetirarNoLocal
            this.enviarCallback()
        },

        enviarCallback() {
            this.$emit("callback", {retirarNoLocal: this.retirarNoLocal})
        },

       

    }
}
</script>

<style scoped>

img { height: 35px}

.opcaoss  {display: flex; align-items: center;  width: 100%;}
.opcaoss > div { display: block; width: 100%;}

.endereco-retirar-no-local { margin-block: 7px; font-size: 0.8rem; width: 100%;}

</style>